import { createAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { runSearch } from './searchActions';
import { saveCategoryFilter } from './savedFiltersActions';
import { toggleTooltips } from './configActions';
import { parseUrlState, pushFilterToHistory } from '../../modules/history';

const prefix = '@filter/';
const paramsToCompare = ['categoryId', 'category', 'params', 'sort', 'offset', 'limit', 'presetIndex', 'occasion'];

const THROTTLING_TIME = 300;

// Send first request immediatly.
// After set it to throttling.
// Once request was done turn off throttling.
let useThrottling = false;

let throttlingTimeout = null;

export const setFilter = createAction(`${prefix}SET_FILTER`);

export const setFilterAndSearch = (filter, categories, forceSearch = false) => (dispatch, getState) => {
  const { filter: filterBefore, config: { useUrlHistory, isTooltipsVisible } } = getState();
  dispatch(setFilter(filter));
  const { filter: filterAfter } = getState();

  const dispatchSearhing = () => {
    dispatch(saveCategoryFilter(filterAfter));
    dispatch(runSearch(categories));

    if (useUrlHistory) pushFilterToHistory(filterAfter);
  }

  if (isTooltipsVisible) {
    dispatch(toggleTooltips());
  }

  if (
    !isEqual(
      pick(filterBefore, paramsToCompare),
      pick(filterAfter, paramsToCompare)
    )
    ||
    forceSearch
  ) {
    if (useThrottling) {
      if (throttlingTimeout) clearTimeout(throttlingTimeout);
      throttlingTimeout = setTimeout(() => {
        dispatchSearhing();
        useThrottling = false;
      }, THROTTLING_TIME);
    }
    else {
      dispatchSearhing();
      useThrottling = true;
      // Turn off throttling if filter was not changed
      // during throttling time.
      throttlingTimeout = setTimeout(() => {
        useThrottling = false;
      }, THROTTLING_TIME);
    }
  }
}

export const hydrateFromUrl = () => {
  const parsedFilter = parseUrlState();
  if (parsedFilter.params) {
    if (typeof parsedFilter.params.color === 'string') {
      parsedFilter.params.color = parsedFilter.params.color
        ? parsedFilter.params.color.split(',')
        : [];
    }
    if (typeof parsedFilter.params.design === 'string') {
      parsedFilter.params.design = parsedFilter.params.design
        ? parsedFilter.params.design.split(',')
        : [];
    }
    if (typeof parsedFilter.offset === 'string') {
      parsedFilter.offset = parseInt(parsedFilter.offset);
    }
    if (typeof parsedFilter.limit === 'string') {
      parsedFilter.limit = parseInt(parsedFilter.limit);
    }
  }
  return setFilter(parsedFilter);
}
