import axios from 'axios'

// Actions
const REQUEST_USER_INFO = 'admin/REQUEST_USER_INFO'
const REQUEST_USER_INFO_SUCCESS = 'admin/REQUEST_USER_INFO_SUCCESS'
const REQUEST_USER_INFO_FAILED = 'admin/REQUEST_USER_INFO_FAILED'
const REQUEST_ADMIN_LOGIN = 'admin/REQUEST_ADMIN_LOGIN'
const REQUEST_ADMIN_LOGIN_SUCCESS = 'admin/REQUEST_ADMIN_LOGIN_SUCCESS'
const REQUEST_ADMIN_LOGIN_FAILED = 'admin/REQUEST_ADMIN_LOGIN_FAILED'
const REQUEST_ADMIN_LOGOUT = 'admin/REQUEST_ADMIN_LOGOUT'
const REQUEST_ADMIN_CATEGORIES = 'admin/REQUEST_ADMIN_CATEGORIES'
const SET_ADMIN_CATEGORIES = 'admin/SET_ADMIN_CATEGORIES'

const { localStorage } = window;

const accessToken = localStorage.getItem('accessToken');

const defaultState = {
  isLoadingUserInfo: true,
  userInfo: null,
  admin: null,
  token: accessToken,
  authorized: !!accessToken,
  isAuthorizing: false,
  isLoadingCategories: false,
  categories: [],
}

// Reducer
export default function reducer (state = defaultState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case REQUEST_USER_INFO:
      return {
        ...state,
        isLoadingUserInfo: true,
      }
    case REQUEST_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoadingUserInfo: false,
        userInfo: payload.userInfo,
      }
    case REQUEST_USER_INFO_FAILED:
      return {
        ...state,
        isLoadingUserInfo: false,
        userInfo: null,
      }
    case REQUEST_ADMIN_LOGIN:
      return {
        ...state,
        isAuthorizing: true
      }
    case REQUEST_ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        authorized: true,
        isAuthorizing: false,
        isLoadingUserInfo: true,
      }
    case REQUEST_ADMIN_LOGIN_FAILED:
      return {
        ...state,
        admin: null,
        authorized: false,
        isAuthorizing: false
      }
    case REQUEST_ADMIN_LOGOUT:
      return {
        ...state,
        admin: null,
        authorized: false,
        isAuthorizing: false,
        isLoadingUserInfo: false,
        userInfo: null,
      }
    case REQUEST_ADMIN_CATEGORIES:
      return {
        ...state,
        isLoadingCategories: true
      }
    case SET_ADMIN_CATEGORIES:
      return {
        ...state,
        isLoadingCategories: false,
        categories: payload.categories
      }
    default: return state
  }
}

// Actions creator
export function requestUserInfo () {
  return { type: REQUEST_USER_INFO }
}

export function requestUserInfoSuccess (userInfo) {
  return { type: REQUEST_USER_INFO_SUCCESS, payload: { userInfo } }
}

export function requestUserInfoFailed () {
  return { type: REQUEST_USER_INFO_FAILED }
}

export function requestAdminLogin () {
  return { type: REQUEST_ADMIN_LOGIN }
}

export function requestAdminLoginFailed () {
  return { type: REQUEST_ADMIN_LOGIN_FAILED }
}

export function setAdminLoginInfo (token) {
  return { type: REQUEST_ADMIN_LOGIN_SUCCESS, payload: { token } }
}

export function adminLogout () {
  return { type: REQUEST_ADMIN_LOGOUT }
}

export function requestAdminCategories () {
  return { type: REQUEST_ADMIN_CATEGORIES }
}

export function setAdminCategories (categories) {
  return { type: SET_ADMIN_CATEGORIES, payload: { categories } }
}

/**
 * fetch Admin authentication
 * @param {Object} loginInfo
 */
export const validateUserInfo = () => async dispatch => {
  dispatch(requestUserInfo());
  try {
    const accessToken = localStorage.getItem('accessToken');
    const reponse = await axios({
      method: 'get',
      url: process.env.REACT_APP_PROXY_BE_URL + '/user/me',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },
    });
    dispatch(requestUserInfoSuccess({ ...reponse.data }));
  }
  catch(error) {
    dispatch(requestUserInfoFailed());
  }
}

export const login = (username, password) => async dispatch => {
  dispatch(requestAdminLogin())
  try {
    const reponse = await axios.post(process.env.REACT_APP_PROXY_BE_URL + '/login', {username, password});
    const { accessToken } = reponse.data;
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common['Authorization'] = `Bearer: ${accessToken}`;

    dispatch(setAdminLoginInfo(accessToken));

    dispatch(validateUserInfo());
  } catch (error) {
    dispatch(requestAdminLoginFailed());
  }
}

export const logout = () => dispatch => {
  localStorage.removeItem('accessToken');
  dispatch(adminLogout());
}

export const fetchCategories = () => {
  return async (dispatch) => {
    dispatch(requestAdminCategories())
    try {
      const response = await axios.get('/admin/categories')
      dispatch(setAdminCategories(response.data))
    } catch (e) {
      console.log('Error:', e)
    }
  }
}
