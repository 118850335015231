import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import Slider from "react-slick";
import { DotLoader } from '@yesplz/core-web/ui-kits/loaders';
import Product from './Product';

import AngleLeft from '@yesplz/core-web/assets/svg/angle-left.svg'
import AngleRight from '@yesplz/core-web/assets/svg/angle-right.svg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ProductRow.scss';

const ITEM_WIDTH = 274;

const NextArrow = ({ className, style, onClick }) => (
  <button type="button" data-role="none" className={className} style={style} onClick={onClick}>
    <img src={AngleRight} alt='Next' />
  </button>
);

const PrevArrow = ({ className , style, onClick }) => (
  <button type="button" data-role="none" className={className} style={style} onClick={onClick}>
    <img src={AngleLeft} alt='Previous' />
  </button>
);

export default ({ product, isTooltipActive, backUrl, listSource = 'recommends', onVisible, searchParams, slidesPerPage }) => {
  const { productsCache } = useSelector(({ AdminReview }) => AdminReview);
  const recommendedContainerRef = useRef();

  const handleVisibilityChange = (isVisible) => {
    if (isVisible && !productFull && onVisible) {
      onVisible();
    }
  }

  const productFull = productsCache && productsCache[product.productId]
    ? productsCache[product.productId]
    : null;

  const slidesToShow = slidesPerPage || (
    recommendedContainerRef.current
      ? Math.floor(recommendedContainerRef.current.offsetWidth / ITEM_WIDTH)
      : null
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    centerPadding: '200px',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const width = slidesToShow * ITEM_WIDTH;

  return (
    <VisibilitySensor
      partialVisibility
      onChange={handleVisibilityChange}
    >
      <div className="ProductRow">
        <div className="ProductRow-product">
          <Product
            searchParams={searchParams}
            product={productFull ? productFull : { product }}
          />
        </div>
        <div className="ProductRow-recommended" ref={recommendedContainerRef}>
          {
            productFull && productFull[listSource]
              ? <div
                  className="ProductRow-recommended-container"
                  style={{ width: `${width}px` }}
                >
                  <Slider {...settings}>
                    {productFull[listSource].map((product) => (
                      <Product
                        key={product.productId || product.product.productId}
                        searchParams={searchParams}
                        isTooltipActive={isTooltipActive}
                        backUrl={backUrl}
                        product={!product.product ? { product } : product}
                      />
                    ))}
                  </Slider>
                </div>
              : <DotLoader visible style={styles.loader} />
          }
        </div>
      </div>
    </VisibilitySensor>
  );
}

const styles = {
  loader: {
    position: 'absolute',
    top: '50%',
    width: '100%'
  },
};
