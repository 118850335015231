import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import PerfectScrollbar from 'react-perfect-scrollbar'
import TextField from '@material-ui/core/TextField'
import { VisualFilter } from 'modules/visual-filter'
import SidebarItem from './SidebarItem'
import ColorPallete from '@yesplz/core-web/modules/filters/ColorPallete'
import { StylesSelect, DesignSelect, MaterialSelect, SavedStylesSelect, AdvancedFilterHandler } from '@yesplz/core-web/modules/advanced-filters'
import './SideBarOfSettings.scss'

class SideBarOfSettings extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      config: undefined,
      categoryChanged: false,
      newStyleName: ''
    }
    this.visualFilter = null
  }

  setVisualFilter = visualFilter => {
    this.visualFilter = visualFilter
    this.setState({
      config: get(visualFilter, 'catdata.catcfg')
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { category } = this.props
    if (!prevState.categoryChanged && category !== prevProps.category) {
      this.setState({categoryChanged: true})
      setTimeout(() => {
        this.setState({categoryChanged: false})
      }, 100)
    }
  }

  handleStyleName = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleAdvancedFilterChange = advancedFilters => {
    const { filters, onFilterChange } = this.props
    onFilterChange({ ...filters, ...advancedFilters })
  }

  handleSaveFilter = () => {
    const { onSaveFilter, category } = this.props
    const { newStyleName } = this.state
    onSaveFilter(category, newStyleName)
  }

  render () {
    const {
      category,
      lastBodyPart,
      filters,
      activePresets,
      goToDashboard
    } = this.props
    const { config, categoryChanged, newStyleName } = this.state

    return (
      <PerfectScrollbar className='SidebarProductsFilter' style={{ height: 'calc(100% - 96px)' }}>
        <div className='visual-filter' style={{ padding: 0 }}>
          {!categoryChanged && <VisualFilter onInit={this.setVisualFilter} />}
          <AdvancedFilterHandler
            filters={filters}
            onChange={this.handleAdvancedFilterChange}
            render={(categorizedFilters, handleFilterChange) => (
              <React.Fragment>
                <StylesSelect
                  name='style'
                  value={categorizedFilters.style}
                  category={category}
                  lastBodyPart={lastBodyPart}
                  config={config}
                  adaptiveHeight
                  onChange={handleFilterChange}
                />
                <div className='add-preset-form'>
                  <TextField
                    id='style-name'
                    label='CREATE THE NAME HERE'
                    className='style-name'
                    value={newStyleName}
                    onChange={this.handleStyleName('newStyleName')}
                    margin='normal'
                  />
                  <div className='events-container'>
                    <button className='event-btn' onClick={this.handleSaveFilter}>Save</button>
                    <button className='event-btn' onClick={goToDashboard}>Dashboard</button>
                  </div>
                </div>
                <div className='separator' style={{ marginTop: 30 }} />
                <SidebarItem title='Design'>
                  <DesignSelect name='design' category={category} value={categorizedFilters.design} onChange={handleFilterChange} />
                </SidebarItem>
                <SidebarItem title='Colors'>
                  <ColorPallete values={categorizedFilters.color} onColorClick={(values) => handleFilterChange('color', values)} />
                </SidebarItem>
                <SidebarItem title='Materials'>
                  <MaterialSelect name='material' category={category} values={categorizedFilters.material} onChange={handleFilterChange} />
                </SidebarItem>
                <SidebarItem title='Saved Styles'>
                  {activePresets && <SavedStylesSelect name='favorite' category={category} presets={activePresets} value={activePresets[0]} onChange={handleFilterChange} />}
                </SidebarItem>
              </React.Fragment>
            )}
          />
        </div>
      </PerfectScrollbar>
    )
  }
}

SideBarOfSettings.propTypes = {
  category: PropTypes.string.isRequired,
  lastBodyPart: PropTypes.string.isRequired,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  activePresets: PropTypes.array,
  onSaveFilter: PropTypes.func,
  goToDashboard: PropTypes.func
}

export default SideBarOfSettings
