import isEqual from 'lodash/isEqual';
import client from '@yesplz/client';
import Widget from '../modules/Widget';
import noUiSlider from 'nouislider';

const { document } = window;

const TEMPLATE = `
<div class="yesplz-price-range-container">
  <div id="yesplz-price-range-slider" class="yesplz-price-range-slider"></div>
  <div class="yesplz-price-range-values">
    <span id="yesplz-price-range-value-low"></span>
    <span id="yesplz-price-range-value-high"></span>
  </div>
</div>
`;

class PriceRangeFilter extends Widget {
  defaultParams = {
    title: 'Price',
    searchButtonText: 'Search',
    step: null,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-price-range-filter';
    this.mainElement = element;

    this.pricesByCategories = {};

    this.mainElement.innerHTML = TEMPLATE;

    this.slider = null;
  }

  didMount() {
    this.fetchAndRenderPrices();
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.fetchAndRenderPrices();
    }
    else if (
      !isEqual(prevState.filter.params.price, this.state.filter.params.price)
    ) {
      this.renderLabels(...this.values);
      if (this.slider.noUiSlider) {
        const [low, high] = this.values;
        this.slider.noUiSlider.updateOptions({
          start: [low || this.range.min, high || this.range.max],
        });
      }
    }
  }

  get prices() {
    const { categoryId } = this.state.filter;
    return this.pricesByCategories[categoryId];
  }

  get range() {
    const prices = Object.keys(this.prices);
    const min = parseInt(prices[0].replace('$', ''));
    const max = parseInt(prices[prices.length - 1].replace('$', ''));
    return { min, max };
  }

  get step() {
    if (this.params.step) {
      return this.params.step;
    }

    const prices = Object.values(this.prices);
    return prices[1] - prices[0];
  }

  get values() {
    const price = this.state.filter.params.price;

    if (!price) return [];

    const [low, high] = price.split('-');

    return [low, high || this.range.max];
  }

  handlePriceChange = (low, high) => {
    const { params } = this.state.filter;
    this.setFilter({
      params: {
        ...params,
        price: `${low}-${high < this.range.max ? high : ''}`,
      },
    });
  }

  handlePriceUpdate = (low, high) => {
    this.renderLabels(low, high);
  }

  async fetchAndRenderPrices() {
    const { categoryId } = this.state.filter;
    const { searchAdditionalParams } = this.state.config;
    if (!this.pricesByCategories[categoryId]) {
      this.pricesByCategories[categoryId] = await client.pricesByCategories(categoryId, searchAdditionalParams);
    }
    this.renderPrices();
  }

  renderLabels(low, high) {
    const lowElement = document.getElementById('yesplz-price-range-value-low');
    const highElement = document.getElementById('yesplz-price-range-value-high');
    lowElement.innerHTML = `$${low || this.range.min}`;
    highElement.innerHTML = high ? `$${high}` : `Over $${this.range.max}`;
  }

  renderPrices() {
    if (!this.pricesByCategories) return;

    const [low, high] = this.values;

    const slider = document.getElementById('yesplz-price-range-slider');

    if (slider.noUiSlider) {
      slider.noUiSlider.destroy();
    }

    noUiSlider.create(slider, {
      start: [low || this.range.min, high || this.range.max],
      step: this.step,
      connect: true,
      margin: this.step,
      range: this.range,
    });

    slider.noUiSlider.on('change', ([lowStr, highStr]) => {
      this.handlePriceChange(parseInt(lowStr), parseInt(highStr));
    });

    slider.noUiSlider.on('update', ([lowStr, highStr]) => {
      this.handlePriceUpdate(parseInt(lowStr), parseInt(highStr));
    });

    this.renderLabels(...this.values);

    this.slider = slider;
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new PriceRangeFilter(params);
};
