import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
import { store } from '../store';
import { setConfigValue } from '../store/actions/configActions';

const { document } = window;

class TreeViewMenu extends Widget {
  defaultParams = {
    breadcrumbsContainer: null,
    templates: {
      breadcrumbsItem: '<a href="#" data-category-id="{{{categoryId}}}">{{label}}</a>',
      breadcrumbsSeparator: ' > ',
    }
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-tree-view';
    this.mainElement = element;

    this.visualFilterContainer = document.querySelector(
      this.params.visualFilterContainer
    );

    this.breadcrumbsIndex = null;
    this.breadcrumbsContainer = params.breadcrumbsContainer
      ? document.querySelector(params.breadcrumbsContainer)
      : null;

    this.breadcrumbsItemTemplate = Handlebars.compile(
      this.params.templates.breadcrumbsItem
    );
  }

  didMount() {
    const { categoryId } = this.state.filter;
    const activeLi = document.querySelector(
      `[data-tree-item-catid="${categoryId}"]`
    );

    if (!activeLi) return;

    activeLi.classList.add('active');

    this.activateParentItem(activeLi.parentElement);

    if (this.main.noFilterCategories.includes(categoryId))
      this.hideVisualFilter();
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.renderBreadcrumbs();
      this.updateSelected();
    }
  }

  setCategoryId(categoryId) {
    if (categoryId && this.main.categories[categoryId]) {
      this.main.setCategory(categoryId);
      this.showVisualFilter();
    }
    else {
      this.setFilter({
        categoryId: categoryId,
        category: null,
        bodyPart: null,
        params: {},
        offset: 0,
        limit: this.state.filter.limit,
      });
      this.hideVisualFilter();
    }
  }

  activateParentItem(ul) {
    if (ul.parentElement.tagName === 'LI') {
      const li = ul.parentElement;
      li.classList.add('active');
      this.activateParentItem(li.parentElement);
    }
  }

  handleItemClick = (e, item) => {
    e.stopPropagation();

    this.setCategoryId(item.categoryId);

    if (typeof this.params.onItemClick === 'function') {
      this.params.onItemClick(item);
    }

    store.dispatch(setConfigValue({
      key: 'layoutTitle',
      value: item.label,
    }));
  }

  showVisualFilter() {
    if (!this.visualFilterContainer) return;

    this.visualFilterContainer.style.display = 'block';
  }

  hideVisualFilter() {
    if (!this.visualFilterContainer) return;

    this.visualFilterContainer.style.display = 'none';
  }

  buildBreadcrumbsIndex() {
    this.breadcrumbsIndex = {};

    const buildBreadcrumbs = (items, prefix) => {
      items.forEach(item => {
        const itemHtml = this.breadcrumbsItemTemplate(item);
        this.breadcrumbsIndex[item.categoryId] = prefix + itemHtml;

        if (item.children) {
          buildBreadcrumbs(
            item.children,
            `${prefix}${itemHtml}${this.params.templates.breadcrumbsSeparator}`
          )
        }
      });
    }

    buildBreadcrumbs(this.params.items, '');
  }

  renderBreadcrumbs() {
    if (!this.breadcrumbsContainer) return;

    const { categoryId } = this.state.filter;
    if (!this.breadcrumbsIndex) {
      this.buildBreadcrumbsIndex();
    }

    if (!this.breadcrumbsIndex[categoryId]) return;

    this.breadcrumbsContainer.innerHTML = this.breadcrumbsIndex[categoryId];

    this.breadcrumbsContainer.querySelectorAll('[data-category-id]')
      .forEach(node => node.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        const categoryId = e.target.getAttribute('data-category-id');
        if (categoryId) {
          this.setCategoryId(categoryId);
        }
      }))
  }

  updateSelected() {
    const { categoryId } = this.state.filter;
    const li = this.mainElement.querySelector(`[data-tree-item-catid="${categoryId}"]`);
    const ul = li.parentElement;
    const activeNodes = ul.querySelectorAll('.active');
    activeNodes.forEach(active => active.classList.remove('active'));
    li.classList.add('active');
  }

  renderTree(items) {
    const ul = document.createElement('ul');
    items.forEach(item => {
      const li = document.createElement('li');
      li.innerHTML = `<span>${item.label}</span>`;
      if (item.categoryId) {
        li.setAttribute('data-tree-item-catid', item.categoryId);
      }
      if (Array.isArray(item.children) && item.children.length) {
        li.appendChild(this.renderTree(item.children));
      }
      ul.appendChild(li);

      li.querySelector('span')
        .addEventListener('click', (e) => this.handleItemClick(e, item));
    });

    return ul;
  }

  render() {
    this.mainElement.appendChild(this.renderTree(this.params.items));
    this.renderBreadcrumbs();
    return this.mainElement;
  }
}

export default (params) => {
  return new TreeViewMenu(params);
};
