import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default ({ className, selected, categories, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.categoryRoot, className)}>{
      <Select
        className={classes.categorySelect}
        value={selected || 'default'}
        onChange={(event) => {
          onChange(
            event.target.value !== 'default'
              ? event.target.value
              : ''
          );
        }}
      >
        {/* <MenuItem
          value={'default'}
          className='category-menu-item'
        >No category selected</MenuItem> */}
        {categories && Object.values(categories).map(({ category, displayName, label }) => (
          <MenuItem
            key={`category-${category}`}
            value={category}
            className='category-menu-item'
          >
            {label || displayName}
          </MenuItem>
        ))}
      </Select>
    }</div>
  );
};

const useStyles = makeStyles({
  categoryRoot: {
    width: '200px',
    marginLeft: '75px',
    marginRight: '35px',
  },
  categorySelect: {
    '&:before': {
      borderBottom: 0,
    },
  },
});
