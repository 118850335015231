import designSolidSvg from '@yesplz/core-models/assets/svg/design-solid.svg';
import designPatternSvg from '@yesplz/core-models/assets/svg/design-pattern.svg';
import designDetailSvg from '@yesplz/core-models/assets/svg/design-detail.svg';
import designDestroyedSvg from '@yesplz/core-models/assets/svg/design-destroyed.svg';
// import designLaceUpSvg from '@yesplz/core-models/assets/svg/design-lace-up.svg';
// import designLightSvg from '@yesplz/core-models/assets/svg/design-light.svg';
import designLeatherSvg from '@yesplz/core-models/assets/svg/design-leather.svg';
import designDenimSvg from '@yesplz/core-models/assets/svg/design-denim.svg';
// import designSlacksSvg from '@yesplz/core-models/assets/svg/design-slacks.svg';
import designWoolSvg from '@yesplz/core-models/assets/svg/design-wool.svg';
import designDownSvg from '@yesplz/core-models/assets/svg/design-down.svg';
// import designWorkoutSvg from '@yesplz/core-models/assets/svg/design-workout.svg';
// import designLeggingsSvg from '@yesplz/core-models/assets/svg/design-leggings.svg';
// import designSuedeSvg from '@yesplz/core-models/assets/svg/design-suede.svg';
// import designAnimalSvg from '@yesplz/core-models/assets/svg/design-animal.png';
import designCottonSvg from '@yesplz/core-models/assets/svg/design-cotton.svg';
// import materialFleeceSvg from '@yesplz/core-models/assets/svg/material-fleece.svg';
// import materialGoldSvg from '@yesplz/core-models/assets/svg/material-gold.svg';
// import materialRoseGoldSvg from '@yesplz/core-models/assets/svg/material-rose-gold.svg';
// import materialSilverSvg from '@yesplz/core-models/assets/svg/material-silver.svg';
// import materialPearlSvg from '@yesplz/core-models/assets/svg/material-pearl.svg';

// For local testing
var vf_svg_url = function (path) { return require('@yesplz/core-models/assets/svg/'+path) }

export const paternsFilterParams = {
  designTypes: [
    {type: 'solid', label: 'Solid', image: designSolidSvg, remove: 'pattern'},
    {type: 'pattern', label: 'Pattern', image: designPatternSvg, remove: 'solid', className: 'patterns-pattern'},
    {type: 'detail', label: 'Details', image: designDetailSvg, activeIn: ['wtop', 'wknit', 'wdress', 'wouter', 'wpants', 'wskirt']},
    {type: 'ripped-off', label: 'Destroyed', image: designDestroyedSvg, activeIn: ['wpants']},
    // {type: 'lace-up', label: 'Shoelaces', image: designLaceUpSvg, activeIn: ['wdressshoes']},
    // {type: 'animal', label: 'Animal Print', image: designAnimalSvg, activeIn: ['wwallet']},
  ]
};

export const materialsFilterParams = {
  materialTypes: [
    // {type: 'light', label: 'Light', image: designLightSvg, activeIn: ['wouter', 'mouter']},
    {type: 'leather', label: 'Leather', image: designLeatherSvg, activeIn: ['wouter']},
    {type: 'denim', label: 'Denim', image: designDenimSvg, activeIn: ['wpants']},
    {type: 'cotton', label: 'Cotton', image: designCottonSvg, activeIn: ['wpants']},//'mouter', 'wouter'
    {type: 'wool', label: 'Wool', image: designWoolSvg, activeIn: ['wpants']}, //'mouter', 'wouter'
    {type: 'down', label: 'Down/Puffer', image: designDownSvg, activeIn: ['wouter']},
    // {type: 'workout', label: '운동/아웃도어', image: designWorkoutSvg, activeIn: ['wpants', 'mpants']},
    // {type: 'polyester', label: 'Polyester', image: designWoolSvg, activeIn: ['wpants']}, // TODO: Update image
    // {type: 'fleece', label: 'Fleece', image: materialFleeceSvg, activeIn: ['wouter']},
  ]
};

export const paramLabelsParams = {
  showPartNameFor: {
    'wtop': ['sleeves'],
    'wdress': ["sleeves"],
    'wpants': ["length", "ankle"],
  },
  labels: {
    'wtop': {
      "neckline": { "open": "Open Neck", "round": "Round Neck", "high": "High Neck" },
      'waist': { 'straight': 'Regular Fit', 'wide': 'Loose Fit' },
      'length': { 'short': "Regular Length", "long": "Long Length"}
    },
    'wdress': {
      "neckline": { "open": "Open Neck", "round": "Round Neck", "high": "High Neck" },
      'waist': { 'wide': 'Loose Fit' },
      "style": {"plain": "Plain Style"},
      "length": {"short":"Short Length", "knees":"Knee Length", "semi_long": "Midi", "very_long": "Max"}
    },
    'wknit': {
      "neckline": { "open": "Open Neck", "round": "Round Neck", "high": "High Neck" },
      "sleeves": { "short": "Short Sleeves", "long": "Long Sleeves"},
      "short": {"short": "Short Length", "medium": "Regular Length", "long": "Long Length"}
    },
    'wskirt': {
      "thigh": {"tight": "Fitted", "wide": "Loose Fit"},
      "style": {"plain": "Plain Style"},
      "length": {"short":"Short Length", "knees":"Knee Length", "semi_long": "Midi", "very_long": "Max"}
    },
    'wpants': {
      'tightness': {"tight": "Skinny", "straight": "Medium Fit", "wide": "Loose Fit"}
    },
    'wouter': {
      "neckline": { "round": "Round Neck", "Wide": "Wide Collar", "high": "High Neck" },
      'waist': { 'wide': 'Loose Fit' },
      "sleeves": { "short": "Short Sleeves", "long": "Long Sleeves"},
      'length': { 'short': "Short Length", "half": "Regular Length", "long": "Long Length"}
    },
  },
};

export const categories = {
  "wtop": {
    "label": "Women Tops",
    "category": "wtop",
    "partList" : ["neckline", "sleeves", "waist", "length"],
    "partListLabels" : ["Neckline", "Sleeves", "Waist", "Length"],
    "defaultVal": {"neckline": "all", "sleeves": "all", "waist": "all", "length": "all"},
    "presetList": {
      "Comfy":   {"neckline": "round", "sleeves": "short",      "waist": "wide",    "length": "short"},
      "Tank Top":    {"neckline": "round", "sleeves": "wide_strap", "waist": "straight", "length": "long"},
      "Off Top":{"neckline": "open",  "sleeves": "long",       "waist": "tight",    "length": "short"},
      "Cami":        {"neckline": "v_neck","sleeves": "thin_strap","waist": "straight", "length": "short"},
      // "Hoodie":      {"neckline": "hoodie","sleeves": "long",       "waist": "wide",     "length": "short"},
      "Tunic":       {"neckline": "round", "sleeves": "long",       "waist": "straight", "length": "long"}
    },
    "vfSvgSource": vf_svg_url('white-all/vf_wtop_core.svg'),
    "tnSprite": vf_svg_url('white-all/TN-Women-Tops-2.0_OptionAll.svg'),
    "tn": {
      "length": {
        "cropped": {"label":"Cropped", "tnClass": "tn-3-0"},
        "short": {"label": "Regular","tnClass": "tn-3-1"},
        "long": {"label": "Long","tnClass": "tn-3-2"},
        "all": {"label": "Reset", "tnClass": "tn-3-3"}
      },
      "neckline": {
        "open": {"label": "Open","tnClass": "tn-0-0"},
        "v_neck": {"label": "V-neck","tnClass": "tn-0-1"},
        "round": {"label": "Round","tnClass": "tn-0-2"},
        "high": {"label": "High","tnClass": "tn-0-3"},
        "collar": {"label": "Collar","tnClass": "tn-0-4"},
        "hoodie": {"label": "Hoodie","tnClass": "tn-0-5"},
        "all": {"label": "Reset", "tnClass": "tn-0-6"}
      },
      "sleeves": {
        "thin_strap": {"label": "Cami","tnClass": "tn-1-0"},
        "wide_strap": {"label": "Tank","tnClass": "tn-1-1"},
        "short": {"label": "Short","tnClass": "tn-1-2"},
        "elbow": {"label": "Elbow","tnClass": "tn-1-3"},
        "three_quarters": {"label": "3/4","tnClass": "tn-1-4"},
        "long": {"label": "Long","tnClass": "tn-1-5"},
        "all": {"label": "Reset", "tnClass": "tn-1-6"}        
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-2-0"},
        "straight": {"label": "Regular","tnClass": "tn-2-1"},
        "wide": {"label": "Loose","tnClass": "tn-2-2"},
        "all": {"label": "Reset", "tnClass": "tn-2-3"}
      }
    },
    overrideBodyPartGroupName: function (state, prop, val) {
      // if (prop === 'shoulder') {
      //   if (val === 'closed' && state['sleeves'] === 'sleeveless') {
      //     return 'shoulder_closed_for_sleeves_sleeveless'
      //   }
      // }
      if (prop === 'length') { // && ['short', 'long'].includes(val)) {
        return 'length_' + val + '_waist_' + state['waist']
      }
      if (prop === 'waist') {
        return 'no_display_group'
      }
      if (prop === 'neckline') {
        if (['round', 'v_neck'].includes(val) && state['sleeves'] === 'thin_strap') {
          return 'neckline_' + val + '_' +  state['sleeves']
        }
        if (val === 'collar') {
          return 'neckline_collar_length_' + state['length']
        }
        if (val === 'open' && ['thin_strap', 'wide_strap'].includes(state['sleeves'])) {
          return 'no_display_group'
        }
        if (val === 'all' && state['sleeves'] === 'thin_strap') {
          return 'neckline_all_thin_strap'
        }
      }
      if (prop === 'sleeves') {
        if (['thin_strap', 'wide_strap'].includes(val) && state['neckline'] === 'open') {
          return 'sleeves_' + val + '_neckline_open'
        }
      }
      return null
    }
  },
  "wdress": { // style and waist is removed
    "label": "Women Dresses",
    "category": "wdress",
    "partList": ["neckline", "sleeves","length"], // , "waist" ,"style",
    "partListLabels" : ["Neckline", "Sleeves", "Length"], // , "Waist" , "Style",
    "defaultVal": {"neckline": "all", "sleeves": "all", "length": "all"}, // "waist": "all", "style": "all"
    "presetList": {
      "Maxi":    {"neckline": "v_neck", /* "waist": "tight", */ "sleeves": "elbow", "length": "very_long", /* "style": "pleated" */},
      "Shirt":   {"neckline": "collar", /* "waist": "tight",  */ "sleeves": "elbow", "length": "semi_long", /* "style": "plain" */},
      "Body-con":{"neckline": "round", /* "waist": "tight",  */ "sleeves": "thin_strap", "length": "short", /* "style": "plain" */},
      "Slit":    {"neckline": "round", /* "waist": "tight",  */ "sleeves": "short", "length": "semi_long", /* "style": "slit" */},
      "T-shirt": {"neckline": "round", /* "waist": "wide",  */ "sleeves": "elbow", "length": "short", /* "style": "plain" */},
      "Slip":    {"neckline": "v_neck", /* "waist": "wide",  */ "sleeves": "thin_strap", "length": "semi_long", /* "style": "plain" */}
    },
    "vfSvgSource": vf_svg_url('white-all/vf_wdress_no_waist_no_style_core.svg'),
    "tnSprite": vf_svg_url('white-all/TN-Women-Dress-2.0_OptionAll.svg'),
    "tn": {
      "neckline": {
        "open": {"label": "Off-Shoulder","tnClass": "tn-0-0"},
        "v_neck": {"label": "V-Neck","tnClass": "tn-0-1"},
        "round": {"label": "Round","tnClass": "tn-0-2"}, // round + square
        "collar": {"label": "Collar","tnClass": "tn-0-3"},
        "high": {"label": "High","tnClass": "tn-0-4"},
        "all": {"label": "Reset", "tnClass": "tn-0-5"}
      },
      // "shoulder": {
      //   "open": {"label": "Open", "tnClass": "tn-1-0"},
      //   "thin": {"label": "Thin","tnClass": "tn-1-1"},
      //   "wide": {"label": "Wide","tnClass": "tn-1-2"},
      //   "closed": {"label": "Closed","tnClass": "tn-1-3"}
      // },
      "sleeves": {
        "thin_strap": {"label": "Cami","tnClass": "tn-1-0"},
        "wide_strap": {"label": "Tank","tnClass": "tn-1-1"},
        "short": {"label": "Short","tnClass": "tn-1-2"},
        "elbow": {"label": "Elbow","tnClass": "tn-1-3"},
        "three_quarters": {"label": "3/4","tnClass": "tn-1-4"},
        "long": {"label": "Long","tnClass": "tn-1-5"},
        "all": {"label": "Reset", "tnClass": "tn-1-6"}
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-3-0"},
        "knees": {"label": "Knees","tnClass": "tn-3-1"},
        "semi_long": {"label": "Midi","tnClass": "tn-3-2"},
        "very_long": {"label": "Long","tnClass": "tn-3-3"},
        "all": {"label": "Reset", "tnClass": "tn-3-4"}
      },
      // "style": {
      //   "plain": {"label": "Plain","tnClass": "tn-4-0"},
      //   "pleated": {"label": "Pleated","tnClass": "tn-4-1"},
      //   "slit": {"label": "Slit","tnClass": "tn-4-2"},
      //   "ruffle": {"label": "Ruffle","tnClass": "tn-4-3"},
      //   "all": {"label": "Reset", "tnClass": "tn-4-5"}
      // },
      // "waist": {
      //   "tight": {"label": "Fitted","tnClass": "tn-2-0"}, // tight + straight
      //   // "straight": {"label": "Regular","tnClass": "tn-2-1"},
      //   "wide": {"label": "Loose","tnClass": "tn-2-1"},
      //   "all": {"label": "Reset", "tnClass": "tn-2-2"}
      // }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'style' && val !== 'plain') {
        return 'style_' + val + '_for_length_' + state['length']
      }
      if (prop === 'shoulder' && val === 'closed') {
        if (state['sleeve'] === 'none') {
          return 'shoulder_closed_for_sleeve_none'
        }
      }
      if (prop === 'neckline') {
        if (val === 'open' && ['thin_strap', 'wide_strap'].includes(state['sleeves'])) {
          return 'no_display_group'
        } else if ((val === 'round' || val === 'v_neck') &&  state['sleeves'] == 'thin_strap') {
          return 'neckline_' + val + '_sleeves_thin_strap'
        }
      }
      if (prop === 'sleeves') {
        if (['thin_strap', 'wide_strap'].includes(val) && state['neckline'] === 'open') {
          return 'sleeves_' + val + '_neckline_open'
        }
      }
      return null
    },
  },
  "wouter": {
    "label": "Women Outerwears",
    "category": "wouter",
    "partList": ["necktype","length","sleeves","waist"],
    "partListLabels" : ["Necktype", "Length", "Sleeves", "Waist"],
    "defaultVal": {"necktype": "all", "sleeves": "all","waist": "all", "length": "all"},
    "presetList": {
      "Blazer":    {"necktype": "notch",  "sleeves": "long", "waist": "tight", "length": "half", "material": "wool"},
      "Overcoat":  {"necktype": "notch",  "sleeves": "long", "waist": "wide", "length": "long", "material": "wool"},
      "Jackets":   {"necktype": "collar",  "sleeves": "long", "waist": "tight", "length": "short"},
      "Parka":     {"necktype": "hoodie", "sleeves": "long", "waist": "wide", "length": "long", "material": "down"},
      "Pullover":  {"necktype": "high",   "sleeves": "long", "waist": "wide", "length": "half", "material": "fleece"},
      "Moto":      {"necktype": "notch",  "sleeves": "long", "waist": "tight", "length": "short", "material": "leather"}
    },
    "vfSvgSource": vf_svg_url('white-all/vf_wouter_core.svg'),
    "tnSprite": vf_svg_url('white-all/TN-Women-Outerwears-2.0_OptionAll.svg'),
    "tn": {
      "necktype": {
        "notch": {"label": "Notch","tnClass": "tn-0-0"},
        "round": {"label": "Round","tnClass": "tn-0-1"},
        "collar": {"label": "Collar","tnClass": "tn-0-2"},
        "hoodie": {"label": "Hoodie","tnClass": "tn-0-3"},
        "high": {"label": "High","tnClass": "tn-0-4"},
        // "wide_collar": {"label": "Wide","tnClass": "tn-0-5"},
        "all": {"label": "Reset", "tnClass": "tn-0-6"}
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-1-0"},
        "half": {"label": "Regular","tnClass": "tn-1-1"},
        "long": {"label": "Long","tnClass": "tn-1-2"},
        "all": {"label": "Reset", "tnClass": "tn-1-3"}
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-3-0"},
        // "straight": {"label": "Regular","tnClass": "tn-3-1"},
        "wide": {"label": "Loose","tnClass": "tn-3-1"},
        "all": {"label": "Reset", "tnClass": "tn-3-2"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-2-0"},
        "short": {"label": "Short","tnClass": "tn-2-1"},
        "long": {"label": "Regular","tnClass": "tn-2-2"},
        "all": {"label": "Reset", "tnClass": "tn-2-3"}
      }
    },
    // "extraBodyGroups": ["sleeves_frame"],
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      if (prop === 'waist') {
        return 'no_display_group'
      }
      if (prop === 'sleeves' && val === 'sleeveless') {
        return 'sleeves_sleeveless_waist_' + state['waist']
      }
      return null
    }
  },
  "wknit": {
    "label": "Women Knits",
    "category": "wknit",
    "partList": ["cardigan","neckline","sleeves","waist","length"],
    "partListLabels" : ["Cardigan", "Neckline", "Sleeves", "Waist", "Length"],
    "defaultVal": {"neckline": "all", "sleeves": "all", "waist": "all", "length": "all", "cardigan": "sweater"},
    "presetList": {
      "High Neck": {"neckline": "high",   "sleeves": "long", "waist": "tight", "length": "medium", "cardigan": "sweater", "design":"solid"},
      "Cardigan":  {"neckline": "v_neck", "sleeves": "long", "waist": "tight", "length": "long", "cardigan": "cardigan"},
      "Sweater":   {"neckline": "round",  "sleeves": "short", "waist": "tight", "length": "medium", "cardigan": "sweater"},
      "Cropped":   {"neckline": "v_neck", "sleeves": "short", "waist": "tight", "length": "short", "cardigan": "cardigan"},
      "Oversized": {"neckline": "round",  "sleeves": "long", "waist": "wide", "length": "medium", "cardigan": "sweater"},
      "Tank":      {"neckline": "v_neck", "sleeves": "sleeveless", "waist": "tight", "length": "medium", "cardigan": "sweater"}
    },
    "vfSvgSource": vf_svg_url('white-all/vf_wknit_core.svg'),
    "tnSprite": vf_svg_url('white-all/TN-Women-Knits-2.0-OptionAll.svg'),
    "tn": {
      "neckline": {
        "v_neck": {"label": "V-neck","tnClass": "tn-1-0"},
        "round": {"label": "Round","tnClass": "tn-1-1"},
        "high": {"label": "High","tnClass": "tn-1-2"},
        "all": {"label": "Reset", "tnClass": "tn-1-3"}
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-3-0"},
        // "straight": {"label": "Regular","tnClass": "tn-3-0"},
        "wide": {"label": "Loose","tnClass": "tn-3-1"},
        "all": {"label": "Reset", "tnClass": "tn-3-2"}
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-4-0"},
        "medium": {"label": "Regular","tnClass": "tn-4-1"},
        "long": {"label": "Long","tnClass": "tn-4-2"},
        "all": {"label": "Reset", "tnClass": "tn-4-3"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-2-0"},
        "short": {"label": "Short","tnClass": "tn-2-1"},
        "long": {"label": "Long","tnClass": "tn-2-2"},
        "all": {"label": "Reset", "tnClass": "tn-2-3"}
      },
      "cardigan": {
        "sweater": {"label": "Sweater","tnClass": "tn-0-0"},
        "cardigan": {"label": "Cardigan","tnClass": "tn-0-1"}
        // No all option in cardigan
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      if (prop === 'neckline') {
        return 'neckline_' + val + '_' + state['cardigan']
      }
      if (prop === 'waist' || prop === 'cardigan') {
        return 'no_display_group'
      }
      return null
    }

  },
  "wpants": {
    "label": "Women Pants",
    "category": "wpants",
    "partList" : ["rise", "length", "tightness", "ankle"],
    "partListLabels" : ["Rise", "Length", "Tightness", "Ankle"],
    "defaultVal" : {"rise": "all", "tightness": "all", "length": "all",  "ankle": "all"},
    "presetList": {
      "Skinny": {"rise": "low", "tightness": "tight", "length": "long",  "ankle": "tight", "material": "denim"},
      "Slacks": {"rise": "low", "tightness": "straight", "length": "long",  "ankle": "straight", "material": "wool"},
      "Boot Cut": {"rise": "low", "tightness": "tight", "length": "long",  "ankle": "straight"},
      "Jogger": {"rise": "low", "tightness": "wide", "length": "long",  "ankle": "tight", "material": "cotton"},
      "Leggings": {"rise": "low", "tightness": "tight", "length": "cropped",  "ankle": "tight"},
      "Wide": {"rise": "low", "tightness": "wide", "length": "long",  "ankle": "wide"}
    },
    "vfSvgSource": vf_svg_url('white-all/vf_wpants_core.svg'),
    "tnSprite": vf_svg_url('white-all/TN-Women-Pants-v2.0_OptionAll.svg'),
    "tn" : {
      "rise": {
        "low": { "label": "Low Rise", "tnClass": "tn-0-0" },
        "medium_high": { "label": "Mid-High Rise", "tnClass": "tn-0-1" },
        "all": {"label": "Reset", "tnClass": "tn-0-2"}
      },
      "length": {
        "short": { "label": "Shorts", "tnClass": "tn-1-0" },
        "cropped": { "label": "Cropped", "tnClass": "tn-1-1" },
        "long": { "label": "Regular", "tnClass": "tn-1-2" },
        "all": {"label": "Reset", "tnClass": "tn-1-3"}
      },
      "tightness": {
        "tight": { "label": "Skinny", "tnClass": "tn-2-0" },
        "straight": { "label": "Slim", "tnClass": "tn-2-1" },
        "wide": { "label": "Loose", "tnClass": "tn-2-2" },
        "all": {"label": "Reset", "tnClass": "tn-2-3"}
      },
      "ankle": {
        "tight": { "label": "Narrow", "tnClass": "tn-3-0" },
        "straight": { "label": "Straight", "tnClass": "tn-3-1" },
        "wide": { "label": "Wide", "tnClass": "tn-3-2" },
        "all": {"label": "Reset", "tnClass": "tn-3-3"}
      }
    },
    evaluateDisabledParts(filter) {
      const disabled = [];
      if (filter['length'] === 'short' || filter['length'] === 'knee') {
        disabled.push('ankle');
      }
      return disabled;
    },
    overrideBodyPartGroupName: function (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_tightness_' + state['tightness']
      }
      if (prop === 'ankle') {
        if (state['length'] == 'short') {
          return 'no_display_group'
        }
        return 'ankle_' + val + '_length_' + state['length'] + '_tightness_' + state['tightness']
      }
      if (prop === 'tightness') {
        return 'no_display_group'
      }
      return null
    }
  },
  "wskirt": {
    "label": "Women Skirts",
    "category": "wskirt",
    "partList": ["length","thigh","style"],
    "partListLabels" : ["Length", "Tightness", "Style"],
    "defaultVal": {"thigh": "all", "length": "all", "style": "all"},
    "presetList": {
      "Midi": {"thigh": "wide", "length": "semi_long", "style": "plain"},
      "Pleated": {"thigh": "wide", "length": "very_long", "style": "pleated"},
      "Ruffle": {"thigh": "wide", "length": "knees", "style": "ruffle"},
      "Long": {"thigh": "wide", "length": "very_long", "style": "plain"},
      "Mini": {"thigh": "tight", "length": "short", "style": "plain"},
      "Slit": {"thigh": "tight", "length": "knees", "style": "slit"},
    },
    "vfSvgSource": vf_svg_url('white-all/vf_wskirt_core.svg'),
    "tnSprite": vf_svg_url('white-all/TN-Women-Skirts-2.0_OptionAll.svg'),
    "tn": {
      "length": {
        "short": {"label": "Short","tnClass": "tn-0-0"},
        "knees": {"label": "Knees","tnClass": "tn-0-1"},
        "semi_long": {"label": "Midi","tnClass": "tn-0-2"},
        "very_long": {"label": "Long","tnClass": "tn-0-3"},
        "all": {"label": "Reset", "tnClass": "tn-0-4"}
      },
      "thigh": {
        "tight": {"label": "Fitted","tnClass": "tn-1-0"},
        "wide": {"label": "Loose","tnClass": "tn-1-1"},
        "all": {"label": "Reset", "tnClass": "tn-1-2"}
      },
      "style": {
        "plain": {"label": "Plain","tnClass": "tn-2-0"},
        "pleated": {"label": "Pleated","tnClass": "tn-2-1"},
        "slit": {"label": "Slit","tnClass": "tn-2-2"},
        "ruffle": {"label": "Ruffle","tnClass": "tn-2-3"},
        "all": {"label": "Reset", "tnClass": "tn-2-6"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_thigh_' + state['thigh']
      }
      if (prop === 'style') {
        if (['pleated', 'ruffle'].includes(val)) {
          return 'style_' + val + '_length_' + state['length'] + '_thigh_' + state['thigh']
        } else if (val === 'slit') {
          return 'style_' + val + '_length_' + state['length']
        } else {
          return 'style_' + val
        }
      }
      if (prop === 'thigh') {
        return 'no_display_group'
      }
      return null
    }
  },
};
