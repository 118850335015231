import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import client from '@yesplz/client';
import * as widgets from './widgets';
import EventEmitter from './modules/EventEmitter';
import Hooks from './modules/Hooks';
import { categoriesConfig } from '@yesplz/core-models/src/CategoryConfigs';
import { startHistoryListener } from './modules/history';
import { store, configureStore } from './store';
import { setCategories } from './store/categories';
import { setFilterAndSearch, hydrateFromUrl } from './store/actions/filterActions';
import { runSearch } from './store/actions/searchActions';
import { setConfigValue, setSearchFinishedReturnFields, setUseUrlHistory, toggleTooltips } from './store/actions/configActions';

/**
 * Mediator and Core Module for VisualFilter
 */
export default class VisualFilter {
  $hooks = Hooks;
  widgetsMountQueue = [];
  widgetsContainer = [];
  initialized = false;

  constructor(params) {
    const {
      categories = categoriesConfig,
      noFilterCategories,
      clientBaseURL,
      usePersistentFilter = true,
      useUrlHistory = false,
      useSearch = true,
      requestConfig,
      searchAdditionalParams,
      searchFinishedReturnFields,
      initialFilter,
      isAdmin,
    } = params;

    this.initialFilter = initialFilter ?? null;

    if (isAdmin) {
      client.setIsAdmin(isAdmin);
    }

    if (clientBaseURL) {
      client.setBaseURL(clientBaseURL);
    }

    configureStore(usePersistentFilter);

    if (searchFinishedReturnFields !== undefined) {
      store.dispatch(
        setSearchFinishedReturnFields(searchFinishedReturnFields)
      );
    }

    if (useUrlHistory) {
      startHistoryListener();
      store.dispatch(setUseUrlHistory(true));
    }

    store.dispatch(setConfigValue({
      key: 'useSearch',
      value: useSearch,
    }));

    if (searchAdditionalParams && typeof searchAdditionalParams === 'object') {
      store.dispatch(setConfigValue({
        key: 'searchAdditionalParams',
        value: searchAdditionalParams,
      }));
    }

    if (requestConfig && typeof requestConfig === 'object') {
      store.dispatch(setConfigValue({
        key: 'requestConfig',
        value: requestConfig,
      }));
    }

    this.noFilterCategories = noFilterCategories || [];
    this.categories = categories;
    // Setting categories to be accessible for the store components.
    // TODO: On improving categories config management, remove.
    setCategories(categories);

    this.widgets = widgets;

    this.state = store.getState();
    if (
      usePersistentFilter
      &&
      !(this.state._persist && this.state._persist.rehydrated)
      &&
      !(this.state.filter._persist && this.state.filter._persist.rehydrated)
    ) {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState();
        if (
          state._persist.rehydrated
          &&
          state.filter._persist.rehydrated
        ) {
          unsubscribe();
          this.handleAfterStoreRehydrated(state);
        }
      });
    }
    else {
      this.validateStateAndInitialize();
    }
  }

  handleAfterStoreRehydrated(newState) {
    this.state = newState;
    this.validateStateAndInitialize();
  }

  validateStateAndInitialize() {
    store.subscribe(() => this.state = store.getState());

    if (this.state.config.useUrlHistory) {
      store.dispatch(hydrateFromUrl());
    }

    if (
      !this.state.filter.categoryId
      ||
      !this.isFilterValid(this.state.filter)
      ||
      this.initialFilter
    ) {
      const categoryId = this.initialFilter && this.initialFilter.categoryId
        ? this.initialFilter.categoryId
        : this.state.filter.categoryId
          &&
          (this.categories[this.state.filter.categoryId] || this.noFilterCategories.includes(this.state.filter.categoryId))
          ? this.state.filter.categoryId
          : Object.keys(this.categories)[0];

      const savedFilter = this.state.savedFilters[categoryId];
      this.setFilterAndSearch({
        ...(
          savedFilter && this.isFilterValid(savedFilter)
            ? savedFilter
            : this.getCategoryDefaults(categoryId)
        ),
        ...(
          this.initialFilter && this.initialFilter.categoryId
            ? this.initialFilter
            : {}
        ),
      }, true);
    }
    else {
      store.dispatch(runSearch(this.categories));
    }

    this.mountWidgets();

    this.initialized = true;
  }

  isFilterValid(filter) {
    const category = this.categories[filter.categoryId];

    if (!category) return false;

    if (filter.category !== category.category) return false;

    const pickedParts = pick(filter.params, category.partList);
    if (category.partList.length !== Object.keys(pickedParts).length) return false;

    return true;
  }

  setSort = (sort) => {
    this.setFilterAndSearch({ sort });
  }

  setLimit = (limit) => {
    this.setFilterAndSearch({ limit });
  }

  setOffset = (offset) => {
    this.setFilterAndSearch({ offset });
  }

  setFilter = (filter) => {
    this.setFilterAndSearch(filter);
  }

  setFilterAndSearch = (filter, forceSearch) => {
    return store.dispatch(setFilterAndSearch(filter, this.categories, forceSearch));
  }

  getFilter = () => {
    const { filter } = store.getState();
    return cloneDeep(filter);
  }

  setUseSearch = (useSearch) => {
    store.dispatch(setConfigValue({
      key: 'useSearch',
      value: useSearch,
    }));
  }

  toggleTooltips = () => {
    store.dispatch(toggleTooltips());
  }

  getCategoryDefaults(categoryId) {
    if (this.noFilterCategories.includes(categoryId)) return {};

    const categories = this.categories;

    if (!categories[categoryId]) {
      console.log("Unsupported CategoryId", categoryId, "Supported Categories are", Object.keys(categories));
    }

    const bodyPart = (
      categories[categoryId].partList && categories[categoryId].partList.length
        ? categories[categoryId].partList[0]
        : null
    );
    const params = {
      ...(
        categories[categoryId].defaultVal
          ? categories[categoryId].defaultVal
          : {}
      ),
      color: [],
    };
    return {
      categoryId,
      category: categories[categoryId].category,
      bodyPart,
      params,
      presetIndex: null,
      occasion: null,
    };
  }

  setCategory = (categoryId) => {
    const savedFilter = this.state.savedFilters[categoryId];
    const filter = savedFilter && this.isFilterValid(savedFilter)
      ? this.state.savedFilters[categoryId]
      : this.getCategoryDefaults(categoryId);

    EventEmitter.emit('categoryChanged', categoryId);
    return this.setFilterAndSearch({
      ...filter,
      offset: 0,
    });
  }

  currentCategoryHasPresets() {
    return (
      this.categories[this.state.filter.categoryId].presetList
      &&
      this.categories[this.state.filter.categoryId].presetList.length
    );
  }

  setPrevPreset = () => {
    this.changePreset(false);
  }

  setNextPreset = () => {
    this.changePreset();
  }

  changePreset(isNext = true) {
    const categoryConfig = this.categories[this.state.filter.categoryId];

    if (isEmpty(categoryConfig.presetList)) {
      return null;
    }

    const presetsKeys = Object.keys(categoryConfig.presetList);

    let presetKeyIndex = 0;
    if (this.state.filter.presetIndex !== null) {
      const currentPresetKeyIndex = presetsKeys.indexOf(
        this.state.filter.presetIndex
      );
      if (isNext) {
        presetKeyIndex = currentPresetKeyIndex === presetsKeys.length - 1
          ? 0
          : currentPresetKeyIndex + 1;
      }
      else {
        presetKeyIndex = currentPresetKeyIndex === 0
          ? presetsKeys.length - 1
          : currentPresetKeyIndex - 1;
      }
    }
    this.setFilterAndSearch({
      params: { ...categoryConfig.presetList[presetsKeys[presetKeyIndex]] },
      presetIndex: presetsKeys[presetKeyIndex],
    });

    EventEmitter.emit('presetChange', presetsKeys[presetKeyIndex]);
  }

  addWidget(widget) {
    if (!this.initialized) {
      this.widgetsMountQueue.push(widget);
    }
    else {
      this.mountWidget(widget);
    }
  }

  mountWidgets() {
    this.widgetsMountQueue.map(this.mountWidget);
  }

  mountWidget = widget => {
    this.widgetsContainer.push(widget);
    widget.main = this;
    widget.mount();
  }

  on(event, subscriber) {
    EventEmitter.on(event, subscriber);
  }

  off(event, subscriber) {
    EventEmitter.off(event, subscriber);
  }
}
