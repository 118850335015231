import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import client from '@yesplz/client';
import Fuse from 'fuse.js';
import Widget from '../modules/Widget';

const { document } = window;

class BrandFilter extends Widget {
  defaultParams = {
    title: 'Brands',
    displaySearchBar: false,
    displayHeaders: false,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.brandsByCategories = {};

    this.fuse = null;
    this.ul = document.createElement('ul');
  }

  didMount() {
    this.fetchAndRenderBrands();
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.fetchAndRenderBrands();
    }
    else if (
      !isEqual(prevState.filter.params.brands, this.state.filter.params.brands)
    ) {
      const prevBrands = prevState.filter.params.brands;
      const brands = this.state.filter.params.brands;
      const addedItems = difference(brands, prevBrands);
      const removedItems = difference(prevBrands, brands);
      addedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
        if (!el) return;

        el.classList.add('active');
      });
      removedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
        if (!el) return;

        el.classList.remove('active');
      });

      this.renderSelectedBrands();
    }
  }

  handleItemClick = (e, brand) => {
    e.stopPropagation();
    const { params } = this.state.filter;
    let { brands = [] } = params;
    brands = brands.includes(brand.brandsFilter)
      ? brands.filter(b => b !== brand.brandsFilter)
      : [...brands, brand.brandsFilter];
    this.setFilter({
      params: {
        ...params,
        brands,
      },
    });
  }

  async fetchAndRenderBrands() {
    const { categoryId } = this.state.filter;
    const { searchAdditionalParams } = this.state.config;
    if (!this.brandsByCategories[categoryId]) {
      this.brandsByCategories[categoryId] = await client.brandsByCategories(categoryId, searchAdditionalParams);
    }

    this.fuse = new Fuse(this.brandsByCategories[categoryId], {
      includeScore: true,
      includeMatches: true,
      threshold: 0.4,
      keys: ['label'],
    });

    this.mainElement.innerHTML = '';
    this.ul = document.createElement('ul');
    this.selected = document.createElement('div');
    this.selected.classList.add('brands-selected');

    if (
      this.brandsByCategories[categoryId]
      &&
      this.brandsByCategories[categoryId].length > 20
      &&
      this.params.displaySearchBar
    ) this.renderSearchBar(this.brandsByCategories[categoryId]);

    this.mainElement.appendChild(this.selected);
    this.mainElement.appendChild(this.ul);

    this.renderSelectedBrands();
    this.renderBrands(this.brandsByCategories[categoryId], true);
  }

  search = (e) => {
    const query = e.target.value;
    const found = this.fuse.search(query);
    const items = found.map(f => f.item);
    if (items.length) {
      this.renderBrands(items);
    }
    else {
      const { categoryId } = this.state.filter;
      this.renderBrands(this.brandsByCategories[categoryId], true);
    }
  }

  removeBrand(brand) {
    const { params } = this.state.filter;
    const { brands } = params;

    this.setFilter({
      params: {
        ...params,
        brands: brands.filter(b => b !== brand),
      },
    });
  }

  renderSearchBar(brands) {
    const form = document.createElement('form');
    const input = document.createElement('input');
    input.placeholder = `Search ${brands.length} brands`;
    form.appendChild(input);
    this.mainElement.appendChild(form);

    input.addEventListener('keyup', this.search);
  }

  renderSelectedBrands() {
    const { brands } = this.state.filter.params;

    this.selected.innerHTML = '';

    if (!brands) return;

    brands.forEach(brand => {
      const element = document.createElement('span');
      element.innerHTML = brand;
      this.selected.appendChild(element);

      element.addEventListener('click', () => this.removeBrand(brand));
    });
  }

  renderBrands(brands, displayTitles) {
    const { params: { brands: currentBrands = [] } } = this.state.filter;

    if (!brands) return;

    this.ul.innerHTML = '';

    let currentLetter = null;
    brands.forEach((brand, refIndex) => {
      const nextLetter = brand.label[0] === '7' ? '#' : brand.label[0];
      if (displayTitles && currentLetter !== nextLetter) {
        currentLetter = nextLetter;
        const li = document.createElement('li');
        li.classList.add('header');
        li.innerHTML = `<span>${currentLetter}</span>`;
        this.ul.appendChild(li);
      }

      const li = document.createElement('li');
      li.innerHTML = `<span>${brand.label}</span>`;
      li.setAttribute('data-brand-id', brand.brandsFilter);
      li.setAttribute('data-brand-ref-index', refIndex);
      this.ul.appendChild(li);

      if (currentBrands.includes(brand.brandsFilter)) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, brand));
    });
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new BrandFilter(params);
};
