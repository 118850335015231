import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// core redux
import { setBugReportSetting } from '@yesplz/core-redux/ducks/admin/report'

// core web
import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants'
import BugReportIcon from '@yesplz/core-web/assets/svg/fav-item-selected.svg'

import './ProductDetail.scss'

class ProductDetail extends React.Component {
  isSaledProduct = () => {
    const { product } = this.props
    if (product.price < product.original_price) {
      return true
    }

    return false
  }

  handleBugReport = () => {
    const { setBugReportSetting } = this.props
    setBugReportSetting(true)
  }

  renderVertical (product) {
    return (
      <div className='vertical-container'>
        <div className='product-id'>{product.product_id}</div>
        <div className='product-image' title={`${product.text_predictions}`}>
          <img src={product.front_img} alt='' />
          <div className='bug-report-icon' onClick={this.handleBugReport}>
            <img src={BugReportIcon} alt='' />
          </div>
        </div>
        <div className='product-detail'>
          <h3 className='product-name'>{product.name}</h3>
          <p className='product-brand'>{product.brand}</p>
          <div className='product-price'>
            {this.isSaledProduct() && <span className='original'>{product.original_price}</span>}
            <span>{product.price}</span>
          </div>
          <div className='product-retailer'>{product.retailer}</div>
          <div className='product-sizes' />
        </div>
      </div>
    )
  }

  renderHorizontal (product) {
    const { handleClickProduct } = this.props
    return (
      <div className='horizontal-container'>
        <div className='product-image'>
          <img src={product.front_img} onClick={handleClickProduct} alt='' />
          <div className='bug-report-icon' onClick={this.handleBugReport}>
            <img src={BugReportIcon} alt='' />
          </div>
        </div>
        <div className='product-detail'>
          <div className='product-id'>{product.productId}</div>
          <h3 className='product-name'>{product.productName}</h3>
          <p className='product-brand'>{product.brandName}</p>
          {/* <div className='product-price'>
            {this.isSaledProduct() && <span className='original'>{product.original_price}</span>}
            <span>{product.price}</span>
          </div> */}
          <div className='product-retailer'>{product.retailer}</div>
          <div className='product-sizes' />
          <h3 className='product-prediction'>{product.text_predictions}</h3>
          <h3 className='product-prediction'>{product.raw_predictions}</h3>
        </div>
      </div>
    )
  }

  render () {
    const { product, isProductOnly } = this.props
    if (!product) {
      return <div className='loading-product'>Loading Product</div>
    }

    return (
      <div className='product-detail-container'>
        {isProductOnly && this.renderVertical(product)}
        {!isProductOnly && this.renderHorizontal(product)}
      </div>
    )
  }
}

ProductDetail.propTypes = {
  product: PropTypes.object,
  handleClickProduct: PropTypes.func,
  setBugReportSetting: PropTypes.func,
  isProductOnly: PropTypes.bool
}

const mapStateToProps = state => ({
  product: state.AdminProducts.currentProductInfo
})

export default connect(
  mapStateToProps,
  {
    setBugReportSetting
  }
)(ProductDetail)
