import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import {
  CATEGORY_WTOP,
  CATEGORY_WSHOES,
  CATEGORY_WPANTS,
  CATEGORY_WBAG,
  CATEGORY_MBAG,
  CATEGORY_WWALLET,
  CATEGORY_MWALLET,
  CATEGORY_WOUTER,
  CATEGORY_MOUTER,
  CATEGORY_WEARRING,
} from '../consts';
import MaterialCottonSvg from '@yesplz/core-models/assets/svg/material-cotton.svg';
import MaterialSilkSvg from '@yesplz/core-models/assets/svg/material-silk.svg';
import MaterialWrinkleFreeSvg from '@yesplz/core-models/assets/svg/material-wrinkle-free.svg';
import MaterialDenimSvg from '@yesplz/core-models/assets/svg/material-denim.svg';
import MaterialSpandexSvg from '@yesplz/core-models/assets/svg/material-spandex.svg';
import MaterialWoolSvg from '@yesplz/core-models/assets/svg/material-wool.svg';
import MaterialSuedeSvg from '@yesplz/core-models/assets/svg/material-suede.svg';
import MaterialLeatherSvg from '@yesplz/core-models/assets/svg/material-leather.svg';
import MaterialGlossySvg from '@yesplz/core-models/assets/svg/material-glossy.svg';
import MaterialFabricSvg from '@yesplz/core-models/assets/svg/material-fabric.svg';
import materialFleeceSvg from '@yesplz/core-models/assets/svg/material-fleece.svg';
import materialGoldSvg from '@yesplz/core-models/assets/svg/material-gold.svg';
import materialRoseGoldSvg from '@yesplz/core-models/assets/svg/material-rose-gold.svg';
import materialSilverSvg from '@yesplz/core-models/assets/svg/material-silver.svg';
import materialPearlSvg from '@yesplz/core-models/assets/svg/material-pearl.svg';

import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';

const defaultMaterialTypes = [
  { type: 'cotton', label: 'Cotton', image: MaterialCottonSvg, activeIn: [CATEGORY_WTOP] },
  { type: 'silk', label: 'Silk', image: MaterialSilkSvg, activeIn: [CATEGORY_WTOP] },
  { type: 'wrinkle-free', label: 'Wrinkle Free', image: MaterialWrinkleFreeSvg, activeIn: [CATEGORY_WTOP] },
  { type: 'denim', label: 'Denim', image: MaterialDenimSvg, activeIn: [CATEGORY_WPANTS] },
  { type: 'spandex', label: 'Spandex', image: MaterialSpandexSvg, activeIn: [CATEGORY_WPANTS] },
  { type: 'wool', label: 'Wool', image: MaterialWoolSvg, activeIn: [CATEGORY_WPANTS] },
  { type: 'suede', label: 'Suede', image: MaterialSuedeSvg, activeIn: [CATEGORY_WSHOES] },
  { type: 'leather', label: 'Leather', image: MaterialLeatherSvg, activeIn: [CATEGORY_WSHOES, CATEGORY_WBAG, CATEGORY_MBAG, CATEGORY_WWALLET, CATEGORY_MWALLET] },
  { type: 'glossy', label: 'Glossy', image: MaterialGlossySvg, activeIn: [CATEGORY_WSHOES] },
  { type: 'fabric', label: 'Fabric', image: MaterialFabricSvg, activeIn: [CATEGORY_WSHOES] },
  { type: 'polyester', label: 'Polyester', image: MaterialGlossySvg, activeIn: [CATEGORY_WBAG, CATEGORY_MBAG, CATEGORY_MWALLET] }, // TODO: Use proper image
  { type: 'textile', label: 'Textile', image: MaterialFabricSvg, activeIn: [CATEGORY_WBAG, CATEGORY_MBAG] },
  { type: 'animal', label: 'Animal', image: MaterialSuedeSvg, activeIn: [CATEGORY_WWALLET, CATEGORY_MWALLET] }, // TODO: Use proper image
  { type: 'embossy', label: 'Embossy', image: MaterialWoolSvg, activeIn: [CATEGORY_WWALLET] }, // TODO: Use proper image
  {type: 'fleece', label: 'Fleece', image: materialFleeceSvg, activeIn: [CATEGORY_WOUTER, CATEGORY_MOUTER]},
  {type: 'gold', label: 'Gold', image: materialGoldSvg, activeIn: [CATEGORY_WEARRING]},
  {type: 'rosegold', label: 'Rose Gold', image: materialRoseGoldSvg, activeIn: [CATEGORY_WEARRING]},
  {type: 'silver', label: 'Silver', image: materialSilverSvg, activeIn: [CATEGORY_WEARRING]},
  {type: 'pearl', label: 'Pearl', image: materialPearlSvg, activeIn: [CATEGORY_WEARRING]},
];

class MaterialFilter extends Widget {
  defaultParams = {
    showReset : false,
    resetImage: resetIconSvg,
    resetLabel: 'Reset',
    smallIcons: false,
  };

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.element.classList.add('ThumbnailPicker');
    if (this.params.smallIcons) {
      this.element.classList.add('ThumbnailPicker--small-icons');
    }
    this.materialElements = {};

    if (!this.params.materialTypes) {
      this.params.materialTypes = defaultMaterialTypes;
    }
  }

  handleLabelClick = (materialKey) => {
    const { categoryId, params } = this.state.filter;

    EventEmitter.emit('materialThumbnailClick', {
      categoryId,
      material: materialKey,
    });

    let material = params.material || [];
    if (materialKey === 'reset') {
      material = [];
    }
    else if (material.includes(materialKey)) {
      material = material.filter((m) => m !== materialKey);
    }
    else {
      material = [
        ...material,
        materialKey,
      ];
    }

    this.setFilter({
      presetIndex: null,
      params: {
        ...params,
        material,
      },
    });
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.renderMaterials();
    }
    else if (
      !isEqual(prevState.filter.params.material, this.state.filter.params.material)
    ) {
      const prevMaterial = prevState.filter.params.material;
      const material = this.state.filter.params.material;
      const addedItems = difference(material, prevMaterial);
      const removedItems = difference(prevMaterial, material);
      addedItems.forEach(type => {
        if (this.materialElements[type])
          this.materialElements[type].classList.add('is-active');
      });
      removedItems.forEach(type => {
        if (this.materialElements[type])
          this.materialElements[type].classList.remove('is-active');
      });
    }
  }

  renderMaterials() {
    const { categoryId, params } = this.state.filter;
    this.element.innerHTML = '';
    this.materialElements = {};

    const filteredTypes = this.params.materialTypes.filter(type => {
      if (type.disabledIn && type.disabledIn.includes(categoryId)) return false;
      if (type.activeIn && !type.activeIn.includes(categoryId)) return false;

      return true;
    });
    let materialsCount = 0;
    [
      ...filteredTypes,
      ...(
        this.params.showReset && filteredTypes.length
          ? [{ type: 'reset', label: this.params.resetLabel, image: this.params.resetImage, className: 'reset' }]
          : []
      ),
    ].forEach((material) => {
      const labelElement = document.createElement('div');
      labelElement.innerHTML = `
        <div class="ThumbnailPickerOption-thumbnail">
          <div class="ThumbnailPickerOption-imageWrapper">
            <img src="${material.image}" alt="" />
          </div>
        </div>
        <h5>${material.label}</h5>
      `;
      labelElement.className = 'ThumbnailPickerOption' + (
        params.material && params.material.includes(material.type) ? ' is-active' : ''
      ) + (material.className ? ` ${material.className}` : '');
      this.materialElements[material.type] = labelElement;
      this.element.appendChild(labelElement);

      labelElement.addEventListener('click', () => {
        this.handleLabelClick(material.type);
      });

      materialsCount = materialsCount + 1;
    });
    if (materialsCount === 0) {
      this.container.classList.add('ThumbnailPicker-hidden');
    }
    else {
      this.container.classList.remove('ThumbnailPicker-hidden');
    }

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(filteredTypes);
    }
  }

  render() {
    const container = document.createElement('div');
    const title = document.createElement('h3');
    title.innerText = this.params.title ? this.params.title : 'Materials';
    container.appendChild(title);
    this.renderMaterials();
    container.appendChild(this.element);
    return container;
  }
}

export default (params) => {
  return new MaterialFilter(params);
};
