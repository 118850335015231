import { createReducer } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { setFilter } from '../actions/filterActions';

const initialFilter = {
  categoryId: null,
  category: null,
  bodyPart: null,
  params: {},
  sort: null,
  offset: 0,
  limit: 60,
  presetIndex: null,
  occasion: null,
};

const multiSelectParams = ['design', 'color', 'material'];
const offsetReseters = ['categoryId', 'category', 'params', 'sort', 'limit', 'presetIndex'];

const filter = createReducer(initialFilter, {
  [setFilter.type]: (filter, { payload }) => {
    if (payload.params) {
      multiSelectParams.forEach(name => {
        if (typeof payload.params[name] === 'string') {
          payload.params[name] = payload.params[name].split(',');
        }
      });
    }

    const filterAfter = {
      ...filter,
      ...payload,
    };

    // Check if offset should be reset.
    // If one of the offset reset parameters was changed.
    if (
      !isEqual(
        pick(filter, offsetReseters),
        pick(filterAfter, offsetReseters)
      )
    ) {
      filterAfter.offset = 0;
    }

    return filterAfter;
  },
});

export default filter;
