import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import lodashFilter from 'lodash/filter'
import { withStyles } from '@material-ui/core/styles'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Slider from '@material-ui/core/Slider'
import { VisualFilter } from 'modules/visual-filter'
import SidebarItem from 'modules/presets/SidebarItem'
import ColorPallete from '@yesplz/core-web/modules/filters/ColorPallete'
import { StylesSelect, DesignSelect, MaterialSelect, AdvancedFilterHandler } from '@yesplz/core-web/modules/advanced-filters'
import './SideBarOfYMAL.scss'

const styles = theme => ({
  thumbIconWrapper: {
    width: '25px',
    height: '25px',
    backgroundColor: '#582adf',
    borderRadius: '50%'
  }
})

class SideBarOfYMAL extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      config: undefined,
      categoryChanged: false
    }
    this.visualFilter = null
  }

  setVisualFilter = visualFilter => {
    this.visualFilter = visualFilter
    this.setState({
      config: get(visualFilter, 'catdata.catcfg')
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { category } = this.props
    if (!prevState.categoryChanged && category !== prevProps.category) {
      this.setState({categoryChanged: true})
      setTimeout(() => {
        this.setState({categoryChanged: false})
      }, 100)
    }
  }

  handleAdvancedFilterChange = changedFilters => {
    const { advancedFilters, onAdvancedFilterChange } = this.props
    onAdvancedFilterChange({ ...advancedFilters, ...changedFilters })
  }

  handleMainFilterChange = (type) => (event, value) => {
    const { onFilterChange } = this.props
    onFilterChange(type, value)
  }

  render () {
    const {
      category,
      filters,
      advancedFilters,
      weights,
      lastBodyPart,
      activeTab,
      onUpdateTab,
      onSaveFilter,
      onResetFilter,
      classes
    } = this.props
    const { config, categoryChanged } = this.state
    const isWeights = activeTab === 'weights'
    const handleMainFilterChange = this.handleMainFilterChange

    return (
      <PerfectScrollbar className='SidebarProductsFilter' style={{ height: 'calc(100% - 145px)' }}>
        <div className='visual-filter' style={{ padding: 0 }}>
          {!categoryChanged && <VisualFilter onInit={this.setVisualFilter} />}
          <AdvancedFilterHandler
            filters={advancedFilters}
            onChange={this.handleAdvancedFilterChange}
            render={(categorizedFilters, handleFilterChange) => (
              <React.Fragment>
                <StylesSelect
                  name='style'
                  value={categorizedFilters.style}
                  category={category}
                  lastBodyPart={lastBodyPart}
                  config={config}
                  adaptiveHeight
                  onChange={handleFilterChange}
                />
                <div className='separator' style={{ marginTop: 30 }} />
                <div className='tab-container'>
                  <div
                    className={`tab-item ${isWeights ? 'active' : ''}`}
                    onClick={onUpdateTab('weights')}
                  >weights</div>
                  <div
                    className={`tab-item ${isWeights ? '' : 'active'}`}
                    onClick={onUpdateTab('information')}
                  >information</div>
                </div>
                {isWeights && (
                  <div className='weights-filters'>
                    <div className='group-title'>silhouette</div>
                    {lodashFilter(weights, weight => weight.group === 'silhouette').map(weight => (
                      <div key={weight.property} className='pred-wrapper'>
                        <div className='pred-title'>{weight.property.split('_')[0]}</div>
                        <div className='pred-slider'>
                          <Slider
                            value={filters[weight.property] || 0}
                            min={weight.min}
                            max={weight.max}
                            onChange={handleMainFilterChange(weight.property)}
                            classes={{
                              thumbIconWrapper: classes.thumbIconWrapper
                            }}
                          />
                        </div>
                        <div className='pred-value'>{filters[weight.property]}</div>
                      </div>
                    ))}
                    <div className='group-title'>Design</div>
                    {lodashFilter(weights, weight => weight.group === 'design').map(weight => (
                      <div key={weight.property} className='pred-wrapper'>
                        <div className='pred-title'>{weight.property}</div>
                        <div className='pred-slider'>
                          <Slider
                            value={filters[weight.property] || 0}
                            min={weight.min}
                            max={weight.max}
                            onChange={handleMainFilterChange(weight.property)}
                            classes={{
                              thumbIconWrapper: classes.thumbIconWrapper
                            }}
                          />
                        </div>
                        <div className='pred-value'>{filters[weight.property]}</div>
                      </div>
                    ))}
                    <div className='group-title'>Others</div>
                    {lodashFilter(weights, weight => weight.group === 'others').map(weight => (
                      <div key={weight.property} className='pred-wrapper'>
                        <div className='pred-title'>{weight.property}</div>
                        <div className='pred-slider'>
                          <Slider
                            value={filters[weight.property] || 0}
                            min={weight.min}
                            max={weight.max}
                            onChange={handleMainFilterChange(weight.property)}
                            classes={{
                              thumbIconWrapper: classes.thumbIconWrapper
                            }}
                          />
                        </div>
                        <div className='pred-value'>{filters[weight.property]}</div>
                      </div>
                    ))}
                    <div className='group-action'>
                      <button onClick={onSaveFilter}>SAVE</button>
                      <button onClick={onResetFilter}>RESET</button>
                    </div>
                  </div>
                )}
                {!isWeights && (
                  <React.Fragment>
                    <SidebarItem title='Design'>
                      <DesignSelect name='design' category={category} value={categorizedFilters.design} onChange={handleFilterChange} />
                    </SidebarItem>
                    <SidebarItem title='Colors'>
                      <ColorPallete values={categorizedFilters.color} onColorClick={(values) => handleFilterChange('color', values)} />
                    </SidebarItem>
                    <SidebarItem title='Materials'>
                      <MaterialSelect name='material' category={category} values={categorizedFilters.material} onChange={handleFilterChange} />
                    </SidebarItem>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          />
        </div>
      </PerfectScrollbar>
    )
  }
}

SideBarOfYMAL.propTypes = {
  category: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  lastBodyPart: PropTypes.string,
  filters: PropTypes.object,
  advancedFilters: PropTypes.object,
  weights: PropTypes.array,
  onFilterChange: PropTypes.func,
  onSaveFilter: PropTypes.func,
  onUpdateTab: PropTypes.func,
  onResetFilter: PropTypes.func,
  onAdvancedFilterChange: PropTypes.func,
  classes: PropTypes.object
}

export default withStyles(styles)(SideBarOfYMAL)
