
export const ANALYTICS_EVENTS_TO_SUBSCRIBE = [
  'categoryChanged',
  { event: 'bodyPartClick', labelProperty: (res) => `${res.categoryId}: ${res.bodyPart}` },
  { event: 'styleThumbnailClick', labelProperty: (res) => `${res.categoryId}: ${res.bodyPart}: ${res.style}` },
  { event: 'paternThumbnailClick', labelProperty: (res) => `${res.categoryId}: ${res.design}` },
  { event: 'colorThumbnailClick', labelProperty: (res) => `${res.categoryId}: ${res.color}` },
  { event: 'presetClick', labelProperty: (res) => `${res.categoryId}: ${res.preset}` },
  'presetNextClick',
  'presetPrevClick',
  { event: 'materialThumbnailClick', labelProperty: (res) => `${res.categoryId}: ${res.material}` },
  { event: 'sfListProductClick', labelProperty: 'productId' },
  { event: 'paginationClick', labelProperty: 'page' },
  { event: 'ymalPageView', labelProperty: (res) => res.productId },
  { event: 'ymalProdcutsFetched', labelProperty: (res) => res.productId },
  { event: 'ymalProdcutsFetchError', labelProperty: (res) => res.productId },
  { event: 'ymalListProductClick', labelProperty: (res) => res.productId },
  { event: 'sfProductAddToBasketClick', labelProperty: (res) => res.productId },
  { event: 'sfProductBuyClick', labelProperty: (res) => res.productId },
  { event: 'ymalProductAddToBasketClick', labelProperty: (res) => res.productId },
  { event: 'ymalProductBuyClick', labelProperty: (res) => res.productId },
];
