import { createBrowserHistory } from 'history';
import qs from 'qs';
import { store } from '../store';
import { hydrateFromUrl } from '../store/actions/filterActions';

const history = createBrowserHistory();

function handleHistoryChanges() {
  store.dispatch(hydrateFromUrl());
}

export function startHistoryListener() {
  history.listen(handleHistoryChanges);
}

export function pushFilterToHistory(filter) {
  const queryString = qs.stringify(filter, {
    encodeValuesOnly: true,
    arrayFormat: 'comma',
    allowDots: true,
    skipNulls: true,
  });
  history.push(`?${queryString}`);
}

export function parseUrlState() {
  return qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    comma: true,
    interpretNumericEntities: true,
  });
}
