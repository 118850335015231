import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import RandomIcon from '@material-ui/icons/ThreeSixty'

// libs
import PropTypes from 'prop-types'

import history from '@yesplz/core-web/config/history'

// core redux
import {
  fetchProductById,
  fetchRandomProduct,
  fetchUpdateWeights,
  fetchCurrentWeights,
  fetchAdminRecommendedProducts,
  setCurrentProductInfo
} from '@yesplz/core-redux/ducks/admin/products'
import { setFilter, syncFilter } from '@yesplz/core-redux/ducks/filters'

// HOC
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider'

// constants
import {
  CATEGORY_WTOP,
  CATEGORY_WPANTS,
  CATEGORY_WSHOES
} from '@yesplz/core-web/config/constants'
import WTop from '@yesplz/core-web/assets/svg/vf-button-top-black.svg'
import WPants from '@yesplz/core-web/assets/svg/vf-button-pants-black.svg'
import WShoes from '@yesplz/core-web/assets/svg/vf-button-shoes-black.svg'
import SideBarOfYMAL from './SideBarOfYMAL'
import ProductDetail from 'modules/products/ProductDetail'
import ProductList from 'modules/products/ProductList'

import './ymalSettings.scss'

const categoryIcons = {
  [CATEGORY_WTOP]: WTop,
  [CATEGORY_WPANTS]: WPants,
  [CATEGORY_WSHOES]: WShoes
}

const styles = theme => ({
  formControl: {
    padding: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  categoryFormControl: {
    padding: theme.spacing(1),
    minWidth: 120
  },
  searchRoot: {
    padding: '2px 4px',
    marginLeft: '39px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 39px)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  preText: {}
})

class YMALSettings extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuItem: 'ymal',
      activeTab: 'weights',
      searchValue: '',
      openCategory: false,
      defaultFilters: null,
      customFilters: {}
    }
  }

  componentDidMount () {
    const { product, weights, activeCategory, fetchRandomProduct, syncFilter, fetchCurrentWeights } = this.props
    if (!product) {
      fetchRandomProduct(activeCategory)
    }
    if (!weights) {
      fetchCurrentWeights(activeCategory)
    }
    setFilter(activeCategory, {})
    syncFilter()
  }

  componentDidUpdate (prevProps) {
    const {
      activeCategory,
      fetchRandomProduct,
      fetchCurrentWeights,
      fetchAdminRecommendedProducts,
      setCurrentProductInfo,
      productDetail,
      product,
      weights
    } = this.props
    if (prevProps.activeCategory !== activeCategory && !product) {
      fetchRandomProduct(activeCategory)
    }
    if (prevProps.activeCategory !== activeCategory && !weights) {
      fetchCurrentWeights(activeCategory)
    }
    if (prevProps.activeCategory !== activeCategory && product) {
      setCurrentProductInfo(productDetail)
      fetchAdminRecommendedProducts(product.productId, this.getFiltersByWeights())
    }
    if ((prevProps.weights && !this.state.defaultFilters) ||
      (!prevProps.weights && weights) ||
      (prevProps.activeCategory !== activeCategory && weights)) {
      this.setState({
        defaultFilters: this.getFiltersByWeights(),
        customFilters: this.getFiltersByWeights()
      })
    }
  }

  getFiltersByWeights = () => {
    const { weights } = this.props
    const filter = {}
    if (weights) {
      weights.forEach(weight => {
        filter[weight.property] = weight.current
      })
    }

    return filter
  }

  handleSearchById = () => {
    const { fetchProductById, activeCategory } = this.props
    const { searchValue } = this.state
    fetchProductById(activeCategory, searchValue)
  }

  handleRandomProduct = () => {
    const { activeCategory, fetchRandomProduct } = this.props
    fetchRandomProduct(activeCategory)
  }

  handleSaveWeights = () => {
    const { activeCategory, fetchUpdateWeights } = this.props
    const { customFilters } = this.state
    fetchUpdateWeights(activeCategory, customFilters)
    this.setState({ defaultFilters: { ...customFilters } })
  }

  handleResetWeights = () => {
    this.setState({ customFilters: { ...this.state.defaultFilters } })
  }

  handleChangeFilters = (type, value) => {
    const { product, fetchAdminRecommendedProducts } = this.props
    const { customFilters } = this.state
    fetchAdminRecommendedProducts(product.product_id, { ...customFilters, [type]: value })
    this.setState({
      customFilters: {
        ...customFilters,
        [type]: value
      }
    })
  }

  handleAdvancedFilterChange = filters => {
    const { activeCategory, setFilter } = this.props
    setFilter(activeCategory, filters)
    window.scrollTo(0, 0)
  }

  handleActiveTab = tab => () => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  handleSearchValue = event => {
    this.setState({ searchValue: event.target.value })
  }

  handleCloseCategory = () => {
    this.setState({ openCategory: false })
  }

  handleOpenCategory = () => {
    this.setState({ openCategory: true })
  }

  handleMenuChange = event => {
    const { activeCategory } = this.props
    this.setState({ [event.target.name]: event.target.value })
    if (event.target.value === 'presets') {
      history.push(`/presets/settings/${activeCategory}`)
    }
  }

  handleCategoryChange = event => {
    const category = event.target.value
    history.push(`/ymal/settings/${category}`)
  }

  openProductDetail = productId => () => {
    history.push(`/product/${productId}`)
  }

  goToProductDetail = productId => {
    const { activeCategory, fetchProductById } = this.props
    fetchProductById(activeCategory, productId)
    history.push(`/product/${productId}`)
  }

  render () {
    const {
      recommends,
      activeCategory,
      productDetail,
      classes,
      weights,
      filters,
      lastBodyPart,
      categories
    } = this.props
    const {
      menuItem,
      activeTab,
      searchValue,
      customFilters,
      openCategory
    } = this.state
    return (
      <div id='MainScroll' className='ProductsPage'>
        <div className='ProductFilters'>
          <div>
            <FormControl className={`${classes.formControl} menuForm`}>
              <Select
                value={menuItem}
                onChange={this.handleMenuChange}
                inputProps={{
                  name: 'menuItem',
                  id: 'main-menu-item'
                }}
              >
                <MenuItem value='presets'>Presets</MenuItem>
                <MenuItem value='ymal'>You Might Also Like</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl className={`${classes.categoryFormControl} menuForm`}>
              <Select
                value={activeCategory}
                onChange={this.handleCategoryChange}
                open={openCategory}
                onClose={this.handleCloseCategory}
                onOpen={this.handleOpenCategory}
                inputProps={{
                  name: 'category',
                  id: 'main-menu-item'
                }}
              >
                {categories.map(item => (
                  <MenuItem
                    key={`category-${item.category}`}
                    value={item.category}
                    className='category-menu-item'
                  >
                    {openCategory && categoryIcons[item.category] && (
                      <div className='category-img-container'>
                        <img src={categoryIcons[item.category]} className='category-img' alt='' />
                      </div>
                    )}
                    {item.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.searchRoot}>
            <div>ID:</div>
            <InputBase
              className={classes.searchInput}
              placeholder='Search ID'
              value={searchValue}
              onInput={this.handleSearchValue}
            />
            <IconButton
              className={classes.iconButton}
              aria-label='Search'
              onClick={this.handleSearchById}
            >
              <SearchIcon />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              aria-label='Random'
              onClick={this.handleRandomProduct}
            >
              <RandomIcon />
            </IconButton>
          </div>
          <SideBarOfYMAL
            category={activeCategory}
            filters={customFilters}
            advancedFilters={filters}
            weights={weights}
            activeTab={activeTab}
            lastBodyPart={lastBodyPart}
            onAdvancedFilterChange={this.handleAdvancedFilterChange}
            onFilterChange={this.handleChangeFilters}
            onUpdateTab={this.handleActiveTab}
            onSaveFilter={this.handleSaveWeights}
            onResetFilter={this.handleResetWeights}
          />
        </div>
        <div className='Desktop-section'>
          {productDetail && <ProductDetail handleClickProduct={this.openProductDetail(productDetail.productId)} />}
          <div className='product-list-container'>
            <ProductList
              key='recommend-product-list'
              products={recommends}
              handleClickProduct={this.goToProductDetail}
            />
          </div>
        </div>
      </div>
    )
  }
}

YMALSettings.propTypes = {
  match: PropTypes.object,
  fetchProductById: PropTypes.func,
  fetchRandomProduct: PropTypes.func,
  fetchUpdateWeights: PropTypes.func,
  fetchCurrentWeights: PropTypes.func,
  fetchAdminRecommendedProducts: PropTypes.func,
  setCurrentProductInfo: PropTypes.func,
  syncFilter: PropTypes.func,
  setFilter: PropTypes.func,
  weights: PropTypes.array,
  product: PropTypes.object,
  classes: PropTypes.object,
  categories: PropTypes.array,
  activeCategory: PropTypes.string,
  productDetail: PropTypes.object,
  recommends: PropTypes.array,
  filters: PropTypes.object,
  lastBodyPart: PropTypes.string
}

const mapStateToProps = (state, props) => {
  const activeCategory = props.match.params.category
  return {
    activeCategory,
    filters: state.filters[activeCategory].data,
    lastBodyPart: state.filters.lastBodyPart || '',
    categories: state.AdminAuth.categories || [],
    weights: state.AdminProducts.weights[activeCategory],
    product: state.AdminProducts.activeProduct[activeCategory],
    productDetail: state.AdminProducts.activeProductInfo[activeCategory],
    recommends: state.AdminProducts.recommendedProducts
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchProductById,
      fetchRandomProduct,
      fetchUpdateWeights,
      fetchCurrentWeights,
      fetchAdminRecommendedProducts,
      setCurrentProductInfo,
      syncFilter,
      setFilter
    }
  ),
  withTrackingProvider('YMALSettings'),
  withStyles(styles)
)(YMALSettings)
