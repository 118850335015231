import get from 'lodash/get';
import EventEmitter from './EventEmitter';
import { ANALYTICS_GA_KEY, RETAILER } from '../consts';
import { ANALYTICS_EVENTS_TO_SUBSCRIBE } from '../configs/analytics';

if (!window.gtag) {
  const sc = window.document.createElement('script');
  sc.src = `https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_GA_KEY}`;
  window.document.head.prepend(sc);
  window.dataLayer = window.dataLayer || [];
  window.gtag = function (){dataLayer.push(arguments);}
  window.gtag('js', new Date());
}

window.gtag('config', ANALYTICS_GA_KEY);

const { gtag } = window;

const handleEvent = (action, label) => {
  const params = {
    send_to: ANALYTICS_GA_KEY,
  };

  if (RETAILER) {
    params.event_category = RETAILER;
  }
  if (typeof label === 'string') {
    params.event_label = label;
  }
  else if (label && action.labelProperty && typeof label === 'object') {
    if (typeof action.labelProperty === 'function') {
      params.event_label = action.labelProperty(label);
    }
    else {
      params.event_label = get(label, action.labelProperty);
    }
  }

  gtag(
    'event',
    (
      typeof action === 'string'
        ? action
        : action.event
    ),
    params
  );
};

/**
 * Array of strings with event names, same names would be sent
 * to GA as events. If Subscriber receives string it will be sent
 * as a label for GA event.
 *
 * Also, if subscriber recieves object, we can sst event settings object as:
 * { event: 'sfListProductClick', labelProperty: 'productId' }
 *
 * `event` param telling where to subscribe and which name to send.
 * `labelProperty` param telling which property to take from recieving object.
 * it can be equal to object path string `object.param.param`
 */
ANALYTICS_EVENTS_TO_SUBSCRIBE.map(action => {
  EventEmitter.on(
    (
      typeof action === 'string'
        ? action
        : action.event
    ),
    (label) => handleEvent(action, label)
  );
});
