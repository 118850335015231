import { createReducer } from '@reduxjs/toolkit';
import {
  searchResultsPending,
  searchResultsSuccess,
  searchResultsError,
} from '../actions/searchActions';

const initialFilter = {
  isLoading: false,
  error: null,
};

const search = createReducer(initialFilter, {
  [searchResultsPending.type]: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  [searchResultsSuccess.type]: (state, action) => {
    state.results = action.payload.results;
    state.count = action.payload.count;
  },
  [searchResultsError.type]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload.error;
  },
});

export default search;
