import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import queryString from 'query-string'
import Transition from '@yesplz/core-web/ui-kits/transitions/Transition'
import { ScrollFetcher } from '@yesplz/core-web/ui-kits/fetchers'
import { DotLoader } from '@yesplz/core-web/ui-kits/loaders'
import withProductsFetcher from '@yesplz/core-web/hoc/withProductsFetcher'
import { PRODUCT_COUNT_PER_PAGE, CATEGORY_WTOP } from '@yesplz/core-web/config/constants'
import ProductGrid from './PresetProductGrid'
import ProductsNotFound from '@yesplz/core-web/modules/products/ProductsNotFound'
import { matchingProductsSelector, closeMatchingProductsSelector } from '@yesplz/core-web/modules/products/selectors'
import '@yesplz/core-web/modules/products/product-list.css'

const childRenderer = (props) => {
  return (
    <ProductGrid
      product={{...props}}
      category={props.category}
    />
  )
}

childRenderer.propTypes = {
  category: PropTypes.any
}

class PresetProductList extends Component {
  static propTypes = {
    id: PropTypes.string,
    category: PropTypes.string,
    customFilters: PropTypes.object,
    show: PropTypes.bool,
    favorite: PropTypes.bool,
    related: PropTypes.bool,
    recommended: PropTypes.bool,
    limitPerPage: PropTypes.number,
    extraItem: PropTypes.element,
    showOriginalPrice: PropTypes.bool,
    showHighResImage: PropTypes.bool,
    combined: PropTypes.bool,
    useButton: PropTypes.bool,
    productBasePath: PropTypes.string,
    closeMatchingMessage: PropTypes.string,
    style: PropTypes.object,
    loaderStyle: PropTypes.object,
    children: PropTypes.func,
    className: PropTypes.string,
    favorites: PropTypes.array,
    relatedProducts: PropTypes.array,
    recommendedProducts: PropTypes.array,

    // from store
    products: PropTypes.array,
    filters: PropTypes.object,
    nextOffset: PropTypes.number,
    location: PropTypes.object,
    willBeEmptyList: PropTypes.bool,
    maxCount: PropTypes.number,

    onInit: PropTypes.func.isRequired,
    onFetchNext: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    onToggleLike: PropTypes.func.isRequired,
    onScrollBellowTheFold: PropTypes.func.isRequired,
    onScrollChange: PropTypes.func.isRequired,
    onTouchMove: PropTypes.func
  }

  static defaultProps = {
    category: CATEGORY_WTOP,
    products: [],
    favorites: [],
    relatedProducts: [],
    recommendedProducts: [],
    limitPerPage: 99,
    maxCount: 999,
    nextOffset: 0,
    willBeEmptyList: false,
    show: false,
    favorite: false,
    related: false,
    recommended: false,
    combined: false, // when activated, it won't separate matching and close matching.
    useButton: false,
    children: childRenderer,
    extraItem: undefined,
    showOriginalPrice: false,
    showHighResImage: false,
    closeMatchingMessage: 'The next best matching',
    style: {},
    onInit: () => {},
    onFilter: () => {},
    onFetchNext: () => {},
    onScrollBellowTheFold: (scrollState) => {},
    onScrollChange: (scrollTop) => {}
  }

  constructor (props) {
    super(props)
    this.state = {
      useMinimumAnimation: false,
      matchingProducts: [],
      closeMatchingProducts: []
    }
    this.handleFetch = this.handleFetch.bind(this)
  }

  componentDidMount () {
    const { location, category, limitPerPage, customFilters, onInit } = this.props
    if (!(/^\/products\/(wtop|wpants|wshoes)\/list$/.test(location.pathname))) {
      onInit(category, limitPerPage, customFilters)
    }
  }

  componentDidUpdate (prevProps) {
    const { category, limitPerPage, filters, onFilter, customFilters, location, show } = this.props
    const { useMinimumAnimation } = this.state

    const qsValues = queryString.parse(location.search)
    if (!qsValues.preset) {
      if (!isEqual(prevProps.filters, filters) || !isEqual(prevProps.customFilters, customFilters)) {
        onFilter(category, { ...filters, ...customFilters }, limitPerPage)
      }
    }

    if (!useMinimumAnimation && show) {
      this.setState({
        useMinimumAnimation: true
      })
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    // if result is not combined, then separate the matching and close matching products
    if (nextProps.show && !nextProps.combined) {
      return {
        matchingProducts: matchingProductsSelector(nextProps.products),
        closeMatchingProducts: closeMatchingProductsSelector(nextProps.products)
      }
    }
    return null
  }

  shouldComponentUpdate (nextProps, nextState) {
    // don't rerender on `useMinimumAnimation` update
    return !isEqual(this.props, nextProps) || !isEqual(this.state.matchingProducts, nextState.matchingProducts)
  }

  handleFetch () {
    const { products, customFilters, maxCount, category, limitPerPage, onFetchNext } = this.props

    if (products.length < maxCount) {
      return onFetchNext(category, limitPerPage, customFilters)
    }

    return Promise.resolve()
  }

  get handleScroll () {
    const { onScrollBellowTheFold, onScrollChange } = this.props
    return (top) => {
      onScrollChange(top)
      // check whether scroll position is going under the fold
      if (top > window.innerHeight) {
        onScrollBellowTheFold(true)
      } else {
        onScrollBellowTheFold(false)
      }
    }
  }

  get productListOptions () {
    const {
      nextOffset,
      showOriginalPrice,
      showHighResImage,
      onToggleLike,
      productBasePath
    } = this.props
    const { useMinimumAnimation } = this.state

    // get loaded products count
    const prevOffset = (nextOffset - PRODUCT_COUNT_PER_PAGE)
    const loadedProductsCount = PRODUCT_COUNT_PER_PAGE * (prevOffset < 0 ? 0 : prevOffset)

    return {
      showOriginalPrice,
      showHighResImage,
      onToggleLike,
      useMinimumAnimation,
      loadedProductsCount,
      productBasePath
    }
  }

  render () {
    const {
      id,
      products,
      show,
      children,
      className,
      extraItem,
      onTouchMove,
      willBeEmptyList,
      style,
      loaderStyle,
      combined,
      useButton,
      favorite,
      favorites,
      related,
      recommended,
      relatedProducts,
      recommendedProducts,
      closeMatchingMessage
    } = this.props
    const { useMinimumAnimation, matchingProducts, closeMatchingProducts } = this.state
    // get products
    // when `combined` is `true`, product list should render all products without separating the score
    let productList = null
    let closeProductList = null
    if (favorite) {
      productList = renderProducts(favorites, children, this.productListOptions)
    } else if (related) {
      productList = renderProducts(relatedProducts, children, this.productListOptions)
    } else if (recommended) {
      productList = renderProducts(recommendedProducts, children, this.productListOptions)
    } else if (combined) {
      productList = renderProducts(products, children, this.productListOptions)
    } else {
      productList = renderProducts(matchingProducts, children, this.productListOptions)
      closeProductList = renderProducts(closeMatchingProducts, children, this.productListOptions)
    }

    return (
      <ScrollFetcher
        id={id}
        onFetch={this.handleFetch}
        onScroll={this.handleScroll}
        onTouchMove={onTouchMove}
        className={className}
        useButton={useButton}
        style={{ ...styles.wrapper, overflowY: willBeEmptyList ? 'hidden' : 'scroll', ...style }}
        disableInitalFetch
      >
        {willBeEmptyList && <ProductsNotFound style={styles.notFound} />}
        {extraItem}
        <div className='container'>
          <div className='ProductList-wrapper'>
            {!show && <DotLoader visible style={loaderStyle || styles.loader} />}
            <Transition show={show} transition={useMinimumAnimation ? 'fadeIn' : 'fadeInUp'}>
              {productList}
            </Transition>
          </div>
        </div>
        {closeMatchingProducts.length > 0 ? <h4 className='animated fadeIn ProductList-subtitle'>{closeMatchingMessage}</h4> : <div style={{ display: 'none' }} />}
        <div className='container'>
          <div className='ProductList-wrapper'>
              {closeProductList}
          </div>
        </div>
      </ScrollFetcher>
    )
  }
}

export default compose(
  withProductsFetcher,
  connect(state => ({ location: state.router.location }))
)(PresetProductList)

const renderProducts = (
  products,
  children,
  {
    useMinimumAnimation,
    loadedProductsCount
  }
) => (
  products.map((product, index) => {
    const props = {
      ...product,
      key: product.product_id,
      category: product.category,
      style: {
        // `ProducGrid` need be showed directly in each page
        animationDelay: `${useMinimumAnimation ? 0 : 50 * (index - loadedProductsCount)}ms`
      }
    }
    return children(props)
  })
)

const styles = {
  wrapper: {
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    margin: 'auto',
    top: 10,
    right: 0,
    bottom: 0,
    left: 0,
    width: 100,
    height: 30
  },
  notFound: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3
  }
}
