import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ADMIN_FILTER_TYPES } from '@yesplz/core-web/config/constants'
import FilterGroup from '@yesplz/core-web/modules/filters/FilterGroup'
import FilterLabel from '@yesplz/core-web/modules/filters/FilterLabel'

const SideBarOfDashboard = ({ activeCategory, onSubmit, defaultFilters }) => {
  const {
    filters,
    changeFilters
  } = useProductsFilterState(activeCategory, defaultFilters)

  useEffect(() => {
    onSubmit(filters)
  }, [filters])

  const handleFilterChange = async (name, values) => {
    await changeFilters({
      ...filters,
      [name]: values
    })
  }

  return (
    <div>
      <FilterLabel label='Types'>
        <FilterGroup
          name='types'
          options={[
            {
              name: 'all',
              label: 'All Types'
            },
            ...ADMIN_FILTER_TYPES
          ]}
          values={defaultFilters['types']}
          type='radio'
          onChange={handleFilterChange}
        />
      </FilterLabel>
    </div>
  )
}

SideBarOfDashboard.propTypes = {
  activeCategory: PropTypes.string,
  onSubmit: PropTypes.func,
  defaultFilters: PropTypes.object
}

SideBarOfDashboard.defaultProps = {
  isVisible: false,
  redirectPreset: null,
  onSubmit: (productListConfig) => { console.debug('Unhandled `onSubmit` prop in `ProductsFilter`', productListConfig) },
  onClose: () => { console.debug('Unhandled `onClose` prop in `ProductsFilter`') }
}

export default SideBarOfDashboard

/**
 * state for products filter
 * @param {string} category
 * @param {string} defaultColType
 * @param {Object} defaultFilters
 */
export function useProductsFilterState (category, defaultFilters = {}) {
  const [filters, changeFilters] = useState(defaultFilters)

  const clearFilter = () => {
    changeFilters({
      types: [category]
    })
  }

  return {
    filters,
    changeFilters,
    clearFilter
  }
}
