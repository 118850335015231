import Handlebars from 'handlebars';
import Widget from '../modules/Widget';

const { document } = window;

const DEFAULT_LAYOUT = `
  {{#each items}}
    <a href="#" data-sort="{{this.value}}">{{this.label}}</a>
  {{/each}}
`;

class SortBy extends Widget {
  defaultParams = {
    containerClassName: '',
    activeClass: 'is-active',
    items: [
      { label: 'From lowest price', value: 'price:asc' },
      { label: 'Name', value: 'name' },
      { label: 'Newest', value: 'newest' },
    ],
    templates: {
      layout: DEFAULT_LAYOUT,
    },
  };

  constructor(params) {
    super(params);

    this.layoutTemplate = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement('span');
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  didMount() {
    const activeElement = this.mainElement.querySelector(
      `a[data-sort="${this.state.filter.sort}"]`
    );
    if (activeElement) {
      activeElement.classList.add(this.params.activeClass);
    }

    this.mainElement.querySelectorAll('a[data-sort]')
      .forEach(element => {
        element.addEventListener('click', this.handleChange);
      });
  }

  handleChange = (e) => {
    e.preventDefault();

    const element = e.target;
    if (!element.classList.contains(this.params.activeClass)) {
      const activeElement = this.mainElement.querySelector(
        `a.${this.params.activeClass}`
      );
      if (activeElement) {
        activeElement.classList.remove(this.params.activeClass);
      }

      element.classList.add(this.params.activeClass);
      const value = element.getAttribute('data-sort');
      this.setFilter({
        sort: value,
      });
    }
    else {
      element.classList.remove(this.params.activeClass);
      this.setFilter({
        sort: null,
      });
    }
  }

  update() {
    const { limit } = this.state.filter;
    const optionToSelect = this.mainElement.querySelector(`option[value="${limit}"`);
    if (optionToSelect) {
      optionToSelect.selected = true;
    }
  }

  render() {
    this.mainElement.innerHTML = this.layoutTemplate({
      items: this.params.items,
    });
    return this.mainElement;
  }
}

export default (params) => {
  return new SortBy(params);
};
