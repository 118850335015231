import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Slider from '@material-ui/core/Slider'

import './ProductPrediction.scss'

class ProductPrediction extends React.Component {

  getMathValue = value => {
    return Math.round(value * 1000) / 1000
  }
  render () {
    const { product } = this.props

    return (
      <div className='prediction-container'>
        <div className='prediction-header'>
          <div>Silhouette</div>
          <div>Prediction</div>
        </div>
        <div className='prediction-content'>
          {product && product.preds && product.preds.silhouette && Object.keys(product.preds.silhouette).map(attr => (
            <div key={attr} className='pred-wrapper'>
              <div className='pred-title'>{attr}</div>
              <div className='pred-slider'>
                <Slider
                  value={product.preds.silhouette[attr]}
                  min={0}
                  max={10}
                  classes={{
                    thumbIconWrapper: {
                      width: '25px',
                      height: '25px',
                      backgroundColor: '#582adf',
                      borderRadius: '50%'
                    }
                  }}
                />
              </div>
              <div className='pred-value'>{this.getMathValue(product.preds.silhouette[attr])}</div>
            </div>
          ))}
          <div className='sub-header'>Design</div>
          {product && product.preds && product.preds.design && Object.keys(product.preds.design).map(attr => (
            <div key={attr} className='pred-wrapper'>
              <div className='pred-title'>{attr}</div>
              <div className='pred-value'>{product.preds.design[attr]}</div>
            </div>
          ))}
          <div className='sub-header'>Others</div>
          {product && product.preds && product.preds.others && Object.keys(product.preds.others).map(attr => (
            <div key={attr} className='pred-wrapper'>
              <div className='pred-title'>{attr}</div>
              <div className='pred-value'>{product.preds.others[attr]}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

ProductPrediction.propTypes = {
  product: PropTypes.object
}

const mapStateToProps = state => ({
  product: state.AdminProducts.currentProductInfo
})

export default connect(mapStateToProps)(ProductPrediction)
