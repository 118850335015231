import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import { CATEGORY_WSHOES, CATEGORY_WPANTS, CATEGORY_MPANTS } from '../consts';

import designSolidSvg from '@yesplz/core-models/assets/svg/design-solid.svg';
import designPatternSvg from '@yesplz/core-models/assets/svg/design-pattern.svg';
import designDetailSvg from '@yesplz/core-models/assets/svg/design-detail.svg';
import designRippedOffSvg from '@yesplz/core-models/assets/svg/design-ripped-off.svg';
import designLaceUpSvg from '@yesplz/core-models/assets/svg/design-lace-up.svg';
import designLightSvg from '@yesplz/core-models/assets/svg/design-light.svg';
import designLeatherSvg from '@yesplz/core-models/assets/svg/design-leather.svg';
import designDenimSvg from '@yesplz/core-models/assets/svg/design-denim.svg';
import designSlacksSvg from '@yesplz/core-models/assets/svg/design-slacks.svg';
import designWorkoutSvg from '@yesplz/core-models/assets/svg/design-workout.svg';
import designLeggingsSvg from '@yesplz/core-models/assets/svg/design-leggings.svg';
import designSuedeSvg from '@yesplz/core-models/assets/svg/design-suede.svg';
import designWoolSvg from '@yesplz/core-models/assets/svg/design-wool.svg';
import designDownSvg from '@yesplz/core-models/assets/svg/design-down.svg';
import designAnimalSvg from '@yesplz/core-models/assets/svg/design-animal.png';
import designCottonSvg from '@yesplz/core-models/assets/svg/design-cotton.svg';

import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';

const defaultDesignTypes = [
  {type: 'solid', label: 'Solid', image: designSolidSvg, remove: 'pattern'},
  {type: 'pattern', label: 'Pattern', image: designPatternSvg, remove: 'solid'},
  {type: 'detail', label: 'Detail', image: designDetailSvg, disabledIn: [CATEGORY_MPANTS]},
  {type: 'ripped-off', label: 'Ripped Off', image: designRippedOffSvg, activeIn: [CATEGORY_WPANTS, CATEGORY_MPANTS]},
  {type: 'lace-up', label: 'Lace Up', image: designLaceUpSvg, activeIn: [CATEGORY_WSHOES]},
  {type: 'light', label: 'Light', image: designLightSvg, activeIn: ['wouter', 'mouter']},
  {type: 'leather', label: 'Leather', image: designLeatherSvg, activeIn: ['wouter', 'wshoes', 'mouter', 'mshoes', 'wwallet', 'mwallet']},
  {type: 'denim', label: 'Denim', image: designDenimSvg, activeIn: ['wpants', 'mpants']},
  {type: 'cotton', label: 'Cotton', image: designCottonSvg, activeIn: ['wpants']},
  {type: 'wool', label: 'Wool', image: designWoolSvg, activeIn: ['wpants', 'mpants']},
  {type: 'down', label: 'Down', image: designDownSvg, activeIn: ['wouter', 'mouter']},
  {type: 'workout', label: 'Workout', image: designWorkoutSvg, activeIn: ['wpants', 'mpants']},
  {type: 'leggings', label: 'Leggings', image: designLeggingsSvg, activeIn: ['wpants']},
  {type: 'suede', label: 'Suede', image: designSuedeSvg, activeIn: ['wshoes', 'mshoes']},
  {type: 'animal', label: 'Animal Print', image: designAnimalSvg, activeIn: ['wwallet', 'mwallet']},
  {type: 'fabric', label: 'Fabric', image: designSlacksSvg, activeIn: ['wwallet', 'mwallet']},
];

class DesignFilter extends Widget {
  defaultParams = {
    showReset : false,
    resetImage: resetIconSvg,
    resetLabel: 'Reset',
  };

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.element.className = 'ThumbnailPicker';
    this.designElements = {};

    if (!this.params.designTypes) {
      this.params.designTypes = defaultDesignTypes;
    }
  }

  handleLabelClick = (type, remove) => {
    const { categoryId, params } = this.state.filter;

    EventEmitter.emit('paternThumbnailClick', {
      categoryId,
      design: type,
    });

    let design = params.design ? [...params.design] : [];
    if (type === 'reset') {
      design = [];
    }
    else if (design.includes(type)) {
      design.splice(design.indexOf(type), 1);
    }
    else {
      design.push(type);
    }

    if (remove && design.includes(remove)) {
      design.splice(design.indexOf(remove), 1);
    }
    this.setFilter({
      presetIndex: null,
      params: {
        ...params,
        design,
      },
    });
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.renderDesigns();
    }
    else if (
      !isEqual(prevState.filter.params.design, this.state.filter.params.design)
    ) {
      const prevDesign = prevState.filter.params.design;
      const design = this.state.filter.params.design;
      const addedItems = difference(design, prevDesign);
      const removedItems = difference(prevDesign, design);
      addedItems.forEach(type => this.designElements[type].classList.add('is-active'));
      removedItems.forEach(type => this.designElements[type].classList.remove('is-active'));
    }
  }

  renderDesigns() {
    const { categoryId, params } = this.state.filter;
    this.element.innerHTML = '';
    this.designElements = {};

    const filteredTypes = this.params.designTypes.filter(type => {
      if (type.disabledIn && type.disabledIn.includes(categoryId)) return false;
      if (type.activeIn && !type.activeIn.includes(categoryId)) return false;

      return true;
    });
    [
      ...filteredTypes,
      ...(
        this.params.showReset && filteredTypes.length
          ? [{ type: 'reset', label: this.params.resetLabel, image: this.params.resetImage, className: 'reset' }]
          : []
      ),
    ].forEach((design) => {
      if (design.disabledIn && design.disabledIn.includes(categoryId)) return;
      if (design.activeIn && !design.activeIn.includes(categoryId)) return;

      const labelElement = document.createElement('div');
      labelElement.innerHTML = `
        <div class="ThumbnailPickerOption-thumbnail">
          <div class="ThumbnailPickerOption-imageWrapper">
            <img src="${design.image}" alt="" />
          </div>
        </div>
        <h5>${design.label}</h5>
      `;
      labelElement.className = 'ThumbnailPickerOption' + (
        params.design && params.design.includes(design.type) ? ' is-active' : ''
      ) + (design.className ? ` ${design.className}` : '');
      this.designElements[design.type] = labelElement;
      this.element.appendChild(labelElement);

      labelElement.addEventListener('click', () => {
        this.handleLabelClick(design.type, design.remove);
      });
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(filteredTypes);
    }
  }

  render() {
    const container = document.createElement('div');
    const title = document.createElement('h3');
    title.innerText = this.params.title ? this.params.title : 'Design';
    container.appendChild(title);
    this.renderDesigns();
    container.appendChild(this.element);
    return container;
  }
}

export default (params) => {
  return new DesignFilter(params);
};
