class VfCatCfg {
  constructor(categoryCfg) {
    this.category = categoryCfg.category
    this.svgSource = categoryCfg.vfSvgSource
    // Overiing functions
    this.overrideBodyPartGroupName = categoryCfg.overrideBodyPartGroupName
    this.touchpointGroupName = categoryCfg.touchpointGroupName
    this.sanitizePropChange = categoryCfg.sanitizePropChange
    this.evaluateDisabledParts = categoryCfg.evaluateDisabledParts

    this.api_extra_param = categoryCfg['api_extra_param'] || ''

    this.partList = categoryCfg['partList'] // Sillouette filter.
    this.partListLabels = categoryCfg['partListLabels']

    // TODO: Add config for other filters like design/materials, etc
    this.propDefaultVal = categoryCfg['defaultVal']
    this.tn = categoryCfg['tn']

    this.presetList = categoryCfg['presetList']
    this.extraBodyGroups = categoryCfg['extraBodyGroups']
  }
  maxVal (prop) {
    return this.propMaxVal[prop]
  }
  defaultVal (prop) {
    return this.propDefaultVal[prop]
  }
  getTnClass(bodyPart, value) {
    return (
      this.tn[bodyPart]
      &&
      this.tn[bodyPart][value]
      &&
      this.tn[bodyPart][value].tnClass
        ? this.tn[bodyPart][value].tnClass
        : ''
    );
  }
}

export function getCatCfg (categoryCfg) {
  return new VfCatCfg(categoryCfg)
}
