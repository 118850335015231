import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Help from '@yesplz/core-web/ui-kits/icons/Help'
import './HelpButton.scss'

export default function HelpButton({ onClick }) {
  return (
    <div className={classNames('HelpButton')}>
      <button onClick={onClick}>
        <Help />
      </button>
    </div>
  )
}

HelpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
