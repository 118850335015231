import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import { VisualFilter as VisualFilterSVG } from '@yesplz/core-models';
import Widget from '../modules/Widget';
import helpSvg from '@yesplz/core-models/assets/svg/help-icon.svg';
import EventEmitter from '../modules/EventEmitter';
import { SVG } from '@svgdotjs/svg.js'

const SVG_CONTAINER_ID = 'visualfilter-svg';

const { document } = window;

const FILTER_NAVIGATION_LAYOUT = `
<div class="VisualFilter-navigation">
  <div
    id="visualfilter-prev"
    class="VisualFilter-navigation-button VisualFilter-navigation-button-left"
  ></div>
  <div
    id="visualfilter-next"
    class="VisualFilter-navigation-button VisualFilter-navigation-button-right"
  ></div>
</div>
`;

const BUTTONS_CONTAINER_TEMPLATE = `<div id="style-buttons-container" class="VisualFilter-buttons"></div>`;

const TOOLTIP_TEMPLATE = `<button id="tooltips-toggle" class="VisualFilter-button VisualFilter-tooltip-button">
  <img src="${helpSvg}" alt="" />
</button>`;

const RESET_BUTTON_TEMPLATE = `<button id="style-filter-reset" class="VisualFilter-button VisualFilter-clear-button">
  Clear
</button>`;

const persistentParams = ['color','design', 'material', 'brands', 'price'];

class VisualFilter extends Widget {
  defaultParams = {
    presetNavigation: true,
    navigationTemplate: FILTER_NAVIGATION_LAYOUT,
    buttonsContainerTemplate: BUTTONS_CONTAINER_TEMPLATE,
    tooltipTemplate: TOOLTIP_TEMPLATE,
    resetButtonTemplate: RESET_BUTTON_TEMPLATE,
    onFilterChange: null,
    svgViewBox: [30, -5, 250, 170],
    svgViewBoxMobile: [-5, -10, 330, 250],
    svgContainerId: SVG_CONTAINER_ID,
    svgHideTouchPoints: false,
    showTooltipsToggler: true,
    showResetButton: false,
    rotateValueOnFirstClick: false,
    showParamTags: false,
    tagHighlightColor: "#2F30EB",
    tagDefaultColor: "#333333",
  };

  constructor(params) {
    super(params);

    this.select = null;
    this.mainElement = document.createElement('div');
    this.svgContainer = null;
    this.visualFilter = null;
  }

  didMount() {
    const { filter } = this.state;
    if (isEmpty(filter.params)) {
      const defaultCategoryFilter = this.getCategoryDefaults(filter.categoryId);
      this.setFilter(defaultCategoryFilter);
    }
    this.renderSvg(filter.categoryId, filter.bodyPart, filter.params);

    if (this.params.presetNavigation) {
      this.container.querySelector('#visualfilter-prev')
        .addEventListener('click', this.handlePresetClick.bind(this, false));
      this.container.querySelector('#visualfilter-next')
        .addEventListener('click', this.handlePresetClick.bind(this, true));
    }
    this.listenWindowResize();

    if (this.params.showTooltipsToggler) {
      const tooltipsToggleButton = this.container.querySelector('#tooltips-toggle');
      tooltipsToggleButton.addEventListener('click', this.tooltipsToggle);
    }

    if (this.params.showResetButton) {
      const resetButtonElement = this.container.querySelector('#style-filter-reset');
      resetButtonElement.addEventListener('click', this.resetParams);
    }

    setTimeout(() => {
      this.addTags();
    }, 400);

    let options = {
      root: this.container,
      rootMargin: '0px',
      threshold: 1.0
    }
    
    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRect.height > 0) {
          this.addTags();
        }
      });
    }, options);

    observer.observe(this.svgContainer);
  }

  didUpdate(prevState) {
    const { filter, config } = this.state;
    if (filter.categoryId !== prevState.filter.categoryId && prevState.filter.categoryId) {
      this.renderSvg(
        this.state.filter.categoryId,
        this.state.filter.bodyPart,
        this.state.filter.params
      );

      if (this.select) {
        this.select.value = this.state.filter.category;
      }
    }
    else {
      if (this.visualFilter && filter.bodyPart) {
        this.visualFilter.setLastBodyPart(filter.bodyPart);
        this.visualFilter.updateState(filter.params);
      }
    }

    // Check if any of editor's picks are active
    // and show colored navigations.
    const navigationContainer = document.querySelector('.VisualFilter-navigation');
    if (navigationContainer) {
      if (filter.presetIndex) {
        navigationContainer.classList.add('active');
      }
      else {
        navigationContainer.classList.remove('active');
      }
    }

    if (prevState.config.isTooltipsVisible !== config.isTooltipsVisible && this.visualFilter) {
      if (config.isTooltipsVisible)
        this.visualFilter.showTooltip();
      else
        this.visualFilter.hideTooltip();
    }

    setTimeout(() => {
      this.addTags();
    }, 100);
  }

  addTags() {
    if (!this.params.showParamTags) return;

    const { categoryId } = this.state.filter;
    const { categories } = this.main;

    if (!categories[categoryId].tn) return;

    const bodyParts = Object.keys(categories[categoryId].tn);
    bodyParts.forEach(bp => this.drawTag(bp));
  }

  drawTag(bodyPart) {
    const { categoryId, params, bodyPart: activeBodyPart } = this.state.filter;
    const { categories } = this.main;

    const rootSvg = new SVG(`#${this.params.svgContainerId}`);

    if (!rootSvg || (rootSvg && !rootSvg.findOne)) return;

    const oldTag = rootSvg.findOne(`#active-${bodyPart}-tag`);
    if (oldTag) {
      oldTag.remove();
    }

    if (params[bodyPart] === 'all' && activeBodyPart !== bodyPart) return;

    const rootTagsGroup = rootSvg.findOne('#tags');

    if (!rootTagsGroup) return;

    const draw = rootTagsGroup;

    const tag = draw.group();

    const rootTagBbox = draw.findOne(`#tag-text-${bodyPart}`).bbox();

    tag.attr({
      id: `active-${bodyPart}-tag`,
      class: 'action-tag',
    });

    const rect = tag.rect(100, 25).fill('#ffffff').stroke(this.params.tagHighlightColor).attr({
      'x': 0,
      'y': 0,
      'rx': '12.5',
    });

    const label = categories[categoryId].tn[bodyPart][params[bodyPart]].label === 'All' ? 'Any' : categories[categoryId].tn[bodyPart][params[bodyPart]].label;
    const isAbleToReset = label !== 'Any' && categories[categoryId].tn[bodyPart]['all'];
    const text = tag.text(capitalize(label)).attr({
      'fill': this.params.tagHighlightColor,
      'font-size': '12px',
      'x': isAbleToReset ? 11 : 16,
      'y': 17,
    });

    const textBbox = text.bbox();
    const boxWidth = textBbox.width + (isAbleToReset ? 38 : 32);
    rect.width(boxWidth);

    let xGroup = null;
    if (isAbleToReset) {
      xGroup = tag.group();
      xGroup.rect(23, 23)
        .attr({
          'x': 0,
          'y': 1,
          'fill': '#ffffff',
          'rx': '12.5',
        });
      const x = xGroup.path('M0 7 L7 0 M0 0 L7 7 L0 0Z').attr({
        'stroke': this.params.tagHighlightColor,
      });

      xGroup.on('mouseover mouseout', (e) => {
        if (e.type === 'mouseover') {
          x.attr('stroke-width', '2');
        }
        else {
          x.attr('stroke-width', '1');
        }
      });

      xGroup.on('click', () => this.handleResetClick(bodyPart));
      x.attr('transform', `translate(10 9)`);
      xGroup.attr('transform', `translate(${textBbox.width + 13} 0)`);
    }

    tag.translate(
      (rootTagBbox.x + (rootTagBbox.width / 2) - (boxWidth / 2)),
      rootTagBbox.y + 24
    );

    tag.on('click', (e) => {
      if (xGroup && xGroup.node.contains(e.target)) return;

      this.visualFilter.handleBodyPartClick(bodyPart);
    });

    tag.on('mouseover mouseout', (e) => {
      if (e.type === 'mouseover') {
        this.visualFilter.handleBodyPartMouseOver(bodyPart);
      }
      else {
        this.visualFilter.handleBodyPartMouseOut(bodyPart);
      }
    });
  }

  handleResetClick = (resetBodyPart) => {
    const { params } = this.state.filter;

    this.setFilter({
      presetIndex: null,
      occasion: null,
      bodyPart: resetBodyPart,
      params: {
        ...params,
        [resetBodyPart]: 'all',
      },
    });
  }

  tooltipsToggle = () => {
    this.main.toggleTooltips();
  }

  resetParams = () => {
    const filter = this.main.getFilter();
    this.setFilter({
      presetIndex: null,
      occasion: null,
      params: {
        ...Object.entries(filter.params)
          .reduce(
            (params, [key, value]) => ({
              ...params,
              [key]: typeof value === 'string' ? 'all' : value,
            }),
            {}
          ),
      }
    });
  }

  handlePresetClick = (isNext) => {
    if (isNext) {
      EventEmitter.emit('presetNextClick');
      return this.main.setNextPreset();
    }
    EventEmitter.emit('presetPrevClick');
    return this.main.setPrevPreset();
  }

  handleFilterParamsChange = ({ ...newParams }) => {
    const params = {
      ...newParams,
      ...pick(this.state.filter.params, persistentParams)
    };
    if (!isEqual(params, this.state.filter.params)) {
      this.setFilter({
        presetIndex: null,
        params,
      });
      if (typeof this.params.onFilterChange === 'function') {
        this.params.onFilterChange();
      }
    }
  }

  handleBodyPartChage = (bodyPart) => {
    EventEmitter.emit('bodyPartClick', {
      categoryId: this.state.filter.categoryId,
      bodyPart,
    });
    this.setFilter({
      presetIndex: null,
      bodyPart
    });
  }

  getCategoryDefaults(categoryId) {
    if (this.main.noFilterCategories.includes(categoryId)) return {};

    const categories = this.main.categories;
    if (!categories[categoryId]) {
      console.log("Unsupported CategoryId", categoryId, "Supported Categories are", Object.keys(categories))
    }
    const bodyPart = (
      categories[categoryId].partList && categories[categoryId].partList.length
        ? categories[categoryId].partList[0]
        : null
    );
    const params = {
      ...(
        categories[categoryId].defaultVal
          ? categories[categoryId].defaultVal
          : {}
      ),
      color: [],
    };
    return {
      categoryId,
      category: categories[categoryId].category,
      bodyPart,
      params,
      presetIndex: null,
      occasion: null,
    };
  }

  listenWindowResize() {
    let timeout = null;
    window.addEventListener('resize', (e) => {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        this.updateSvgViewBox(
          this.getResponsiveViewBox()
        );

        this.updateSvgScale(
          this.getResponsiveSvgScale()
        );
      }, 100);
    });
  }

  getResponsiveSvgScale() {
    if (!this.params.svgScaleMobile || window.innerWidth > 768) {
      return this.params.svgScale;
    }
    else {
      return this.params.svgScaleMobile;
    }
  }

  getResponsiveViewBox() {
    if (window.innerWidth > 768) {
      return this.params.svgViewBox;
    }
    else {
      return this.params.svgViewBoxMobile;
    }
  }

  updateSvgScale(scale) {
    if (!this.visualFilter) return;

    this.visualFilter.setScale(scale);
  }

  updateSvgViewBox(viewBox) {
    if (!this.visualFilter) return;

    this.visualFilter.setViewBox(viewBox);
  }

  renderSvg(categoryId, bodyPart, params) {
    if (!this.svgContainer || !this.main.categories[categoryId]) return;
    else if (this.main.categories[categoryId] && !this.main.categories[categoryId].vfSvgSource) {
      this.mainElement.classList.add('empty');
      if (this.params.onSVGHide) this.params.onSVGHide();
      return;
    }

    this.mainElement.classList.remove('empty');

    this.svgContainer.innerHTML = '';

    const visualFilter = new VisualFilterSVG(`#${this.params.svgContainerId}`, {
      categoryId: categoryId,
      category: this.main.categories[categoryId].category,
      categoryCfg: this.main.categories[categoryId],
      defaultBodyPart: bodyPart,
      defaultState: {
        ...params,
      },
      hideArrow: true,
      customViewBox: this.getResponsiveViewBox(),
      onFilterChange: this.handleFilterParamsChange,
      onPropChange: this.handleBodyPartChage,
      onSVGLoaded: () => {
        if (typeof this.params.onSVGLoaded === 'function') {
          this.params.onSVGLoaded();
        }
      },
      onBodyPartClick: () => {
        if (typeof this.params.onTouchClick === 'function') {
          this.params.onTouchClick();
        }
      },
      debugTouchArea: false,
      showHighlightOnBuild: true,
      svgScale: this.getResponsiveSvgScale(),
      badgeMode: this.params.svgHideTouchPoints,
      rotateValueOnFirstClick: this.params.rotateValueOnFirstClick,
      tagHighlightColor: this.params.tagHighlightColor,
      tagDefaultColor: this.params.tagDefaultColor,
    });
    visualFilter.setLastBodyPart(bodyPart);

    this.visualFilter = visualFilter;
  }

  render() {
    this.mainElement.classList.add('VisualFilter');

    if (this.params.presetNavigation) {
      this.mainElement.insertAdjacentHTML('beforeend', this.params.navigationTemplate);
    }

    if (this.params.showTooltipsToggler || this.params.showResetButton) {
      this.mainElement.insertAdjacentHTML('beforeend', this.params.buttonsContainerTemplate);
    }

    if (this.params.showTooltipsToggler) {
      this.mainElement.querySelector('#style-buttons-container')
        .insertAdjacentHTML('beforeend', this.params.tooltipTemplate);
    }

    if (this.params.showResetButton) {
      this.mainElement.querySelector('#style-buttons-container')
        .insertAdjacentHTML('beforeend', this.params.resetButtonTemplate);
    }

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    this.svgContainer = svg;
    svg.id = this.params.svgContainerId;
    this.mainElement.appendChild(svg);
    return this.mainElement;
  }
}

export default (params) => {
  return new VisualFilter(params);
};