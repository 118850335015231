import "core-js/stable";
import "regenerator-runtime/runtime";
import './modules/analytics';
import './styles/styles.scss';
import VisualFilter from './Main';
import { categories, paternsFilterParams, materialsFilterParams, paramLabelsParams } from './default-configuration';

export default (params) => (new VisualFilter(params));

export {
  VisualFilter,
  categories,
  paternsFilterParams,
  materialsFilterParams,
  paramLabelsParams,
};
