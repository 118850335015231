import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'

import { ThumbnailPicker, ThumbnailPickerOption } from '../../ui-kits/selects'
import {
  CATEGORY_WTOP,
  CATEGORY_WSHOES,
  CATEGORY_WPANTS,
  CATEGORY_MPANTS,
  CATEGORY_MSHIRTS,
  CATEGORY_MTSHIRTS,
  CATEGORY_MKNIT,
  CATEGORY_MOUTER,
  CATEGORY_MSHOES,
  CATEGORY_MBAG,
  CATEGORY_MWALLET,
  CATEGORY_WSKIRT,
  CATEGORY_WDRESS,
  CATEGORY_WBAG,
  CATEGORY_WEARRING,
  CATEGORY_WOUTER,
  CATEGORY_WKNIT,
  CATEGORY_WWALLET
} from '../../config/constants'
import { getCatCfg } from '@yesplz/core-models/src/VFCatCfg'
import { getCatData } from '@yesplz/core-models/src/VFCatViewData'

import WtopsTN from '@yesplz/core-web/assets/svg/TN-Women-Tops.svg'
import WpantsTN from '@yesplz/core-web/assets/svg/TN-Women-Pants.svg'
import WshoesTN from '@yesplz/core-web/assets/svg/TN-Women-Shoes.svg'
import WknitTN from '@yesplz/core-web/assets/svg/TN-Women-Knit.svg'
import WskirtTN from '@yesplz/core-web/assets/svg/TN-Women-Skirts.svg'
import WdressTN from '@yesplz/core-web/assets/svg/TN-Women-Dress.svg'
import WouterTN from '@yesplz/core-web/assets/svg/TN-Women-Outer.svg'
import WbagTN from '@yesplz/core-web/assets/svg/TN-Women-Bags.svg'
import WearringTN from '@yesplz/core-web/assets/svg/TN-Women-Earrings.svg'
import WwalletTN from '@yesplz/core-web/assets/svg/TN-Women-Wallets.svg'
import MshirtsTN from '@yesplz/core-web/assets/svg/TN-Men-Shirts.svg'
import MtshirtsTN from '@yesplz/core-web/assets/svg/TN-Men-Tshirts.svg'
import MknitTN from '@yesplz/core-web/assets/svg/TN-Men-Knit.svg'
import MouterTN from '@yesplz/core-web/assets/svg/TN-Men-Outer.svg'
import MpantsTN from '@yesplz/core-web/assets/svg/TN-Men-Pants.svg'
import MshoesTN from '@yesplz/core-web/assets/svg/TN-Men-Shoes.svg'
import MbagTN from '@yesplz/core-web/assets/svg/TN-Men-Bags.svg'
import MwalletTN from '@yesplz/core-web/assets/svg/TN-Men-Wallets.svg'

import './StylesSelectItem.scss'

const thumbnailImages = {
  [CATEGORY_WTOP]: WtopsTN,
  [CATEGORY_WSHOES]: WshoesTN,
  [CATEGORY_WPANTS]: WpantsTN,
  [CATEGORY_WDRESS]: WdressTN,
  [CATEGORY_WSKIRT]: WskirtTN,
  [CATEGORY_WOUTER]: WouterTN,
  [CATEGORY_WEARRING]: WearringTN,
  [CATEGORY_WKNIT]: WknitTN,
  [CATEGORY_WWALLET]: WwalletTN,
  [CATEGORY_WBAG]: WbagTN,
  [CATEGORY_MSHIRTS]: MshirtsTN,
  [CATEGORY_MTSHIRTS]: MtshirtsTN,
  [CATEGORY_MKNIT]: MknitTN,
  [CATEGORY_MOUTER]: MouterTN,
  [CATEGORY_MPANTS]: MpantsTN,
  [CATEGORY_MSHOES]: MshoesTN,
  [CATEGORY_MWALLET]: MwalletTN,
  [CATEGORY_MBAG]: MbagTN
}

const StylesSelectItem = ({ name, value, bodyPartKey, category, config, onChange }) => {
  const [ bodyPartValue, changeBodyPartValue ] = useState()
  const max = config.propMaxVal[bodyPartKey]
  useEffect(() => {
    changeBodyPartValue(value[bodyPartKey])
  }, [value[bodyPartKey]])

  const handleChange = (_, itemValue) => {
    changeBodyPartValue(itemValue)

    onChange(name, changeFilters(category, value, bodyPartKey, itemValue))
  }

  return (
    <ThumbnailPicker name={name} value={bodyPartValue} onChange={handleChange}>
      {times(max+1, index => {
        let itemValue = index
        let label, row, col
        label = getCatCfg(category).get_tn_label(bodyPartKey, index)
        row = getCatCfg(category).get_tn_row(bodyPartKey, value)
        col = itemValue
        // console.log(label, row, col)

        // Override row for some temporary exceptional cases.
        // Will be removed once we synchronize VF options between FE and BE
        if (category === 'mouter' && bodyPartKey === 'sleeves' && itemValue == 1) {
          col = itemValue + 1 // Skip short sleeve thumbnail
        }

        return (
          <ThumbnailPickerOption
            key={`vf-${bodyPartKey}-${itemValue}`}
            label={`${label}`}
            value={itemValue}
            nameCategory={name}>
            <div className='thumbnail-item'>
              <div className={`silhouetteTN tn-${row}-${col}`}
                style={{ 'backgroundImage': `url(${thumbnailImages[category]})` }} />
            </div>
          </ThumbnailPickerOption>
        )
      })}
    </ThumbnailPicker>
  )
}

StylesSelectItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  bodyPartKey: PropTypes.string,
  category: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func
}

const changeFilters = (category, value, bodypartkey, bodyPartValue) => {
  const func = getCatData(category).sanitizePropChange
  if (func) {
    func(value, bodypartkey, bodyPartValue)
    return value
  } else {
    console.log('Unhandled prop change', category, bodypartkey, bodyPartValue)
    return {
      ...value,
      [bodypartkey]: bodyPartValue
    }
  }
}

export default StylesSelectItem
