import React from 'react'
import PropTypes from 'prop-types'

import Product from './Product'
import './ProductList.scss'

class ProductList extends React.Component {
  handleClickProduct = productId => () => {
    const { handleClickProduct } = this.props
    handleClickProduct(productId)
  }

  getWidth () {
    const { elementsInLine } = this.props
    return elementsInLine ? `${100 / elementsInLine}%` : '33.33%'
  }

  render () {
    const { products } = this.props
    return (
      <div className='Product-List'>
        <div className='list-title'>You might also like</div>
        <div className='list-content'>
          {products && products.map(product => (
            <div
              key={product.product.productId}
              className='product-item'
              style={{ width: this.getWidth() }}
            >
              <Product
                product={product}
                handleClickProduct={this.handleClickProduct(product.product.productId)}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

ProductList.propTypes = {
  products: PropTypes.array,
  handleClickProduct: PropTypes.func
}

export default ProductList
