import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';
// import SidebarItem from 'modules/presets/SidebarItem';

import visualfilter from '@yesplz/visualfilter';
import '@yesplz/visualfilter/src/styles/styles.scss';

let vs = null;

export default ({
  sort, categoryId, categories, paternsFilterParams = {}, materialsFilterParams = {}, searchAdditionalParams, onSearchFinished
}) => {
  useEffect(() => {
    if (!vs) return;

    vs.setCategory(categoryId);
  }, [categoryId]);

  useEffect(() => {
    if (!vs) return;

    vs.setSort(sort);
  }, [sort]);

  useEffect(() => {
    vs = visualfilter({
      categories,
      searchFinishedReturnFields: null,
      isAdmin: true,
      usePersistentFilter: true,
      searchAdditionalParams,
      initialFilter: {
        categoryId,
      },
    });

    vs.addWidget(
      vs.widgets.CollapsibleFilters({
        container: '#style-filter',
        defaultTab: 'style-filter',
        filters: ['presets', 'occasions', 'style', 'patterns', 'colors', 'materials'],
        filterLabels: {
          'presets': 'Product Type',
        },
        categoriesFilterParams: {
          showGenderTitles: false,
        },
        visualFilterParams: {
          svgViewBox: [15, -5, 300, 200],
          svgViewBoxMobile: [0, -10, 300, 200],
          presetNavigation: false,
          svgScale: 0.95,
          svgScaleMobile: 0.9,
          showTooltipsToggler: false,
          showParamTags: true,
          onSVGLoaded: () => {},
          onTouchClick: () => {},
        },
        styleFilterParams: {
          displayResetInCategories: Object.keys(categories),
        },
        patternsFilterParams: {
          ...paternsFilterParams,
          showReset: true,
        },
        materialsFilterParams: {
          showReset: true,
          ...materialsFilterParams,
        },
        colorsFilterParams: {
          resetImage: resetIconSvg,
        },
        presetsParams: {
          showResetButton: false,
        },
        filtersOpenByDefault: ['style'],
        usePresetFilter: false,
        useFooter: true,
        isFoldable: true,
        // onOpened: onOpened,
        // onClosed: onClosed,
        // onApplyClick: () => {
        //   // this.props.onApplyClick();
        // },
      })
    );

    vs.addWidget(
      vs.widgets.Pagination({
        container: '#visual-filter-pagination',
      })
    );

    vs.on('searchFinished', onSearchFinished);
  }, []);

  return (
    <div
      // ref={sfContainerRef}
      id="style-filter"
      className="sidebar-style-filter"
    />
  );
}

const useStyles = makeStyles({
  sticky: {
    zIndex: 1000,
  },
  filterDetails: {
    paddingTop: 15,
    paddingBottom: 15,

    '& .ThumbnailPicker': {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
    '& .ThumbnailPickerOption': {
      margin: '0 3px'
    }
  },
  visualFilter: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #eae9ea',
    padding: '15px 0',
  },
});
