import React, { Component } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { getProducts } from '@yesplz/core-redux/ducks/products'
import { mapProductFavorites } from '@yesplz/core-redux/ducks/helpers'
import ProductGrid from '../products/PresetProductGrid'
import { Product } from '@yesplz/core-models'
import Preset from '@yesplz/core-web/modules/presets/Preset'
import MinimalPreset from '@yesplz/core-web/modules/presets/MinimalPreset'
import { withProductLike } from '@yesplz/core-web/hoc'
import { GroupTitle } from '@yesplz/core-web/ui-kits/misc'
import TrashSVG from '@yesplz/core-web/assets/svg/trash-can.svg'
import '@yesplz/core-web/modules/presets/AdvancedPreset.scss'

class AdvancedPreset extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    preset: PropTypes.object.isRequired,
    activeCategory: PropTypes.string.isRequired,
    presetMatchesCount: PropTypes.number,
    useMinimalPreset: PropTypes.bool,
    showOriginalPrice: PropTypes.bool,
    hidePreset: PropTypes.bool,
    titleBellowPreset: PropTypes.bool,
    defaultViewBoxSvg: PropTypes.array,
    onClick: PropTypes.func,
    onToggleLike: PropTypes.func,
    toggleProductLike: PropTypes.func,
    onClickGroupTitle: PropTypes.func,
    onClickEditPreset: PropTypes.func,
    onClickPublishPreset: PropTypes.func,
    onClickRemovePreset: PropTypes.func,
    handleBugReport: PropTypes.func,
    adminPreset: PropTypes.bool
  }

  static defaultProps = {
    presetMatchesCount: 4,
    useMinimalPreset: false,
    showOriginalPrice: false,
    hidePreset: false,
    titleBellowPreset: false,
    adminPreset: false,
    onClickGroupTitle () { },
    onClickEditPreset () { },
    onClickPublishPreset () { },
    onClickRemovePreset () { }
  }

  constructor (props) {
    super(props)
    this.state = {
      products: []
    }
  }

  get sliderSettings () {
    return {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }

  async componentDidMount () {
    this.fetchProducts(this.props)
    // const { preset, presetMatchesCount, activeCategory } = this.props

    // // get 4 products relevant to the preset
    // const response = await getProducts(activeCategory, omit(preset, 'favorite', 'name'), presetMatchesCount)
    // this.setState({
    //   products: mapProductFavorites(Product.getFavoriteProductIds(), response.products)
    // })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.preset.name !== this.props.preset.name) {
      this.fetchProducts(this.props)
    }
  }

  get handleToggleLike () {
    return (data, favorite) => {
      const { toggleProductLike } = this.props
      const { products } = this.state

      toggleProductLike(data, favorite)
      this.setState({
        products: mapProductFavorites(Product.getFavoriteProductIds(), products)
      })
    }
  }

  fetchProducts = async (props) => {
    const { preset, presetMatchesCount, activeCategory } = props
    const response = await getProducts(activeCategory, omit(preset, 'favorite', 'name'), presetMatchesCount)
    this.setState({
      products: mapProductFavorites(Product.getFavoriteProductIds(), response.products)
    })
  }

  render () {
    const {
      id,
      preset,
      useMinimalPreset,
      hidePreset,
      titleBellowPreset,
      activeCategory,
      onClick,
      onClickGroupTitle,
      onToggleLike,
      defaultViewBoxSvg,
      adminPreset,
      onClickEditPreset,
      onClickPublishPreset,
      onClickRemovePreset,
      handleBugReport
    } = this.props
    const { products } = this.state
    return (
      <div className='AdvancedPreset'>
        {!titleBellowPreset && <GroupTitle onClickTitle={onClickGroupTitle}>{preset.name}</GroupTitle>}
        {adminPreset && (
          <div className='trashContainer'>
            <div>Delete This Setting</div>
            <button className='DeletePresetSetting' onClick={onClickRemovePreset}>
              <img src={TrashSVG} alt='' />
            </button>
          </div>
        )}
        <Slider {...this.sliderSettings}>
          {
            !hidePreset
              ? (useMinimalPreset ? (
                <React.Fragment>
                  <MinimalPreset
                    key={preset.name}
                    id={id}
                    name={preset.name}
                    coretype={preset.coretype}
                    neckline={preset.neckline}
                    shoulder={preset.shoulder}
                    sleeveLength={preset.sleeve_length}
                    topLength={preset.top_length}
                    pattern={preset.pattern}
                    solid={preset.solid}
                    details={preset.details}
                    bottoms={preset.bottoms}
                    counters={preset.counters}
                    covers={preset.covers}
                    shafts={preset.shafts}
                    toes={preset.toes}
                    ankle={preset.ankle}
                    knee={preset.knee}
                    rise={preset.rise}
                    thigh={preset.thigh}
                    color={preset.color}
                    favorite={preset.favorite}
                    category={activeCategory}
                    onClick={onClick}
                    onToggleLike={onToggleLike}
                    defaultViewBoxSvg={defaultViewBoxSvg}
                    handleBugReport={handleBugReport}
                    adminPreset
                    className='adminPreset'
                  />
                  {titleBellowPreset && <GroupTitle onClickTitle={onClickGroupTitle}>{preset.name}</GroupTitle>}
                  {adminPreset && (
                    <div className='event-container'>
                      <button className='eventBtn' onClick={onClickEditPreset}>Edit</button>
                      <button className={`${preset.published && 'published'} eventBtn`} onClick={onClickPublishPreset}>{preset.published ? 'Published' : 'Publish'}</button>
                    </div>
                  )}
                </React.Fragment>

              ) : (
                <Preset
                  key={preset.name}
                  id={id}
                  name={preset.name}
                  coretype={preset.coretype}
                  neckline={preset.neckline}
                  shoulder={preset.shoulder}
                  sleeveLength={preset.sleeve_length}
                  topLength={preset.top_length}
                  pattern={preset.pattern}
                  solid={preset.solid}
                  details={preset.details}
                  bottoms={preset.bottoms}
                  counters={preset.counters}
                  covers={preset.covers}
                  shafts={preset.shafts}
                  toes={preset.toes}
                  ankle={preset.ankle}
                  knee={preset.knee}
                  rise={preset.rise}
                  thigh={preset.thigh}
                  material={preset.material}
                  color={preset.color}
                  favorite={preset.favorite}
                  category={activeCategory}
                  onClick={onClick}
                  onToggleLike={onToggleLike}
                  handleBugReport={handleBugReport}
                  adminPreset
                  className='adminPreset'
                />
              )) : null
          }
          {
            products.map((product) => (
              <ProductGrid
                key={product.product_id}
                product={product}
                category={product.category}
                className='PresetMatches-productGrid'
              />
            ))
          }
        </Slider>
      </div>
    )
  }
}

export default withProductLike()(AdvancedPreset)
