import Widget from '@yesplz/visualfilter/src/modules/Widget';

const CONTAINER_CLASS_NAME = `simple-presets-list`;

class OccasionsFilter extends Widget {
  defaultParams = {
    fitText: false,
    fitTextMaxSize: 15,
    showResetButton: false,
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.occasions = {};
    this.labelElements = {};
  }

  handleLabelClick = (e) => {
    const button = e.target;
    const newOccasion = button.getAttribute('data-occasion');
    const { occasion, categoryId } = this.state.filter;
    const { tn } = this.main.categories[categoryId];

    this.setFilter({
      occasion: newOccasion !== occasion ? newOccasion : null,
      params: {
        ...(Object.keys(tn).reduce((params, bodyPart) => {
          params[bodyPart] = 'all';
          return params;
        }, {
          design: [],
          color: [],
          material: [],
        }))
      },
    });
  }

  didUpdate(prevState) {
    if (this.state.filter.categoryId !== prevState.filter.categoryId) {
      this.renderItems();
    }
    else if (this.state.filter.occasion !== prevState.filter.occasion) {
      const occasion = this.state.filter.occasion;
      const prevOccasion = prevState.filter.occasion;

      if (occasion) {
        const button = this.element.querySelector(`[data-occasion="${occasion}"]`);
        if (button) button.classList.add('is-selected');
      }
      if (prevOccasion) {
        const button = this.element.querySelector(`[data-occasion="${prevOccasion}"]`)
        if (button) button.classList.remove('is-selected');
      }
    }
  }

  renderItems() {
    const { categoryId } = this.state.filter;
    const categorySettings = this.main.categories[categoryId];

    if (!categorySettings) return;

    this.element.className = CONTAINER_CLASS_NAME;
    this.element.innerHTML = '';
    this.occasions = categorySettings.occasionsList || [];

    const presetsHtml = this.occasions.map(({ label, value }) => {
      return `
        <li data-occasion="${value}">
          ${label}
        </li>
      `;
    });

    const html = `
      <ul>
        ${presetsHtml.join('')}
      </ul>
    `;

    this.element.insertAdjacentHTML('beforeend', html);

    this.element.querySelectorAll('[data-occasion]').forEach(button => {
      button.addEventListener('click', this.handleLabelClick);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(this.occasions);
    }
  }

  render() {
    this.renderItems();
    return this.element;
  }
}

export default (params) => {
  return new OccasionsFilter(params);
};
