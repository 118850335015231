import upperFirst from 'lodash/upperFirst';
import isEqual from 'lodash/isEqual';
// import isInViewport from '@yesplz/core-web/utils/isInViewport';
import closeSvg from '@yesplz/core-web/assets/svg/angle-down.svg';
import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';
import Widget from '../modules/Widget';

const { document } = window;

const WIDGETS_MAP = {
  'categories': 'SimpleCategoriesList',
  'presets': 'SimplePresetsList',
  'occasions': 'SimpleOccasionsList',
  'patterns': 'DesignFilter',
  'colors': 'ColorFilter',
  'materials': 'MaterialFilter',
  'price': 'PriceRangeFilter',
  'brand': 'BrandFilter',
};

class CollapsibleFilters extends Widget {
  defaultParams = {
    filters: ['categories', 'brand', 'style', 'patterns', 'colors', 'materials', 'price'],
    filtersOpenByDefault: ['style'],
    filterLabels: {
      'categories': 'Category',
      'presets': 'Product Type / Occasion',
      'occasions': 'Occasion',
      'style': 'Style',
      'patterns': 'Pattern',
      'colors': 'Color',
      'materials': 'Material',
      'price': 'Price',
      'brand': 'Brand',
    },
    usePresetFilter: true,
    useHeader: true,
    useFooter: true,
    isFoldable: true,
    isOpenInitialy: false,
    // stylesAsPrefix: false,
    categoriesFilterParams: {},
    visualFilterParams: {
      svgViewBox: [15, -5, 300, 200],
      svgViewBoxMobile: [0, -10, 300, 200],
      showTooltipsToggler: false,
      presetNavigation: false,
    },
    styleFilterParams: {},
    patternsFilterParams: {},
    colorsFilterParams: {
      resetImage: resetIconSvg,
    },
    materialsFilterParams: {},
    brandFilterParams: {
      displaySearchBar: true,
    },
    priceFilterParams: {},
    presetsParams: {},
    onCloseClick: () => {},
    onApplyClick: () => {},
    onOpened: () => {},
    onClosed: () => {},
    headerClearAllText: 'Reset all',
  };

  constructor(params) {
    super(params);

    this.container.classList.add('CollapsibleFilters-container');

    this.mainElement = document.createElement('div');

    if (params.filters)
      this.params.filters = params.filters;
  }

  didMount() {
    setTimeout(() => {
      for (const filter of this.params.filters) {
        if (typeof this[`mount${upperFirst(filter)}Widget`] === 'function') {
          this[`mount${upperFirst(filter)}Widget`]();
        }
        else {
          this.mountWidget(filter);
        }
      }

      this.renderFilterValues();
      this.renderFilterActions();

      const collapsibles = document.querySelectorAll('.Collapsible');
      collapsibles.forEach(collapsible => {
        if (this.params.filtersOpenByDefault.includes(collapsible.getAttribute('data-filter'))) {
          const content = collapsible.querySelector('.Collapsible-content');
          collapsible.classList.add('open');
          content.style.maxHeight = "none";
        }
      });

      const scrollContainer = window.innerWidth <= 768
        ? this.container.querySelector('.CollapsibleFilters-Foldable')
        : this.container;
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', (e) => {
          const container = e.target;
          const containerRect = container.getBoundingClientRect();
          const containerOffsetY = containerRect.y;
          collapsibles.forEach((collapsible, index) => {
            if (collapsible.classList.contains('open')) {
              const { y } = collapsible.getBoundingClientRect();
              const relativeY = y - containerOffsetY - 66; // 66 is header height
              if (relativeY < 0) {
                collapsible.classList.add('is-sticky');
              }
              else {
                collapsible.classList.remove('is-sticky');
              }
            }
          });
        });
      }

      if (typeof this.params.onApplyClick === 'function') {
        const applyButton = this.container.querySelector('#collapsible-apply-button');
        if (applyButton) {
          applyButton.addEventListener('click', () => {
            this.params.onApplyClick();
            this.close();
          });
        }
      }

      const clearButton = document.getElementById('collapsible-clear-button');
      if (clearButton) {
        clearButton.addEventListener('click', () => {
          const filter = this.main.getFilter();
          const { categories } = this.main;
          const { partList } = this.main.categories[filter.categoryId];
          const bodyPartValues = {};
          partList.forEach(part => {
            bodyPartValues[part] = 'all';
          });
          this.setFilter({
            presetIndex: null,
            occasion: null,
            params: {
              ...bodyPartValues,
              design: [],
              color: [],
              material: [],
              price: undefined,
              brands: [],
            },
          });
        });
      }

      const closeButton = document.getElementById('collapsible-close-button');
      if (closeButton) {
        closeButton.addEventListener('click', () => this.close());
      }

      const externalOpener = this.params.externalOpener && document.querySelector(this.params.externalOpener);
      if (externalOpener) {
        externalOpener.addEventListener('click', () => this.open());
      }

      // const totalFoundContainer = document.getElementById('collapsible-total-found');
      // if (this.params.useHeader && totalFoundContainer) {
      //   this.main.addWidget(
      //     this.main.widgets.TotalFound({
      //       container: '#collapsible-total-found',
      //     })
      //   );
      // }

      this.backdrop = document.getElementById('collapsible-backdrop');
      if (this.backdrop)
        this.backdrop.addEventListener('click', () => this.close());
    }, 100);
  }

  didUpdate(prevState) {
    this.renderFilterValues(prevState);
  }

  handlePresetsRendered = (presets) => {
    const presetsCollapsible = this.container.querySelector('[data-filter="presets"]');
    if (presets.length > 0) {
      presetsCollapsible.style.display = 'block';
    }
    else {
      presetsCollapsible.style.display = 'none';
    }
  }

  handlePaternsRendered = (types) => {
    const patternsCollapsible = this.container.querySelector('[data-filter="patterns"]');
    if (types.length > 0) {
      patternsCollapsible.style.display = 'block';
    }
    else {
      patternsCollapsible.style.display = 'none';
    }
  }

  handleMaterialsRendered = (types) => {
    const patternsCollapsible = this.container.querySelector('[data-filter="materials"]');
    if (types.length > 0) {
      patternsCollapsible.style.display = 'block';
    }
    else {
      patternsCollapsible.style.display = 'none';
    }
  }

  handleOccasionsRendered = (occasions) => {
    const occasionsCollapsible = this.container.querySelector('[data-filter="occasions"]');
    if (occasions.length > 0) {
      occasionsCollapsible.style.display = 'block';
    }
    else {
      occasionsCollapsible.style.display = 'none';
    }
  }

  handleSVGHide = () => {
    const styleCollapsible = document.querySelector('div[data-filter="style"]');
    styleCollapsible.classList.add('is-hidden');
  }

  handleSVGLoaded = () => {
    const styleCollapsible = document.querySelector('div[data-filter="style"]');
    styleCollapsible.classList.remove('is-hidden');
  }

  mountStyleWidget() {
    this.main.addWidget(
      this.main.widgets.VisualFilter({
        container: '#collapsible-visual-filter',
        ...this.params.visualFilterParams,
        onSVGLoaded: (...args) => {
          if (typeof this.params.visualFilterParams.onSVGLoaded === 'function') {
            this.params.visualFilterParams.onSVGLoaded(...args);
          }
          this.handleSVGLoaded();
        },
        onSVGHide: (...args) => {
          if (typeof this.params.visualFilterParams.onSVGHide === 'function') {
            this.params.visualFilterParams.onSVGHide(...args);
          }
          this.handleSVGHide();
        },
        showResetButton: false,
      })
    );

    this.main.addWidget(
      this.main.widgets.StyleFilter({
        container: '#collapsible-style-filter',
        ...this.params.styleFilterParams,
      })
    );

    if (this.params.usePresetFilter) {
      this.main.addWidget(
        this.main.widgets.EditorsPicks({
          container: '#collapsible-presets-filter',
          ...this.params.presetsParams,
        })
      );
    }
  }

  mountWidget(filter) {
    const widgetName = WIDGETS_MAP[filter];
    if (widgetName) {
      this.main.addWidget(
        this.main.widgets[widgetName]({
          container: `#collapsible-${filter}-filter`,
          ...this.params[`${filter}FilterParams`],
          ...(filter === 'patterns' ? { onRendered: this.handlePaternsRendered } : {}),
          ...(filter === 'materials' ? { onRendered: this.handleMaterialsRendered } : {}),
          ...(filter === 'presets' ? { onRendered: this.handlePresetsRendered } : {}),
          ...(filter === 'occasions' ? { onRendered: this.handleOccasionsRendered } : {}),
        })
      );
    }
  }

  getCategoriesFilterValue(prevFilter, filter) {
    if (prevFilter.categoryId !== filter.categoryId) {
      const category = this.main.categories[filter.categoryId];
      return category.label;
    }

    return false;
  }

  getPresetsFilterValue(prevFilter, filter) {
    if (prevFilter.presetIndex !== filter.presetIndex) {
      return filter.presetIndex || '';
    }
    return false;
  }

  getOccasionsFilterValue(prevFilter, filter) {
    if (prevFilter.occasion !== filter.occasion) {
      if (!filter.occasion) return '';

      const category = this.main.categories[filter.categoryId];
      const label = (category.occasionsList || []).reduce((label, occasion) => {
        if (occasion.value === filter.occasion) return occasion.label;
        return label;
      }, '');

      return label;
    }
    return false;
  }

  getPatternsFilterValue(prevFilter, filter) {
    const { design: prevPaterns } = prevFilter.params || {};
    const { design: paterns = [] } = filter.params;
    if (!prevPaterns || !isEqual(prevPaterns, paterns)) {
      const availablePaterns = this.params.patternsFilterParams.designTypes;
      const appliedPaterns = availablePaterns
        .filter(patern => paterns.includes(patern.type))
        .map(patern => patern.label);
      return appliedPaterns.join(', ');
    }
    else if (paterns.length === 0) {
      return '';
    }
    return false;
  }

  getMaterialsFilterValue(prevFilter, filter) {
    const { material: prevMaterials } = prevFilter.params || {};
    const { material: materials = [] } = filter.params;
    if (!prevMaterials || !isEqual(prevMaterials, materials)) {
      const availableMaterials = this.params.materialsFilterParams.materialTypes;
      const appliedMaterials = availableMaterials
        .filter(material => materials.includes(material.type))
        .map(material => material.label);
      return appliedMaterials.join(', ');
    }
    else if (materials.length === 0) {
      return '';
    }
    return false;
  }

  getColorsFilterValue(prevFilter, filter) {
    const { color: prevColors } = prevFilter.params || {};
    const { color: colors = [] } = filter.params;
    if (!prevColors || !isEqual(prevColors, colors)) {
      return colors.map(color => upperFirst(color)).join(', ');
    }
    else if (colors.length === 0) {
      return '';
    }
    return false;
  }

  getPriceFilterValue(prevFilter, filter) {
    const { price: prevPrice } = prevFilter.params || {};
    const { price } = filter.params;
    if (!prevPrice || prevPrice !== price) {
      return price ? price : '';
    }
    return false;
  }

  getBrandFilterValue(prevFilter, filter) {
    const { brands: prevBrands } = prevFilter.params || {};
    const { brands = [] } = filter.params;
    if (!prevBrands || !isEqual(prevBrands, brands)) {
      return brands.join(', ');
    }
    else if (brands.length === 0) {
      return '';
    }
    return false;
  }

  renderFilterValues(prevState = {}) {
    const { filter: prevFilter = {} } = prevState;
    const { filter } = this.state;
    for (const filterName of this.params.filters) {
      if (typeof this[`get${upperFirst(filterName)}FilterValue`] === 'function') {
        const value = this[`get${upperFirst(filterName)}FilterValue`](prevFilter, filter);
        if (typeof value === 'string') {
          const titleElement = document.getElementById(`${filterName}-filter-value`);
          if (titleElement) titleElement.innerHTML = value.length ? `(${value})` : value;
        }
      }
    }
  }

  getStyleFilterAction() {
    return 'clear';
  }

  handleStyleFilterActionClick = (e) => {
    e.stopPropagation();
    const filter = this.main.getFilter();
    const { partList } = this.main.categories[filter.categoryId];
    const params = filter.params;
    partList.forEach(part => {
      params[part] = 'all';
    });
    this.setFilter({
      presetIndex: null,
      occasion: null,
      params,
    });
  }

  renderFilterActions(prevState = {}) {
    const { filter: prevFilter = {} } = prevState;
    const { filter } = this.state;
    for (const filterName of this.params.filters) {
      const actionName = upperFirst(filterName);
      if (typeof this[`get${actionName}FilterAction`] === 'function') {
        const label = this[`get${actionName}FilterAction`](prevFilter, filter);
        if (typeof label === 'string') {
          const actionElement = document.getElementById(`${filterName}-filter-action`);
          if (actionElement) {
            actionElement.innerHTML = label;
            actionElement.addEventListener('click', this[`handle${actionName}FilterActionClick`]);
          }
        }
      }
    }
  }

  renderCollapsible(filter) {
    let htmlContent = `<div id="collapsible-${filter}-filter"></div>`;
    if (filter === 'style') {
      htmlContent = '<div id="collapsible-visual-filter"></div>' + htmlContent;

      if (this.params.usePresetFilter) {
        htmlContent = '<div id="collapsible-presets-filter"></div>' + htmlContent;
      }
    }

    const collapsible = document.createElement('div');
    collapsible.className = `Collapsible`;
    collapsible.setAttribute('data-filter', filter);
    collapsible.innerHTML = `
      <div class="Collapsible-header">
        <span class="plus"></span>
        <div class="Collapsible-header-title">
          <h3>${this.params.filterLabels[filter]}</h3>
          <span class="filter-value" id="${filter}-filter-value"></span>
          <span class="filter-action" id="${filter}-filter-action"></span>
        </div>
      </div>
      <div class="Collapsible-content">
        ${htmlContent}
      </div>
    `;

    collapsible.querySelector('.Collapsible-header').addEventListener('click', (e) => {
      const content = collapsible.querySelector('.Collapsible-content');
      if (collapsible.classList.contains('open')) {
        collapsible.classList.remove('open');
        collapsible.classList.remove('is-sticky');
        content.style.maxHeight = content.scrollHeight + "px";
        setTimeout(() => {
          content.style.maxHeight = null;
        }, 0);
      }
      else {
        collapsible.classList.add('open');
        collapsible.classList.add('is-animating');
        content.style.maxHeight = content.scrollHeight + "px";

        const transitionend = () => {
          collapsible.classList.remove('is-animating');
          content.style.maxHeight = "none";
          content.removeEventListener('transitionend', transitionend);

          // if (!isInViewport(content)) {
          //   content.scrollIntoView();
          // }
        }
        content.addEventListener('transitionend', transitionend);
      }
    });

    return collapsible;
  }

  open() {
    const slider = this.mainElement;
    slider.style.transform = `translate3d(0, 0, 0)`;
    this.backdrop.classList.remove('is-hidden');

    if (typeof this.params.onOpened === 'function') {
      const onOpened = () => {
        this.params.onOpened();
        slider.removeEventListener('transitionend', onOpened);
      };
      slider.addEventListener('transitionend', onOpened);
    }
  }

  close() {
    const slider = this.mainElement;
    const { height } = slider.getBoundingClientRect();
    const translation = height;

    slider.style.transform = `translate3d(0, ${translation}px, 0)`;

    this.backdrop.classList.add('is-hidden');

    if (typeof this.params.onClosed === 'function') {
      const onClosed = () => {
        this.params.onClosed();
        slider.removeEventListener('transitionend', onClosed);
      };
      slider.addEventListener('transitionend', onClosed);
    }
  };

  render() {
    this.mainElement.classList.add('CollapsibleFilters');
    for (const filter of this.params.filters) {
      if (!filter) continue;
      this.mainElement.appendChild(this.renderCollapsible(filter));
    }

    if (this.params.isFoldable) {
      this.mainElement.classList.add('CollapsibleFilters-Foldable');
    }

    if (this.params.useHeader) {
      this.mainElement.insertAdjacentHTML('afterbegin', `
        <div class="CollapsibleFilters-header">
          <div class="CollapsibleFilters-header-actions">
            <h3>Filters</h3>
            <button id="collapsible-clear-button">${this.params.headerClearAllText}</button>
          </div>
          <div id="collapsible-close-button" class="CollapsibleFilters-header-close">
            <img src="${closeSvg}" alt="" />
          </div>
        </div>
      `);
    }

    if (this.params.useFooter) {
      this.mainElement.insertAdjacentHTML('beforeend', `
        <div class="CollapsibleFilters-footer">
          <button id="collapsible-apply-button" className="CollapsibleFilters-footer-button">Apply filters</button>
        </div>
      `);
    }
    this.container.insertAdjacentHTML('afterbegin', `
      <div id="collapsible-backdrop" class="CollapsibleFilters-backdrop${!this.params.isOpenInitialy ? ' is-hidden' : ''}"></div>
    `);

    if (!this.params.isOpenInitialy && this.params.isFoldable) {
      this.mainElement.style.transform = `translate3d(0, 410px, 0)`;
    }

    return this.mainElement;
  }
}

export default (params) => {
  return new CollapsibleFilters(params);
};
