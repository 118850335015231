import fitty from 'fitty';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const MAIN_CLASS_NAME = 'EditorsPicks';
const CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}-container`;

const persistentParams = ['brands', 'price'];

class EditorsPicks extends Widget {
  defaultParams = {
    fitText: false,
    fitTextMaxSize: 15,
    showResetButton: false,
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.presets = {};
    this.labelElements = {};

    this.container.className = MAIN_CLASS_NAME;
  }

  handleLabelClick = (presetName) => {
    const { categoryId, presetIndex, params } = this.state.filter;
    const newPresetIndex = presetIndex === presetName ? 'Reset' : presetName;

    if (newPresetIndex !== 'Reset' && !presetIndex) {
      this.presets['Reset'] = omit(params, persistentParams);
    }

    EventEmitter.emit('presetClick', {
      categoryId,
      preset: newPresetIndex,
    });

    this.setFilter({
      presetIndex: newPresetIndex !== 'Reset' ? newPresetIndex : null,
      ...(
        this.presets[newPresetIndex] ? {
          params: {
            ...pick(params, persistentParams),
            ...this.presets[newPresetIndex],
          }
        } : {}
      ),
    });
  }

  didUpdate(prevState) {
    if (this.state.filter.categoryId !== prevState.filter.categoryId) {
      this.renderItems();
    }
    else if (this.state.filter.presetIndex !== prevState.filter.presetIndex) {
      const presetIndex = this.state.filter.presetIndex;
      const prevPresetIndex = prevState.filter.presetIndex;

      if (presetIndex) {
        this.labelElements[presetIndex].classList.add('is-active');
      }
      if (prevPresetIndex && this.labelElements[prevPresetIndex]) {
        this.labelElements[prevPresetIndex].classList.remove('is-active');
      }
    }
  }

  renderItems() {
    const { categoryId, presetIndex } = this.state.filter;
    const categorySettings = this.main.categories[categoryId];

    if (!categorySettings) return;

    this.element.className = CONTAINER_CLASS_NAME;
    this.container.classList.remove('empty');
    this.element.innerHTML = '';
    this.labelElements = {};
    this.presets = categorySettings.presetList || [];
    const presetKeys = Object.keys(this.presets);

    if (presetKeys.length === 0) {
      this.container.classList.add('empty');
    }

    if (presetKeys.length > 6) {
      this.element.classList.add(`col-${Math.ceil(presetKeys.length / 2)}`);
    }

    [
      ...presetKeys,
      ...((this.params.showResetButton && ['Reset']) || [])
    ].forEach((presetName) => {
      const labelElement = document.createElement('div');
      labelElement.innerHTML = `
        <div><h5>${presetName}</h5></div>
      `;
      labelElement.className = 'EditorsPicks-Item' + (
        presetName === presetIndex ? ' is-active' : ''
      ) + (
        presetName === 'Reset' ? ' reset' : ''
      );
      this.labelElements[presetName] = labelElement;
      this.element.appendChild(labelElement);

      if (this.params.fitText) {
        fitty(labelElement.querySelector('h5'), {
          minSize: 1,
          maxSize: this.params.fitTextMaxSize,
          multiLine: false,
        });
      }

      labelElement.addEventListener('click', () => {
        this.handleLabelClick(presetName);
      });
    });
  }

  render() {
    const container = document.createElement('div');
    const title = document.createElement('h3');
    title.innerText = this.params.title ? this.params.title : 'Styles';
    container.appendChild(title);
    this.renderItems();
    container.appendChild(this.element)
    return container;
  }
}

export default (params) => {
  return new EditorsPicks(params);
};
