import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import { FABRIC_COLORS } from '../consts';

import clearSvg from '@yesplz/core-models/assets/svg/clear.svg';
import EventEmitter from '../modules/EventEmitter';

class ColorFilter extends Widget {
  defaultParams = {
    resetImage: clearSvg,
  };

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.element.className = 'ColorPallete';
    this.colorElements = {};

    this.params.colors = {
      ...(this.params.colors ?? FABRIC_COLORS),
      clear: '',
    };
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.renderColors();
    }
    else if (
      !isEqual(prevState.filter.params.color, this.state.filter.params.color)
    ) {
      const prevColor = prevState.filter.params.color;
      const color = this.state.filter.params.color;
      const addedItems = difference(color, prevColor);
      const removedItems = difference(prevColor, color);
      addedItems.forEach(val => this.colorElements[val].classList.add('active'));
      removedItems.forEach(val => this.colorElements[val].classList.remove('active'));
    }
  }

  handleLabelClick = (colorName) => {
    const { categoryId, params } = this.state.filter;

    EventEmitter.emit('colorThumbnailClick', {
      categoryId,
      color: colorName,
    });

    let color = params.color || [];

    if (colorName === 'clear') {
      color = [];
    }
    else {
      color = (
        color.includes(colorName)
          ? color.filter(color => color !== colorName)
          : [...color, colorName]
      );
    }

    this.setFilter({
      presetIndex: null,
      params: {
        ...params,
        color,
      },
    });
  }

  renderColors() {
    const { params } = this.state.filter;
    this.element.innerHTML = '';
    this.colorElements = {};
    const colorButtons = this.params.colors;

    for (const colorName in colorButtons) {
      const color = FABRIC_COLORS[colorName];
      const labelElement = document.createElement('button');
      const isActive = params.color && params.color.includes(colorName);

      if (colorName === 'clear') {
        labelElement.innerHTML = `<img src="${this.params.resetImage}" alt="" />`;
      }
      else {
        labelElement.innerHTML =
          `
          <div
            class="ColorButton-color"
            style="background-${colorName === 'pastel' ? 'image' : 'color'}: ${color};"
          ></div>
          <div class="ColorButton-mask"></div>
          `;
      }

      labelElement.className = 'ColorButton ' + colorName + (
        isActive ? ' active' : ''
      );
      this.colorElements[colorName] = labelElement;
      this.element.appendChild(labelElement);

      labelElement.addEventListener('click', () => {
        this.handleLabelClick(colorName);
      });
    }
  }

  render() {
    const container = document.createElement('div');
    const title = document.createElement('h3');
    title.innerText = this.params.title ? this.params.title : 'Colors';
    container.appendChild(title);
    this.renderColors();
    container.appendChild(this.element);
    return container;
  }
}

export default (params) => {
  return new ColorFilter(params);
};
