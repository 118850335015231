import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'

// core redux
import { setBugReportSetting } from '@yesplz/core-redux/ducks/admin/report'
import { logout } from '@yesplz/core-redux/ducks/admin/auth'

// core-web
import history from '@yesplz/core-web/config/history'
import HomeSvg from '@yesplz/core-web/assets/svg/home-icon.svg'

// desktop
import './base.css'
import ModalBG from 'assets/images/bug-report-bg.png'
import ModalFG from 'assets/images/bug-report-fg.png'

class Base extends Component {
  static propTypes = {
    children: PropTypes.element,
    location: PropTypes.object,
    authorized: PropTypes.bool,
    openBugReport: PropTypes.bool,
    setBugReportSetting: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      isOpenMenu: false,
      hoverYes: false
    }
  }

  componentDidMount () {
    // const { authorized } = this.props
    // Disable auth for now for quick testing.
    // if (!authorized) {
    //   history.push('/auth')
    // }
  }

  get isProductDetailPage () {
    const { location } = this.props
    return /^\/products\//.test(location.pathname)
  }

  get isFavoritesPage () {
    const { location } = this.props
    return /^\/favorites\//.test(location.pathname)
  }

  get handleHomeLinkActive () {
    return match => match // || this.isProductDetailPage
  }

  get handleFavoritesLinkActive () {
    return () => {
      const { location } = this.props
      return /^\/favorites\/(fits|clothing)$/.test(location.pathname)
    }
  }

  get handleLinkClick () {
    return () => {
      const scrollWrapper = document.getElementById('MainScroll')
      if (scrollWrapper) {
        scrollWrapper.scrollTop = 0
      }
    }
  }

  hanldeLogoutClick = () => {
    this.props.logout();
  }

  isLoginPage = () => {
    const { location } = this.props
    return location.pathname === '/login'
  }

  handleCloseModal = () => {
    const { setBugReportSetting } = this.props
    setBugReportSetting(false)
    this.setState({ hoverYes: false })
  }

  hoverYes = ishover => () => {
    this.setState({ hoverYes: ishover })
  }

  handlePublish = () => {
    const { setBugReportSetting } = this.props
    setBugReportSetting(false)
    history.push('/comingsoon')
  }

  render () {
    const { children, openBugReport, authorized } = this.props
    const { hoverYes } = this.state

    const isDev = process.env.NODE_ENV === 'development'

    return (
      <div id='Base-desktop' className='Base'>
        {!this.isLoginPage() &&
          (
            <div className='Base-header'>
              <div className='Base-headerContainer'>
                <NavLink
                  exact
                  to={'/'}
                  onClick={this.handleLinkClick}
                  className='home-logo'>
                  <img src={HomeSvg} alt='' />
                </NavLink>
                <NavLink
                  exact
                  to={'/'}
                  onClick={this.handleLinkClick}
                  isActive={this.handleHomeLinkActive}
                  className='logo'>
                  YESPLZ
                </NavLink>
                {authorized && !isDev && <button
                  className="logout-button"
                  onClick={this.hanldeLogoutClick}
                >Logout</button>}
              </div>
            </div>
          )
        }
        {openBugReport && (
          <div className='bugReportModal'>
            <div className='overlay' onClick={this.handleCloseModal} />
            <div className='modalContainer'>
              <div className='image-container' style={{backgroundImage: `url(${ModalBG})`}}>
                <img className={`${hoverYes && 'hoverYes'}`} src={ModalFG} alt='' />
              </div>
              <div className='rightContainer'>
                <TextField
                  className='report-field'
                  placeholder='Could you describe the problem or errors for this search result?'
                  multiline
                  rows={5}
                  rowsMax={6}
                />
                <div className='eventContainer'>
                  <button
                    className={`confirmbtn ${hoverYes && 'hoverYes'}`}
                    onMouseOver={this.hoverYes(true)}
                    onMouseOut={this.hoverYes(false)}
                    onClick={this.handlePublish}
                  >Submit</button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={`base-content ${this.isLoginPage() ? 'login' : ''} ${'not-home'}`}>{children}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activeCategory: state.products.activeCategory,
  authorized: state.AdminAuth.authorized,
  openBugReport: state.AdminReport.openBugReport
})

export default compose(
  connect(
    mapStateToProps,
    {
      setBugReportSetting,
      logout,
    }
  )
)(Base)
