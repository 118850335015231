import Handlebars from 'handlebars';
import flatten from 'lodash/flatten';
import Widget from '../modules/Widget';

const { document } = window;

const TEMPLATE = `
<ul>
  {{#each categories}}
    <li{{#if this.id}} data-category-id="{{this.id}}"{{/if}}>
      {{this.label}}
    </li>
  {{/each}}
</ul>
`

class SimpleCategoriesList extends Widget {
  defaultParams = {
    containerClassName: 'simple-categories-list',
    showGenderTitles: true,
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(TEMPLATE);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.lastCategoryId = null;
  }

  changeCategory = (categoryId) => {
    this.main.setCategory(categoryId);
  }

  didMount() {
    const categoryElements = this.mainElement.querySelectorAll('[data-category-id]');
    categoryElements.forEach(categoryElement => {
      categoryElement.addEventListener('click', () => {
        const categoryId = categoryElement.getAttribute('data-category-id');
        this.main.setCategory(categoryId);
      });
    });

    this.updateSelected();
  }

  didUpdate() {
    this.updateSelected();
  }

  updateSelected() {
    const categoryId = this.state.filter.categoryId;
    if (this.lastCategoryId === categoryId) return;

    if (this.lastCategoryId) {
      this.toggle(this.lastCategoryId);
    }

    this.toggle(categoryId);

    this.lastCategoryId = categoryId;
  }

  toggle(categoryId) {
    const categoryElement = this.mainElement.querySelector(`[data-category-id="${categoryId}"]`);
    if (categoryElement) {
      categoryElement.classList.toggle('is-selected');
    }
  }

  render() {
    let prevGenderCode = null;
    const categories = flatten(Object.entries(this.main.categories).map(([categoryId, category]) => {
      const genderCode = categoryId[0];
      const includeGender = this.params.showGenderTitles && prevGenderCode !== genderCode;
      if (includeGender) prevGenderCode = genderCode;

      return [...(includeGender ? [{ label: genderCode === 'm' ? 'Men' : 'Women' }] : []), {
        id: categoryId,
        label: category.label
          .replace(/(Women|Men)/, ''),
      }];
    }));

    if (this.params.showGenderTitles) {
      this.mainElement.classList.add('is-titled');
    }

    this.mainElement.innerHTML = this.template({
      categories,
    });

    return this.mainElement;
  }
}

export default (params) => {
  return new SimpleCategoriesList(params);
};
