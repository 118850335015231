export const BASE_IMG_PATH = process.env.BASE_IMG_PATH || '';

export const RETAILER = process.env.RETAILER || null;

// categories
export const CATEGORY_WTOP = 'wtop'
export const CATEGORY_WSHOES = 'wshoes'
export const CATEGORY_WPANTS = 'wpants'
export const CATEGORY_WDRESS = 'wdress'
export const CATEGORY_WSKIRT = 'wskirt'
export const CATEGORY_WOUTER = 'wouter'
export const CATEGORY_WEARRING = 'wearring'
export const CATEGORY_WKNIT = 'wknit'
export const CATEGORY_WWALLET = 'wwallet'
export const CATEGORY_WBAG = 'wbag'

export const CATEGORY_MSHIRTS = 'mshirts'
export const CATEGORY_MTSHIRTS = 'mtshirts'
export const CATEGORY_MPANTS = 'mpants'
export const CATEGORY_MKNIT = 'mknit'
export const CATEGORY_MOUTER = 'mouter'
export const CATEGORY_MSHOES = 'mshoes'
export const CATEGORY_MWALLET = 'mwallet'
export const CATEGORY_MBAG = 'mbag'

// fabric colors dictionary
export const FABRIC_COLORS = {
  red: '#E03E3E',
  pink: '#F0A4BD',
  beige: '#FAF0DC',
  orange: '#FF8400',
  yellow: '#E0D03E',
  black: '#000000',
  white: '#FFFFFF',
  green: '#3EE059',
  blue: '#3E60E0',
  purple: '#7F3EE0',
  brown: '#663300',
  pastel: 'linear-gradient(221deg, #8ff5d3 14%, #fdb4e3 80%)',
  grey: '#dddddd',
}

export const NEXT_BEST_MATCH_SCORE = 99.0;

export const ANALYTICS_GA_KEY = process.env.REACT_APP_GA_TRACKING_ID || process.env.ANALYTICS_GA_KEY || 'UA-120529562-2';
