import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { PRODUCT_COUNT_PER_PAGE } from './consts';

const client = {
  isAdmin: false,
  setIsAdmin: (isAdmin) => {
    client.isAdmin = !!isAdmin;
  },
  setBaseURL: (url) => {
    axios.defaults.baseURL = url;
  },
  brandsByCategories: async (categoryId, additionalParams = {}) => {
    const response = await axios.get(`/filters/brands/by_category/${categoryId}`, {
      params: {
        ...(additionalParams || {}),
        format: 'json',
      },
    });
    return camelcaseKeys(response.data[categoryId] || response.data, { deep: true });
  },
  pricesByCategories: async (categoryId, additionalParams) => {
    const response = await axios.get(`/filters/price/by_category/${categoryId}`, {
      params: {
        ...(additionalParams || {}),
        format: 'json',
      },
    });
    return camelcaseKeys(response.data[categoryId] || response.data, { deep: true });
  },
  product: async (productId) => {
    const response = await axios.get(`/products/${productId}`);
    return camelcaseKeys(response.data, { deep: true });
  },
  yml: async (productId, offset = 0, limit = 20) => {
    const response = await axios.get('/recommends/yml', {
      params: {
        productId,
        offset,
        limit,
        selected_product_id: productId,
      }
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  search: async (
    category,
    clientCategory,
    rawParams = {},
    sort,
    offset,
    limit = PRODUCT_COUNT_PER_PAGE,
    additionalParams,
    requestConfig,
    occasion,
  ) => {
    const params = Object.entries(rawParams).reduce((params, [key, value]) => ({
      ...params,
      [key]: Array.isArray(value) ? value.join(',') : value,
    }), {});

    if (params.brands) {
      params.brands_filter = params.brands;
      delete params.brands;
    }
    if (params.price) {
      params.price_filter = params.price;
      delete params.price;
    }
    if (occasion) {
      params.occasion = occasion;
    }

    try {
      const response = await axios.get(`/vf/`, {
        params: {
          category,
          clientCategory,
          limit,
          offset,
          sort,
          ...params,
          ...(client.isAdmin ? { admin: 1 } : {}),
          ...(additionalParams || {}),
        },
        ...requestConfig,
      });
      return camelcaseKeys(response.data, { deep: true });
    } catch (e) {
      console.log('Error!', e);
      throw e;
    }
  }
}

export default client;
