import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CategoryDropdown from 'components/CategoryDropdown';
import Footer from 'components/Footer';
import TooltipSwitch from 'components/TooltipSwitch';
import SortDropdown from 'components/SortDropdown';
// import Product from 'modules/review/Product';
import ProductsList from './ProductsList';

import Sidebar from './Sidebar';

import {
  categories as defaultCategories,
  paternsFilterParams as defaultPaternsFilterParams,
  materialsFilterParams as defaultMaterialsFilterParams
} from 'config/vf-config.js';
import { USE_TOOLTIP } from 'config/consts';

export default ({
  categories = defaultCategories,
  paternsFilterParams = defaultPaternsFilterParams,
  materialsFilterParams = defaultMaterialsFilterParams,
  searchAdditionalParams = {},
}) => {
  const classes = useStyles();
  const [isTooltipActive, setIsTooltipActive] = useState(USE_TOOLTIP);
  const categoriesKeys = Object.keys(categories);
  const [categoryId, setCategoryId] = useState(categoriesKeys[0]);
  const [searchResult, setSearchResult] = useState(null);
  const [sort, setSort] = useState(null);
  const productsContainer = useRef();

  return (
    <div className={classes.root}>
      {/* {(isCategoriesLoading || isProductsLoading) && <Loading />} */}
      <div className={classes.header}>
        <CategoryDropdown
          className={classes.categoryContainer}
          selected={categoryId}
          onChange={(categoryId) => setCategoryId(categoryId)}
          categories={categories}
        />

        <div className={classes.sortContainer}>
          <SortDropdown
            sort={sort}
            onChange={(value) => setSort(value)}
          />
        </div>

        {USE_TOOLTIP && <div className={classes.switchContainer}>
          <TooltipSwitch
            checked={isTooltipActive}
            onChange={() => setIsTooltipActive(!isTooltipActive)}
          />
        </div>}
      </div>
      <div className={classes.mainContainer}>
        <div id="visualFilter" className={classes.visualFilter}>
          <Sidebar
            categoryId={categoryId}
            sort={sort}
            onSearchFinished={(searchResult) => {
              setSearchResult(searchResult);
              if (productsContainer.current)
                productsContainer.current.scrollTo(0, 0);
            }}
            {...{
              categories,
              paternsFilterParams,
              materialsFilterParams,
              searchAdditionalParams,
            }}
          />
        </div>
        <div className={classes.resultController} ref={productsContainer}>
          <div className={classes.resultList}>
            {
              searchResult
              &&
              <ProductsList
                isTooltipActive={isTooltipActive}
                products={searchResult.results}
                counts={searchResult.counts}
              />
            }
            {/* {searchResult && searchResult.results.map((product) => (
              <Product
                className={classes.product}
                key={product.index}
                product={product}
                isTooltipActive={isTooltipActive}
                backUrl={`${location.pathname}${location.search}`}
              />
            ))} */}
          </div>
          <div id="visual-filter-pagination" />
          <Footer />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 65px)",
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '10px 35px 20px 0',
    backgroundColor: '#ffffff',
    position: 'relative',
    borderBottom: '1px solid #eae9ea',
  },
  categoryContainer: {
    width: 230,
  },
  sortContainer: {
    flex: 1,
  },
  switchContainer: {
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#009688',
    },
    '& .Mui-checked': {
      color: '#009688',
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    flex: 1,
  },
  visualFilter: {
    width: 368,
    borderRight: '1px solid #eae9ea',
    height: 'calc(100vh - 65px - 65px)',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  resultController: {
    flex: 1,
    height: 'calc(100vh - 65px - 65px)',
    overflow: 'hidden',
    overflowY: 'scroll',
    padding: 15,
  },
  resultList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  product: {
    marginBottom: 25,
  },
});
