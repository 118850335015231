import React, { Component } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import lodashFilter from 'lodash/filter'

// core-redux
import {
  setDashboardActive,
  setDashboardSettings,
  fetchPublishPreset,
  setActivePreset,
  fetchRemovePreset,
  fetchUnPublishPreset
} from '@yesplz/core-redux/ducks/admin/presets'
import { setBugReportSetting } from '@yesplz/core-redux/ducks/admin/report'

// core-web
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider'
import Transition from '@yesplz/core-web/ui-kits/transitions/Transition'
import history from '@yesplz/core-web/config/history'

// desktop
import MenuNavigation from 'modules/menus/MenuNavigation'
import SideBarOfDashboard from './SideBarOfDashboard'
import EmptyContent from 'ui-kits/empties'
import ModalBG from 'assets/images/publish-modal-bg.png'
import ModalFG from 'assets/images/publish-modal-fg.png'
import AdvancedPreset from './AdvancedPreset'
import './presetsDashboard.scss'

class PresetsDashboard extends Component {
  static propTypes = {
    adminPresets: PropTypes.array,
    setDashboardActive: PropTypes.func.isRequired,
    setDashboardSettings: PropTypes.func.isRequired,
    fetchPublishPreset: PropTypes.func,
    fetchUnPublishPreset: PropTypes.func,
    setActivePreset: PropTypes.func,
    fetchRemovePreset: PropTypes.func,
    setBugReportSetting: PropTypes.func,
    setting: PropTypes.object,
    activeTab: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      defaultColType: true,
      filters: {},
      filterPresets: {},
      hoverYes: false,
      openModal: false,
      selectedPreset: null
    }
  }

  get menuNavigationOptions () {
    return [
      {
        title: 'Published',
        isActived: true,
        key: 'published'
      },
      {
        title: 'All Styles',
        isActived: false,
        key: 'all'
      }
    ].map(item => ({
      ...item,
      isActived: this.props.activeTab === item.key
    }))
  }

  handleClickMenuNavigation = item => {
    const { setDashboardActive } = this.props
    const { key } = item
    setDashboardActive(key)
  }

  handleSubmitFilter = (filters) => {
    const { setDashboardSettings, activeTab } = this.props
    this.setState({ filters })

    setDashboardSettings(activeTab, { ...filters })
  }

  get presetsSorted () {
    const { adminPresets, setting, activeTab } = this.props
    const {
      types
    } = setting[activeTab]

    let presets = [ ...adminPresets ]
    if (activeTab === 'published') {
      presets = lodashFilter(adminPresets, preset => preset.published)
    }

    return presets
      .filter(product => isEmpty(types) || includes(types, product.category))
  }

  handleEditPreset = preset => () => {
    const { setActivePreset } = this.props
    setActivePreset(preset)
    history.push(`/presets/settings/${preset.category}`)
  }

  handlePublishPreset = preset => () => {
    this.setState({ openModal: true, selectedPreset: preset })
  }

  handlePublish = () => {
    const { fetchPublishPreset, fetchUnPublishPreset } = this.props
    const { selectedPreset } = this.state
    if (selectedPreset.published) {
      fetchUnPublishPreset(selectedPreset.id)
    } else {
      fetchPublishPreset(selectedPreset.id)
    }
    this.setState({ openModal: false, selectedPreset: null })
  }

  handleCloseModal = () => {
    this.setState({ openModal: false, selectedPreset: null })
  }

  handleRemovePreset = presetId => () => {
    const { fetchRemovePreset } = this.props
    fetchRemovePreset(presetId)
  }

  hoverYes = status => () => {
    this.setState({ hoverYes: status })
  }

  handleBugReport = () => {
    const { setBugReportSetting } = this.props
    setBugReportSetting(true)
  }

  render () {
    const { setting, activeTab } = this.props
    const { hoverYes, openModal, selectedPreset } = this.state
    return (
      <React.Fragment>
        {openModal && (
          <div className='confirmPublishModal'>
            <div className='overlay' onClick={this.handleCloseModal} />
            <div className='modalContainer'>
              <div className='image-container' style={{backgroundImage: `url(${ModalBG})`}}>
                <img className={`${hoverYes && 'hoverYes'}`} src={ModalFG} alt='' />
              </div>
              <div className='rightContainer'>
                <h1>
                  {selectedPreset && selectedPreset.published
                    ? 'Are you sure to unpublish this curation?'
                    : 'Are you sure to publish this curation?'
                  }
                </h1>
                <div className='eventContainer'>
                  <button
                    className={`confirmbtn ${hoverYes && 'hoverYes'}`}
                    onMouseOver={this.hoverYes(true)}
                    onMouseOut={this.hoverYes(false)}
                    onClick={this.handlePublish}
                  >Yes</button>
                  <button className='confirmbtn' onClick={this.handleCloseModal}>No</button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='FavoriteContent'>
          <div className='left-menu'>
            <MenuNavigation
              menu={this.menuNavigationOptions}
              onClickMenuItem={this.handleClickMenuNavigation}
            />
            <div className='content-menu'>
              <SideBarOfDashboard onSubmit={this.handleSubmitFilter} defaultFilters={setting[activeTab]} />
            </div>
          </div>
          <div className='Favorites'>
            {
              this.presetsSorted.length > 0 ? (
                <div className='content-preset'>
                  <Transition show transition='fadeInUp'>
                    {
                      this.presetsSorted.map((preset) => {
                        return (
                          <AdvancedPreset
                            key={`${preset.name}${preset.id}`}
                            id={`${camelCase(preset.name)}${preset.id}`}
                            preset={{...preset, name: `${preset.name}${preset.createdOn ? `-${preset.createdOn}` : ''}`}}
                            onClick={() => {}}
                            onClickGroupTitle={() => {}}
                            onToggleLike={() => {}}
                            onClickEditPreset={this.handleEditPreset(preset)}
                            onClickPublishPreset={this.handlePublishPreset(preset)}
                            onClickRemovePreset={this.handleRemovePreset(preset.id)}
                            presetMatchesCount={4}
                            useMinimalPreset
                            activeCategory={preset.category}
                            hidePreset={false}
                            showOriginalPrice
                            adminPreset
                            defaultViewBoxSvg={[0, 0, 304, 214]}
                            handleBugReport={this.handleBugReport}
                          />
                        )
                      })
                    }
                  </Transition>
                </div>

              ) : (
                <EmptyContent page='Presets-Dashboard' />
              )
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => ({
  adminPresets: state.AdminPresets.presets || [],
  setting: state.AdminPresets.setting,
  activeTab: state.AdminPresets.setting.active
})

export default compose(
  connect(
    mapStateToProps,
    {
      setDashboardActive,
      setDashboardSettings,
      fetchPublishPreset,
      setActivePreset,
      fetchRemovePreset,
      fetchUnPublishPreset,
      setBugReportSetting
    }
  ),
  withTrackingProvider('PresetsDashboard')
)(PresetsDashboard)
