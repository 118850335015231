import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store from './config/store'
import history from '@yesplz/core-web/config/history'
import routes from './config/routes'
import ScrollToTop from './ScrollToTop'

// import global styles
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@yesplz/core-web/assets/css/animations.css'
import '@yesplz/core-web/assets/css/reset.css'
import 'assets/css/overrides.css'
import 'app.css'
import UserProvider from './components/UserProvider'

const App = () => (
  <Provider store={store}>
    <UserProvider>
      <ConnectedRouter
        history={history}
        basename={process.env.PUBLIC_URL}
      >
        <ScrollToTop>{routes}</ScrollToTop>
      </ConnectedRouter>
    </UserProvider>
  </Provider>
)

export default App
