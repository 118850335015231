import React from 'react'
import PropTypes from 'prop-types'
import ComingSoonSVG from '@yesplz/core-web/assets/svg/fogg-message-sent.svg'
import './ComingSoon.css'

const ComingSoon = ({ functionName }) => {
  return (
    <div className='Comming-Soon'>
      <img src={ComingSoonSVG} alt='' />
      <p>{functionName}</p>
      <p>is coming soon</p>
    </div>
  )
}

ComingSoon.propTypes = {
  functionName: PropTypes.string
}

ComingSoon.defaultProps = {
  functionName: 'Other Filters'
}

export default ComingSoon
