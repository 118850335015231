import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE } from '@yesplz/core-web/config/constants';
import qs from 'qs';
import Sticky from 'react-sticky-el';
import Loading from 'components/Loading';
import CategoryDropdown from 'components/CategoryDropdown';
import SortDropdown from 'components/SortDropdown';
import Pagination from 'components/Pagination';
import Footer from 'components/Footer';
import TooltipSwitch from 'components/TooltipSwitch';
import SearchBar from 'modules/review/SearchBar';

// core-redux
import { fetchCategories } from '@yesplz/core-redux/ducks/admin/auth';
import { fetchProducts, fetchStyleSuggestions } from '@yesplz/core-redux/ducks/admin/review';

import ProductRow from 'modules/review/ProductRow';

export default ({ match, history, location }) => {
  const classes = useStyles();
  const rootContainer = useRef();
  const { params: { category } } = match;
  const dispatch = useDispatch();
  const { categories } = useSelector(({ AdminAuth }) => AdminAuth);
  const { loading, products, count } = useSelector(({ AdminReview }) => AdminReview);
  const [isTooltipActive, setIsTooltipActive] = useState(true);

  let searchParams = location.search ? qs.parse(location.search.slice(1)) : {};
  const page = searchParams.page ? parseInt(searchParams.page, 10) : 1;
  const search = searchParams.search;
  const sort = searchParams.sort;
  const duration = searchParams.duration ? parseInt(searchParams.duration, 10) : null;

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    fetch();
    rootContainer.current.scrollTo(0, 0);
  }, [category, search, sort, duration, page]);

  const fetch = () => {
    dispatch(fetchProducts(category, search, sort, duration, page));
  }

  function setSearchParams(searchParams) {
    const searchQuery = qs.stringify(
      searchParams,
      { skipNulls: true }
    );
    history.push(`/style-suggestions/${category}?${searchQuery}`);
  }

  const handleSearch = (search) => {
    setSearchParams({
      search,
    });
  }

  const handleCategoryChange = (category) => {
    history.push(`/style-suggestions/${category || ''}`);
  }

  const handleSortChange = (sort) => {
    setSearchParams({ sort });
  }

  return (
    <div id="MainScroll" className={classes.root} ref={rootContainer}>
      {loading && <Loading />}
      <Sticky
        scrollElement="#MainScroll"
        stickyClassName={classes.sticky}
      >
        <div className={classes.header}>
          <div className={classes.searchRoot}>
            <SearchBar
              search={search}
              onSearch={handleSearch}
              onRandomClick={() => {
                if (sort === 'random') {
                  fetch();
                }
                else {
                  handleSortChange('random');
                }
              }}
            />
          </div>

          <CategoryDropdown
            selected={category}
            categories={categories}
            onChange={handleCategoryChange}
          />

          <div className={classes.sortRoot}>
            <SortDropdown
              sort={sort}
              duration={duration}
              onChange={handleSortChange}
            />
          </div>

          <div className={classes.switchRoot}>
            <TooltipSwitch
              checked={isTooltipActive}
              onChange={() => setIsTooltipActive(!isTooltipActive)}
            />
          </div>
        </div>
      </Sticky>
      <div classame="Review-Products">
        {products.map((product) => (
          <ProductRow
            key={product.productId}
            product={product}
            isTooltipActive={isTooltipActive}
            searchParams={{ category, page, search, sort }}
            backUrl={`${location.pathname}${location.search}`}
            onVisible={() => dispatch(fetchStyleSuggestions(product.productId))}
            slidesPerPage={5}
            listSource='stylewith'
          />
        ))}
        <Pagination
          page={page - 1}
          pageSize={ADMIN_REVIEW_PRODUCT_COUNT_PER_PAGE}
          productCount={count}
        />
      </div>
      <Footer />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 65px)",
    overflow: "hidden",
    overflowY: "scroll",
  },
  sticky: {
    zIndex: 1000,
    boxShadow: '0 10px 8px 0 rgba(0, 0, 0, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 35px 20px 0',
    backgroundColor: '#ffffff',
    position: 'relative',
  },
  searchRoot: {
    width: '270px',
    marginLeft: '22px',
  },
  searchInput: {
    marginLeft: 8,
    flex: 1,
  },
  categoryRoot: {
    width: '200px',
    marginLeft: '75px',
    marginRight: '35px',
  },
  switchRoot: {},
  categorySelect: {
    '&:before': {
      borderBottom: 0,
    },
  },
  sortRoot: {
    width: '285px',
    display: 'flex',
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
});
