import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
import { BASE_IMG_PATH, NEXT_BEST_MATCH_SCORE } from '../consts';
import { store } from '../store';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

const nothingFoundTemplate = `
<h2>Nothing found!</h2>
`;

const itemTemplate = `
<a href="{{srcUrl}}" target="{{linkTarget}}" class="ProductGrid">
  <div class="ProductGrid-thumbnail">
    <img src="{{frontImg}}" alt="{{brand}}" />
  </div>
  <div class="ProductGrid-detail">
    <h5>{{name}}</h5>
    {{{priceHtml}}}
  </div>
</a>
`;

const itemPriceTemplate = `<div class="ProductGrid-price">\${{price}}</div>`;
const itemDiscountPriceTemplate = `
<div class="ProductGrid-price sale">\${{price}}</div>
<div class="ProductGrid-originalPrice">\${{originalPrice}}</div>
`;

const nextBestMatchingTitleTemplate = `
<h2>We couldn’t find the exact matches, but these items might interest you.</h2>
`;

class ProductsList extends Widget {
  defaultParams = {
    imgBaseURL: BASE_IMG_PATH,
    containerClassName: 'ProductList-wrapper',
    rootTag: 'div',
    scrollContainer: window,
    productUrl: null,
    productLinkTarget: '_self',
    /**
     * Available options are:
     * - perRow: @number
     * - className: @string
     */
    row: false,
    templates: {
      item: itemTemplate,
      price: itemPriceTemplate,
      discountPrice: itemDiscountPriceTemplate,
      nextBestMatchingTitle: nextBestMatchingTitleTemplate,
      nothingFound: nothingFoundTemplate,
    }
  };

  constructor(params) {
    super(params);

    const element = document.createElement(this.params.rootTag);
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  didMount() {
    this.itemTemplate = Handlebars.compile(this.params.templates.item);
    this.itemPriceTemplate = Handlebars.compile(this.params.templates.price);
    this.itemDiscountPriceTemplate = Handlebars.compile(
      this.params.templates.discountPrice
    );
    this.nextBestMatchingTitleTemplate = Handlebars.compile(
      this.params.templates.nextBestMatchingTitle
    );
    this.nothingFoundTemplate = Handlebars.compile(this.params.templates.nothingFound);

    this.scrollContainer = (
      typeof this.params.scrollContainer === 'string'
        ? document.querySelector(this.params.scrollContainer)
        : this.params.scrollContainer
    );

    this.search = store.getState().search;
    store.subscribe(() => {
      this.search = store.getState().search;
      this.update();
    });
  }

  getProductUrl(product) {
    if (typeof this.params.productUrl === 'function') {
      return this.params.productUrl(product);
    }
    return product.productUrl;
  }

  renderItem(item) {
    const element = document.createElement('div');

    const discountValue = (
      item.salePrice !== item.originalPrice
        ? 100 - Math.ceil((item.salePrice / item.originalPrice) * 100)
        : ''
    );

    const priceHtml = (
      item.originalPrice && item.salePrice !== item.originalPrice
        ? this.itemDiscountPriceTemplate({
            price: item.salePrice,
            originalPrice: item.originalPrice,
            discountValue,
          })
        : item.salePrice
          ? this.itemPriceTemplate({ price: item.salePrice })
          : ''
    );

    element.innerHTML = this.itemTemplate({
      srcUrl: this.getProductUrl(item),
      linkTarget: this.params.productLinkTarget,
      frontImg: (
        item.frontImgSrc
          ? item.frontImgSrc
          : item.frontImg
            ? `${this.params.imgBaseURL}/${item.frontImg}`
            : ''
      ),
      brand: item.brand,
      name: item.productName,
      priceHtml,
    });

    element.firstElementChild.addEventListener('click', () => {
      EventEmitter.emit('sfListProductClick', item);
    });

    return element.firstElementChild;
  }

  update() {
    if (this.scrollContainer) {
      this.scrollContainer.scrollTo(0, 0);
    }

    this.mainElement.innerHTML = '';
    if (this.search.results && this.search.results.length > 0) {
      let prevItem = null;
      let currentRow = null;
      this.search.results.forEach((item, index) => {
        if (
          (!prevItem || prevItem.score >= NEXT_BEST_MATCH_SCORE)
          &&
          item.score < NEXT_BEST_MATCH_SCORE
        ) {
          this.mainElement.insertAdjacentHTML(
            'beforeend',
            this.nextBestMatchingTitleTemplate()
          );
        }

        if (this.params.row && index % this.params.row.perRow === 0) {
          currentRow = document.createElement('DIV');
          this.mainElement.appendChild(currentRow);
          if (this.params.row.className)
            currentRow.className = this.params.row.className;
        }

        const container = this.params.row ? currentRow : this.mainElement;
        const element = this.renderItem(item.product);
        container.appendChild(element);

        prevItem = item;
      });
    }
    else {
      this.mainElement.innerHTML = this.nothingFoundTemplate();
    }
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new ProductsList(params);
};
