import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Product from 'modules/review/Product';
import styles from './ProductsList.module.scss';

const NEXT_BEST_MATCH_SCORE = 99.0;

export default function ProductsList({ isTooltipActive, products, counts }) {
  const location = useLocation();

  const renderProduct = (product) => (
    <Product
      key={product.product.id}
      product={product}
      isTooltipActive={isTooltipActive}
      backUrl={`${location.pathname}${location.search}`}
    />
  );

  if (!products) return null;

  const bestMatches = (products || [])
    .filter(product => product.score > NEXT_BEST_MATCH_SCORE);

  const nextMatches = (products || [])
    .filter(product => product.score < NEXT_BEST_MATCH_SCORE);

  let bestMatchesCount = 0;
  let nextMatchesCount = 0;
  if (counts.bestMatch)
    bestMatchesCount = counts.bestMatch;
  if (counts.total) {
    nextMatchesCount = counts.total - counts.bestMatch;
  }

  return (
    <div className={styles['root']}>
      {
        bestMatches.length > 0
        &&
        <>
          <h4>
            Top Search Results
          </h4>
          <p>{bestMatchesCount} items found</p>
          <div className={styles['products']}>
            {bestMatches.map(renderProduct)}
          </div>
        </>
      }
      {
        nextMatches.length > 0
        &&
        <>
          <h4
            className={classNames({
              [styles['next-matches']]: bestMatches.length > 0,
            })}
          >
            {bestMatches.length === 0 && <>Sorry, no search results are found. <br /></>}
            How about the next best matches?
          </h4>
          <p className='text-gray-400'>{nextMatchesCount} items found</p>
          <div className={styles['products']}>
            {nextMatches.map(renderProduct)}
          </div>
        </>
      }
    </div>
  );
}
