import { createAction } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import client from '@yesplz/client';
import EventEmitter from '../../modules/EventEmitter';
import Hooks from '../../modules/Hooks';

const prefix = '@search/';

export const searchResultsPending = createAction(`${prefix}SEARCH_RESULTS_PENDING`);
export const searchResultsSuccess = createAction(`${prefix}SEARCH_RESULTS_SUCCESS`);
export const searchResultsError = createAction(`${prefix}SEARCH_RESULTS_ERROR`);

function applyTnValues(category, params) {
  const updatedParams = { ...params };

  if (!category.tn) return updatedParams;

  for (const tnName in params) {
    if (Object.hasOwnProperty.call(category.tn, tnName)) {
      const tn = category.tn[tnName];
      if (
        Object.hasOwnProperty.call(tn, params[tnName])
        &&
        Object.hasOwnProperty.call(tn[params[tnName]], 'value')
      ) {
        updatedParams[tnName] = tn[params[tnName]].value;
      }
    }
  }

  return updatedParams;
}

export const runSearch = (categories) => async (dispatch, getState) => {
  dispatch(searchResultsPending());
  const { filter, config } = getState();
  const category = categories[filter.categoryId];

  try {
    const params = {
      ...applyTnValues(category, filter.params),
      editorspick: filter.presetIndex,
    };

    EventEmitter.emit('searchStarted', Hooks.call('events.searchStarted', {
      category: filter.category,
      categoryId: filter.categoryId,
      sort: filter.sort,
      limit: filter.limit,
      offset: filter.offset,
      presetIndex: filter.presetIndex,
      occasion: filter.occasion,
      params,
      additionalParams: config.searchAdditionalParams,
      bodyPart: filter.bodyPart,
    }));

    if (!config.useSearch) return; // TODO: Revise position. Changed because Favorite page on playground. Error When there are more then 1 saved

    let data = await client.search(
      filter.category,
      filter.categoryId,
      params,
      filter.sort,
      filter.offset,
      filter.limit,
      config.searchAdditionalParams,
      config.requestConfig,
      filter.occasion,
    );

    dispatch(searchResultsSuccess({
      ...data,
    }));

    EventEmitter.emit(
      'searchFinished',
      {
        count: data.count,
        counts: data.counts,
        offset: filter.offset,
        limit: filter.limit,
        results: (
          config.searchFinishedReturnFields
          ? data.results.map(p => ({
              ...(pick(p.product, config.searchFinishedReturnFields)),
              ...(
                config.searchFinishedReturnFields.includes('score')
                  ? { score: p.score }
                  : {}
              ),
            }))
          : data.results
        ),
      }
    );
  }
  catch(error) {
    console.error(error);
    dispatch(searchResultsError({ error: error.message }));
  }
}
