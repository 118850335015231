import React from 'react'
import { connect } from 'react-redux'

import ProductDetail from './ProductDetail'
import ProductPrediction from './ProductPrediction'
import ProductList from 'modules/products/ProductList'

import './ProductPage.scss'

class ProductPage extends React.Component {
  render () {
    const { recommends } = this.props

    return (
      <div className='Product-Page'>
        <div className='main-content-container'>
          <div className='detail-container'>
            <ProductDetail
              handleClickProduct={() => {}}
              isProductOnly
            />
          </div>
          <div className='pred-container'>
            <ProductPrediction />
          </div>
        </div>
        <div className='product-list-container'>
          <ProductList
            products={recommends}
            handleClickProduct={() => {}}
            elementsInLine={5}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => (
  {
    recommends: state.AdminProducts.recommendedProducts
  }
)

export default connect(mapStateToProps)(ProductPage)
