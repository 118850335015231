import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// core redux
import { setBugReportSetting } from '@yesplz/core-redux/ducks/admin/report'

// core web
import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants'
import BugReportIcon from '@yesplz/core-web/assets/svg/fav-item-selected.svg'
import './Product.scss'

class AdminProduct extends React.Component {
  isSaledProduct = () => {
    const { product } = this.props
    if (product.original_price && product.price < product.original_price) {
      return true
    }

    return false
  }

  handleBugReport = () => {
    const { setBugReportSetting } = this.props
    setBugReportSetting(true)
  }

  render () {
    const { product, handleClickProduct } = this.props
    let tooltip = product.score ? 'score: ' + product.score.toFixed(1) + '\n' : '';
    tooltip += product.score_info + '\n';
    tooltip += product.product.text_predictions + '\n';
    tooltip += product.product.raw_predictions;

    return (
      <div className='product-container'>
        <div className='product-image' onClick={handleClickProduct} title={tooltip}>
          <img src={product.product.front_img} className='front-image' alt='' />
          <div className='bug-report-icon' onClick={this.handleBugReport}>
            <img src={BugReportIcon} alt='' />
          </div>
        </div>
        <div className='product-detail'>
          <p className='product-id'>{product.product.productId}</p>
          <p className='product-name'>{product.product.productName}</p>
          <p className='product-brand'>{product.product.brandName}</p>
          {/* <div className='product-price'>
            {this.isSaledProduct() && <span className='original'>{product.original_price}</span>}
            <span>{product.price}</span>
          </div> */}
        </div>
      </div>
    )
  }
}

AdminProduct.propTypes = {
  product: PropTypes.object,
  handleClickProduct: PropTypes.func,
  setBugReportSetting: PropTypes.func
}

export default connect(
  null,
  {
    setBugReportSetting
  }
)(AdminProduct)
