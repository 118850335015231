import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// core redux
import { setBugReportSetting } from '@yesplz/core-redux/ducks/admin/report'
import {
  fetchProductById
} from '@yesplz/core-redux/ducks/admin/products'
// core web
import history from '@yesplz/core-web/config/history'
import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants'
import BugReportIcon from '@yesplz/core-web/assets/svg/fav-item-selected.svg'
import './Product.scss'

class PresetProductGrid extends React.Component {
  isSaledProduct = () => {
    const { product } = this.props
    if (product.original_price && product.price < product.original_price) {
      return true
    }

    return false
  }

  goToProductDetail = productId => () => {
    const { category, fetchProductById } = this.props
    fetchProductById(category, productId)
    history.push(`/product/${productId}`)
  }

  handleBugReport = () => {
    const { setBugReportSetting } = this.props
    setBugReportSetting(true)
  }

  render () {
    const { product } = this.props
    let preds = JSON.stringify(product.preds, undefined, 2).replace(/[{},"]/g, '')
    // preds = preds.replace(/\n\n/g,'')
    return (
      <div className='product-container' title={`${product.product_id}\n${product.extra_info}\n${preds}`}>
        <div className='product-image'>
          <img src={product.front_img} onClick={this.goToProductDetail(product.product_id)} className='front-image' alt='' />
          <div className='bug-report-icon' onClick={this.handleBugReport}>
            <img src={BugReportIcon} alt='' />
          </div>
        </div>
        <div className='product-detail' onClick={this.goToProductDetail(product.product_id)}>
          <p className='product-brand'>{product.brand}</p>
          <div className='product-price'>
            {this.isSaledProduct() && <span className='original'>{product.original_price}</span>}
            <span>{product.price}</span>
          </div>
        </div>
      </div>
    )
  }
}

PresetProductGrid.propTypes = {
  product: PropTypes.object,
  category: PropTypes.string,
  fetchProductById: PropTypes.func,
  setBugReportSetting: PropTypes.func
}

export default connect(
  null,
  {
    setBugReportSetting,
    fetchProductById
  }
)(PresetProductGrid)
