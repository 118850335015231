import Handlebars from 'handlebars';
import pick from 'lodash/pick';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

const persistentParams = ['brands', 'price'];

const TEMPLATE = `
<ul>
  <li data-preset-id="defaultValue">
    All
  </li>
  {{#each presets}}
    <li data-preset-id="{{this.id}}">
      {{this.label}}
    </li>
  {{/each}}
</ul>
`

class SimplePresetsList extends Widget {
  defaultParams = {
    containerClassName: 'simple-presets-list',
    showGenderTitles: true,
    template: TEMPLATE,
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(this.params.template);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.prevPresetName = null;
  }

  didMount() {
    this.initClicks();
  }

  didUpdate(prevState) {
    const { categoryId: prevCategoryId } = prevState.filter;
    const { categoryId } = this.state.filter;
    if (categoryId !== prevCategoryId) {
      this.render();
      this.initClicks();
    }
    this.updateSelected();
  }

  initClicks() {
    const { categoryId } = this.state.filter;
    const presetElements = this.mainElement.querySelectorAll('[data-preset-id]');
    presetElements.forEach(presetElement => {
      presetElement.addEventListener('click', () => {
        const presetName = presetElement.getAttribute('data-preset-id');
        this.setPreset(presetName.replace(`${categoryId}-`, ''));
      });
    });
  }

  setPreset(presetName) {
    const { categoryId, params } = this.state.filter;
    const presets = {
      ...this.main.categories[categoryId].presetList,
      defaultValue: this.main.categories[categoryId].defaultVal,
    };

    EventEmitter.emit('presetClick', {
      categoryId,
      preset: presetName,
    });

    this.setFilter({
      presetIndex: presetName !== 'defaultValue' ? presetName : null,
      ...(
        presets[presetName] ? {
          params: {
            ...pick(params, persistentParams),
            ...presets[presetName],
          }
        } : {}
      ),
    });
  }

  updateSelected() {
    const { presetIndex } = this.state.filter;
    if (this.prevPresetName === presetIndex) return;

    if (this.prevPresetName) {
      this.toggle(this.prevPresetName);
    }

    this.toggle(presetIndex);

    this.prevPresetName = presetIndex;
  }

  toggle(presetIndex) {
    const { categoryId } = this.state.filter;
    const presetElement = this.mainElement.querySelector(`[data-preset-id="${categoryId}-${presetIndex}"]`);
    if (presetElement) {
      presetElement.classList.toggle('is-selected');
    }
  }

  render() {
    const { categoryId } = this.state.filter;
    const presets = (
      this.main.categories[categoryId].presetList
        ? Object.keys(this.main.categories[categoryId].presetList)
            .map(p => ({
              id: `${categoryId}-${p}`,
              label: p,
            }))
        : []
    );

    this.mainElement.innerHTML = this.template({
      presets,
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(presets);
    }

    return this.mainElement;
  }
}

export default (params) => {
  return new SimplePresetsList(params);
};
