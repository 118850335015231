import pick from 'lodash/pick';
import { getCatCfg } from './VFCatCfg';

class VfCatViewData {
  catcfg = null
  viewBoxWithNoThumnbnail = [0, 0, 250, 250]
  currentPreset = null
  extraBodyGroups = [ ]
  svgSource = null

  constructor (vfcatcfg) {
    this.catcfg = vfcatcfg
    this.curViewBox = null
    this.currentPropState = vfcatcfg.propDefaultVal
    this.presetList = vfcatcfg.presetList
    this.svgSource = vfcatcfg.svgSource
  }

  sanitizeFilters (filters) {
    let filterSettings = pick(filters, this.propList())
    // For some cases, interval value needs to be added.
    // And it is defined in defaultVal
    let propsFromDefaults = pick(filters, Object.keys(this.catcfg.propDefaultVal))

    return {...filterSettings, ...propsFromDefaults}
  }

  sanitizePropChange (filters, prop, new_value) {
    if (this.catcfg.sanitizePropChange) {
      this.catcfg.sanitizePropChange(filters, prop, new_value);
      return;
    }
    // Pass through by default
    filters[prop] = new_value
  }

  changePropSelection (prop, sel) {
    this.sanitizePropChange(this.currentPropState, prop, sel)
  }

  setDefaultState (filters) {
    this.currentPropState = this.sanitizeFilters(filters)
  }

  viewBox () {
    return this.viewBoxWithNoThumnbnail
  }

  setViewBox (viewBox) {
    this.curViewBox = viewBox
  }

  propCount (prop) {
    return this.catcfg.maxVal(prop) + 1
  }

  getMaxSelectionIndx (prop) {
    return this.catcfg.maxVal(prop)
  }

  propList () {
    return this.catcfg.partList
  }
  prevProp (prop) {
    const currentPropIndex = this.propList().indexOf(prop)
    const nextPropIndex = currentPropIndex > 0 ? currentPropIndex - 1 : this.propList().length - 1
    return this.propList()[nextPropIndex]
  }
  nextProp (prop) {
    const currentPropIndex = this.propList().indexOf(prop)
    const nextPropIndex = currentPropIndex < this.propList().length - 1 ? currentPropIndex + 1 : 0
    return this.propList()[nextPropIndex]
  }
  nextPreset (backward) {
    if (this.currentPreset === null) {
      this.currentPreset = 0
    }
    this.currentPreset += backward ? -1 : 1
    this.currentPreset = (this.currentPreset + this.presetList.length) % this.presetList.length
    return this.presetList[this.currentPreset]
  }

  overrideBodyPartGroupName (state, prop, val) {
    if (this.catcfg.overrideBodyPartGroupName) {
      return this.catcfg.overrideBodyPartGroupName(state, prop, val)
    }
    return null
  }

  getBodyPartGroupName (prop, propState = null) {
    let state = propState || this.currentPropState
    const override = this.overrideBodyPartGroupName(state, prop, state[prop])
    if (override) {
      return override
    } else {
      return prop + '_' + (Array.isArray(state[prop]) ? state[prop][state[prop].length - 1] : state[prop])
    }
  }

  getHoverGroupName (prop) {
    return this.getBodyPartGroupName(prop, this.catcfg.propMaxVal)
  }

  fullbodyGroupNames () {
    return [this.catcfg.category + '_mannequin']
      .concat(this.catcfg.extraBodyGroups || [])
  }

  touchGroupName (prop) {
    return prop + '_touch'
  }
  touchpointGroupName (bodypart) {
    if (this.catcfg.touchpointGroupName) {
      return this.catcfg.touchpointGroupName(bodypart);
    }
    return bodypart + '_point'
  }

  evaluateDisabledParts() {
    let disabledParts = null;
    if (this.catcfg.evaluateDisabledParts) {
      disabledParts = this.catcfg.evaluateDisabledParts(
        this.currentPropState
      );
    }
    return (
      Array.isArray(disabledParts)
        ? disabledParts
        : []
    );
  }
}

export function getCatData (categoryCfg) {
  const cfg = getCatCfg(categoryCfg);
  return new VfCatViewData(cfg);
}
