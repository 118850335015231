import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// libs
import PropTypes from 'prop-types'
import classnames from 'classnames'
import queryString from 'query-string'
import lodashFilter from 'lodash/filter'

// core web
import { SectionTitle } from '@yesplz/core-web/ui-kits/misc'
import ProductList from '../products/PresetProductList'
import { ListView } from '@yesplz/core-web/modules/filters'
import history from '@yesplz/core-web/config/history'

// core redux
import { fetchPresets, fetchNewProducts } from '@yesplz/core-redux/ducks/products'
import { fetchPresets as fetchPresetsEditorPicks, setFilter, syncFilter } from '@yesplz/core-redux/ducks/filters'
import { fetchCategories } from '@yesplz/core-redux/ducks/admin/auth'
import { fetchPresets as fetchAdminPresets, fetchAddPreset } from '@yesplz/core-redux/ducks/admin/presets'

// HOC
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider'

// constants
import {
  CATEGORY_WSHOES, CATEGORY_WTOP, CATEGORY_WPANTS, CATEGORY_MPANTS, CATEGORY_MSHIRTS, DEBUG_PARAM
} from '@yesplz/core-web/config/constants'

// utils
import { parsePresetName } from '@yesplz/core-web/utils/index'
import WTop from '@yesplz/core-web/assets/svg/vf-button-top-black.svg'
import WPants from '@yesplz/core-web/assets/svg/vf-button-pants-black.svg'
import WShoes from '@yesplz/core-web/assets/svg/vf-button-shoes-black.svg'
import SideBarOfSettings from './SideBarOfSettings'


import './presetsSettings.scss'

const categoryIcons = {
  [CATEGORY_WTOP]: WTop,
  [CATEGORY_WPANTS]: WPants,
  [CATEGORY_WSHOES]: WShoes,
  [CATEGORY_MPANTS]: WPants, // Use women's for now
  [CATEGORY_MSHIRTS]: WTop, // Use women's for now
}

const styles = theme => ({
  formControl: {
    padding: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  categoryFormControl: {
    padding: theme.spacing(1),
    minWidth: 120
  }
})

class PresetsSettings extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      defaultColType: false,
      menuItem: 'presets',
      openCategory: false
    }
  }

  componentDidMount () {
    const { syncFilter, fetchAdminPresets, setFilter, match, activePreset } = this.props
    if (activePreset && match.params.category === activePreset.category) {
      setFilter(match.params.category, activePreset)
    } else {
      syncFilter()
    }

    fetchCategories();
    fetchAdminPresets()
    this.handleFetchPresets()
    this.handleFetchProducts()
  }

  handleFetchPresets = () => {
    if (this.qsValues.preset) {
      const { fetchPresetsEditorPicks, fetchPresets } = this.props
      if (this.qsValues.page === 'editorspick') {
        fetchPresetsEditorPicks(this.currentCategory)
      } else {
        fetchPresets(this.currentCategory)
      }
    }
  }

  handleFetchProducts = () => {
    const { filters, fetchNewProducts } = this.props
    fetchNewProducts(this.currentCategory, filters, 60)
  }

  get qsValues () {
    const { location } = this.props
    const qsValues = queryString.parse(location.search)
    return qsValues
  }

  get productTitle () {
    const { location } = this.props
    const qsValues = queryString.parse(location.search)
    if (!qsValues.page) return 'ALL SEARCH RESULTS'
    switch (qsValues.page) {
      case 'new': {
        return 'ALL NEW ARRIVALS'
      }

      case 'editorspick': {
        return `ALL ${parsePresetName(qsValues.preset)}`
      }

      default: return 'ALL SEARCH RESULTS'
    }
  }

  get currentCategory () {
    const { match } = this.props
    return match.params.category || CATEGORY_WTOP
  }

  get isSaleOnly () {
    const { location } = this.props
    const qsValues = queryString.parse(location.search)

    if (qsValues.sale && qsValues.sale === '1') {
      return true
    }

    return false
  }

  // get customFilters () {
  //   const { location, presets } = this.props
  //   const qsValues = queryString.parse(location.search)
  //   let options = {
  //     new: qsValues.page === 'new' ? 1 : 0,
  //     preset: qsValues.preset ? qsValues.preset : null
  //   }
  //   if (qsValues.preset) {
  //     let presetExtra = presets.find(preset => formatPresetName(preset.name) === qsValues.preset)
  //     options = {
  //       ...options,
  //       ...(presetExtra ? omit(presetExtra, ['name', 'category']) : {})
  //     }
  //   }
  //   return options
  // }

  get currentPresets () {
    const { adminPresets, match } = this.props
    const activeCategory = match.params.category
    const presets = lodashFilter(adminPresets, preset => preset.category === activeCategory)

    return presets
  }

  onColTypeChange = data => {
    this.setState({
      defaultColType: data === 'single'
    })
  }

  handleFilterChange = filters => {
    const { match, setFilter } = this.props
    setFilter(match.params.category, filters)
    window.scrollTo(0, 0)
  }

  handleMenuChange = event => {
    const { match } = this.props
    this.setState({ [event.target.name]: event.target.value })
    if (event.target.value === 'ymal') {
      history.push(`/ymal/settings/${match.params.category}`)
    }
  }

  handleCategoryChange = event => {
    const category = event.target.value
    history.push(`/presets/settings/${category}`)
  }

  handleCloseCategory = () => {
    this.setState({ openCategory: false })
  }

  handleOpenCategory = () => {
    this.setState({ openCategory: true })
  }

  onCreateNewAdminPreset = (category, name) => {
    const { fetchAddPreset, filters } = this.props
    const newPreset = { category, name, ...filters }
    fetchAddPreset(newPreset)
    history.push('/presets/dashboard')
  }

  goToDashboard = () => {
    history.push(`/presets/dashboard`)
  }

  render () {
    const {
      match,
      filters,
      lastBodyPart,
      classes,
      categories
    } = this.props;
    filters[DEBUG_PARAM] = 1;

    return (
      <div id='MainScroll' className='ProductsPage'>
        <div className='ProductFilters'>
          <div>
            <FormControl className={`${classes.formControl} menuForm`}>
              <Select
                value={this.state.menuItem}
                onChange={this.handleMenuChange}
                inputProps={{
                  name: 'menuItem',
                  id: 'main-menu-item'
                }}
              >
                <MenuItem value='presets'>Presets</MenuItem>
                <MenuItem value='ymal'>You Might Also Like</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl className={`${classes.categoryFormControl} menuForm`}>
              <Select
                value={match.params.category}
                onChange={this.handleCategoryChange}
                open={this.state.openCategory}
                onClose={this.handleCloseCategory}
                onOpen={this.handleOpenCategory}
                inputProps={{
                  name: 'category',
                  id: 'main-menu-item'
                }}
              >
                {categories.map(item => (
                  <MenuItem
                    key={`category-${item.category}`}
                    value={item.category}
                    className='category-menu-item'
                  >
                    {this.state.openCategory && categoryIcons[item.category] && (
                      <div className='category-img-container'>
                        <img src={categoryIcons[item.category]} className='category-img' alt='' />
                      </div>
                    )}
                    {item.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <SideBarOfSettings
            category={match.params.category}
            filters={filters}
            onSaveFilter={this.onCreateNewAdminPreset}
            goToDashboard={this.goToDashboard}
            activePresets={this.currentPresets}
            lastBodyPart={lastBodyPart}
            onFilterChange={this.handleFilterChange}
          />
        </div>
        <div className='Desktop-section'>
          <div className='ProductsPage-HeaderBar'>
            <SectionTitle
              title={this.productTitle}
              withoutContainer
              small
            />
            <div className='GridView'>
              <ListView colType={this.state.defaultColType ? 'single' : 'double'} onChange={this.onColTypeChange} />
            </div>
          </div>
          <div className={classnames('ProductsPage-ProductList', {
            ColTypeDouble: !this.state.defaultColType,
            contained: match.params.category === CATEGORY_WSHOES
          })}>
            <ProductList
              category={match.params.category}
              customFilters={filters}
              location={match.location}
              className='ProductsPage-products'
              style={{ overflow: 'hidden' }}
              salesOnly={this.isSaleOnly}
              showOriginalPrice
              // showHighResImage
              show
              // combined
              useButton
            />
          </div>
        </div>
      </div>
    )
  }
}

PresetsSettings.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  presets: PropTypes.array.isRequired,
  fetchPresets: PropTypes.func.isRequired,
  fetchPresetsEditorPicks: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  syncFilter: PropTypes.func.isRequired,
  fetchNewProducts: PropTypes.func.isRequired,
  fetchAdminPresets: PropTypes.func,
  fetchAddPreset: PropTypes.func,
  filters: PropTypes.object,
  lastBodyPart: PropTypes.string,
  classes: PropTypes.object,
  categories: PropTypes.array,
  adminPresets: PropTypes.array,
  activePreset: PropTypes.object
}

const mapStateToProps = (state, props) => {
  const { location } = props
  const qsValues = queryString.parse(location.search)
  const activeCategory = props.match.params.category
  return {
    presets: qsValues.page === 'editorspick' ? state.filters.presets : state.products[activeCategory].presets,
    // filters
    filters: state.filters[activeCategory].data,
    lastBodyPart: state.filters.lastBodyPart || '',
    categories: state.AdminAuth.categories || [],
    adminPresets: state.AdminPresets.presets || [],
    activePreset: state.AdminPresets.activePreset
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchCategories,
      fetchPresets,
      fetchPresetsEditorPicks,
      setFilter,
      syncFilter,
      fetchNewProducts,
      fetchAdminPresets,
      fetchAddPreset
    }
  ),
  withTrackingProvider('PresetsSettings'),
  withStyles(styles)
)(PresetsSettings)
